import React, { useState } from "react";
import { FaCheck } from "react-icons/fa6";

import { useSelector } from "react-redux";
import Modal from "../../components/Modal";

const OnBoardControl = () => {
  const [openOnboardModal, setOpenOnboardModal] = useState(false);
  const { user } = useSelector((state) => state.Auth);
  if (!user) return null;

  const onboardingItems = [
    { label: "Complete registration", checked: true },
    { label: "Save your first search", checked: user.is_first_search_saved },
    { label: "Activate WhatsApp notifications", checked: user.is_whatsapp_activated },
    // { label: "Check out our moving guide (later)", checked: user.is_moving_guide_done },
  ];

  // if all items are checked, return
  if (onboardingItems.every((item) => item.checked)) {
    return null;
  }

  return (
    <div className="mr-5 w-full">
      <div onClick={() => setOpenOnboardModal((open) => !open)} className="flex flex-row gap-1 justify-end w-full items-center cursor-pointer text-sm">
        <span>Get started</span>
        <span className="flex rounded-md bg-primary py-1 px-2 min-h-0 leading-none font-medium text-white text-xs">
          {onboardingItems.filter((item) => item.checked).length} / {onboardingItems.length}
        </span>
      </div>
      <OnBoardModal
        onboardingItems={onboardingItems}
        open={openOnboardModal}
        onClose={() => {
          setOpenOnboardModal(false);
        }}
      />
    </div>
  );
};

const OnBoardModal = ({ open, onClose, onboardingItems }) => {
  if (!open) return null;
  return (
    <Modal isOpen={true} onClose={onClose} innerClassName="md:flex md:max-w-[50rem] md:w-[40vw] md:max-h-[calc(100vh-5rem)]">
      <div className="w-full h-full flex flex-col overflow-y-hidden transform bg-white text-left align-middle shadow-xl transition-all">
        <div className="flex justify-between px-8 py-5 border-b">
          <div>
            <div className="text-lg md:text-2xl font-semibold mb-2">Let’s get started!</div>
            <div>Follow this step-by-step guide and get the maximum out of RentHunter.</div>
          </div>
          <button
            className="text-2xl flex items-start"
            onClick={() => {
              onClose();
            }}>
            X
          </button>
        </div>

        <div className="pt-4 md:h-full px-8 py-7 overflow-auto flex flex-col gap-5">
          {onboardingItems.map(({ checked, label }, n) => (
            <div key={n} className="flex item-center gap-4">
              <div className={`flex items-center justify-center h-7 w-7 rounded-full text-white ${checked ? "bg-green-500" : "bg-transparent border border-solid border-primary"}`}>
                <FaCheck className={`${checked ? "block" : "hidden"}`} />
              </div>
              <div className={`flex items-center text-base leading-none ${checked ? "line-through" : ""}`}>{label}</div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default OnBoardControl;

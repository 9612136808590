import React, { useState } from "react";

import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import LoadingButton from "@/components/LoadingButton";
import Modal from "@/components/Modal";
import { Statsig } from "@/services/statsig";
import { capitalizeFirstLetter } from "@/utils";
import { DEFAULT_MESSAGE_TEMPLATE, SOURCES_AFFILIATION_ARGUMENTS } from "@/utils/constants";
import { STATSIG_EVENTS } from "@/utils/statsigEvents";
import { sleep } from "@/utils";

const replaceVariables = (text, variables) => text.replace(/@(\w+)/g, (_, variableName) => variables[variableName] || `@${variableName}`);

const constructVariables = (user = {}, advert = {}) => {
  return {
    // advertName: `${capitalizeFirstLetter(advert?.city)} - ${capitalizeFirstLetter(advert?.converted_type)} ${advert?.surface ? ` - ${advert?.surface}m²` : ""}`,
    advertStreet: "",
    advertCity: capitalizeFirstLetter(advert?.city),
    advertPrice: `${advert?.price}€`,
    firstName: user?.firstname,
    lastName: user?.lastname,
    email: user?.email,
    phoneNumber: user?.whatsapp_phone,
    // moveInDate: new Date(advert?.move_in_date).toDateString(),
  };
};

const CustomMessageModal = ({ openCustomMessage, setOpenCustomMessage, advert }) => {
  const { user } = useSelector((state) => state.Auth);

  const [customMessage, setCustomMessage] = useState(replaceVariables(user?.message_template || DEFAULT_MESSAGE_TEMPLATE, constructVariables(user, advert)));
  const [isLoading, setIsLoading] = useState(false);

  const handleCopy = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success("Copied");
    } catch (err) {
      console.error("Failed to copy text to clipboard:", err);
    }
  };

  return (
    <Modal
      innerClassName="md:flex md:max-w-[50rem] md:w-[60vw] md:h-[85vh] md:max-h-[calc(100vh-5rem)]"
      isOpen={openCustomMessage}
      onClose={() => {
        setOpenCustomMessage(false);
      }}>
      <div className="w-full max-h-screen h-full lg:h-auto flex flex-col overflow-y-hidden transform bg-white text-left align-middle shadow-xl transition-all">
        <div className="flex justify-between items-center px-8 py-5 border-b">
          <div className="text-xl font-semibold flex gap-2 items-center">Copy your personnalized message</div>
          <button
            className="text-2xl"
            onClick={() => {
              setOpenCustomMessage(false);
            }}>
            X
          </button>
        </div>
        <div className="w-full h-full flex flex-col overflow-y-auto">
          <div className="py-4 px-8 flex-1 flex flex-col h-full">
            <div className="">Save time contacting the landlord by copying your message below!</div>
            <div className="mb-6">
              To make it even more personal, edit the{" "}
              {user ? (
                <Link
                  to="/account/template-message"
                  className="text-primary underline"
                  onClick={() => {
                    Statsig.logEvent(STATSIG_EVENTS.advert_page_click_edit_message_template, "login_user", {
                      advert_id: advert._id,
                      source: advert.source,
                      advert_price: advert.price,
                      advert_type: advert.type,
                    });
                  }}>
                  template here
                </Link>
              ) : (
                <span
                  className="text-primary underline cursor-pointer"
                  onClick={() => {
                    Statsig.logEvent(STATSIG_EVENTS.advert_page_click_edit_message_template, "not_login_user", {
                      advert_id: advert._id,
                      source: advert.source,
                      advert_price: advert.price,
                      advert_type: advert.type,
                    });
                    toast.error("Please login to edit your message template");
                  }}>
                  template here
                </span>
              )}
              .
            </div>

            <label className="flex-1 flex flex-col gap-2">
              <textarea
                required
                placeholder="Give us more details"
                name="report body"
                value={customMessage}
                className="p-4 resize-y rounded-lg w-full border h-full"
                onChange={(e) => {
                  const value = e.target.value;
                  setCustomMessage(value);
                }}></textarea>
            </label>
          </div>
          <div className="bg-white flex justify-between w-full border-t pt-3 px-8 pb-3">
            <LoadingButton
              type="button"
              loading={isLoading}
              onClick={async () => {
                try {
                  setIsLoading(true);
                  Statsig.logEvent(STATSIG_EVENTS.advert_page_click_copy_message_and_redirect, advert._id, {
                    advert_id: advert._id,
                    source: advert.source,
                    advert_price: advert.price,
                    advert_type: advert.type,
                    minutes_since_advert_creation: Math.floor((new Date() - new Date(advert.created_at)) / 60000),
                  });
                  await handleCopy(customMessage);
                  // wait 1 second before redirecting to make the user understand that the message is copied
                  await sleep(1000);
                  let url = advert.url;
                  if (SOURCES_AFFILIATION_ARGUMENTS[advert.source]) url += SOURCES_AFFILIATION_ARGUMENTS[advert.source];
                  window.open(url, "_blank");
                } catch (err) {
                  console.error(err);
                } finally {
                  setIsLoading(false);
                }
              }}
              className="btn-primary w-full">
              Copy to clipboard + Continue
            </LoadingButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CustomMessageModal;

import React, { useEffect, useState } from "react";

import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";

import { BiBuilding, BiHomeAlt } from "react-icons/bi";
import { CgStudio } from "react-icons/cg";
import { RiHotelBedLine } from "react-icons/ri";

import AlertIcon from "@/assets/img/bell-3d.png";
import HomeIcon from "@/assets/img/home-3d.png";
import OnboardImage from "@/assets/img/onboardImage.jpg";
import OnboardMapImage from "@/assets/img/onboardMap.jpg";
import BotIcon from "@/assets/svg/bot.svg?react";
import BulbIcon from "@/assets/svg/bulb.svg?react";
import NotificationIcon from "@/assets/svg/notification.svg?react";

import { CheckBoxes } from "@/components/CheckBoxes";
import Modal from "@/components/Modal";
import PhoneNumberInput from "@/components/phoneNumberInput";
import PriceRange from "@/components/PriceRange";
import { setUser } from "@/redux/auth/actions";
import api from "@/services/api";
import { Statsig } from "@/services/statsig";
import { capitalizeFirstLetter, isPhoneValid } from "@/utils";
import { STATSIG_EVENTS } from "@/utils/statsigEvents";
import { Combobox } from "../housingMatches/list/ComboboxSearchCity";

const ONBOARD_STAGES = {
  WELCOME: "WELCOME",
  MOVE_IN: "MOVE_IN",
  LOCATION_PRICE: "LOCATION_PRICE",
  MORE_FILTERS: "MORE_FILTERS",
  ALERT: "ALERT",
  SEE_LISTINGS: "SEE_LISTINGS",
};

const Onboard = () => {
  const { user } = useSelector((state) => state.Auth);

  const [stage, setStage] = useState(ONBOARD_STAGES.WELCOME);
  const [onboardFilters, setOnboardFilters] = useState({
    notification_types: ["WHATSAPP"],
    notification_frequency: "INSTANT",
    rental_min: 0,
    rental_max: 5000,
  });
  const [userObject, setUserObject] = useState({
    move_in_period: user?.move_in_period,
  });
  const [isOpen, setIsOpen] = useState(true);
  const [municipalities, setMunicipalities] = useState([]); // municipalities to display when the input is empty
  const [priceRangeSegments, setPriceRangeSegments] = useState([]);

  useEffect(() => {
    fetchDefaultMunicipalities("");
  }, []);

  // Log event when stage changes
  useEffect(() => {
    Statsig.logEvent(STATSIG_EVENTS.onboarding_stage_ + stage);
  }, [stage]);

  useEffect(() => {
    fetchPriceRangeSegments(onboardFilters);
  }, [onboardFilters]);

  const fetchDefaultMunicipalities = async (value) => {
    try {
      const res = await api.post("/municipality/search", { search: value });
      setMunicipalities(res.data);
    } catch (e) {
      console.log("e", e);
      toast.error(e?.code || "Error");
    }
  };

  const fetchPriceRangeSegments = async () => {
    try {
      const res = await api.post("/advert/search", {
        // we include paid sources to get the price range to show more relevant results
        included_paid_sources: ["KAMERNET", "KAMER", "HUURWONINGEN"],
        ...onboardFilters,
        rental_min: null,
        rental_max: null,
        forRange: true,
      });
      const range = res.range || [];
      setPriceRangeSegments([...range]);
    } catch (e) {
      console.log("e", e);
      toast.error(e?.code || "Error");
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    Statsig.logEvent(STATSIG_EVENTS.onboarding_click_close);
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} innerClassName="w-full md:flex md:max-w-[50rem] md:max-h-[calc(100vh-5rem)] md:min-h-[min(100vh-5rem,520px)]">
      <div className="w-full h-full md:h-[inherit] flex flex-col overflow-y-hidden bg-white text-left align-middle">
        {stage === ONBOARD_STAGES.WELCOME && (
          <div className="flex justify-end items-end pt-5 px-6 md:px-8">
            <button onClick={handleClose} className="text-xl font-ligth text-gray-600">
              X
            </button>
          </div>
        )}
        <div className="flex flex-col h-full px-6 md:px-8 py-5 overflow-y-auto">
          {stage === ONBOARD_STAGES.WELCOME ? (
            <WelcomeStage setStage={setStage} />
          ) : (
            <>
              <div className="py-4">
                <div className="h-2.5 w-full rounded-full shadow-inner bg-gray-200">
                  <div
                    style={{
                      width: `${(((Object.keys(ONBOARD_STAGES).indexOf(stage) + 1) / 6) * 100).toFixed(0)}%`,
                    }}
                    className="h-full bg-primary-700 rounded-full"></div>
                </div>
              </div>
              {stage === ONBOARD_STAGES.MOVE_IN ? (
                <MoveInStage setStage={setStage} userObject={userObject} setUserObject={setUserObject} />
              ) : stage === ONBOARD_STAGES.LOCATION_PRICE ? (
                <LocationPriceStage
                  priceRangeSegments={priceRangeSegments}
                  municipalities={municipalities}
                  setStage={setStage}
                  onboardFilters={onboardFilters}
                  setOnboardFilters={setOnboardFilters}
                />
              ) : stage === ONBOARD_STAGES.MORE_FILTERS ? (
                <MoreFiltersStage setStage={setStage} onboardFilters={onboardFilters} setOnboardFilters={setOnboardFilters} />
              ) : stage === ONBOARD_STAGES.ALERT ? (
                <AlertStage userObject={userObject} setUserObject={setUserObject} setStage={setStage} onboardFilters={onboardFilters} setOnboardFilters={setOnboardFilters} />
              ) : stage === ONBOARD_STAGES.SEE_LISTINGS ? (
                <ListingsStage setIsOpen={setIsOpen} onboardFilters={onboardFilters} userObject={userObject} />
              ) : null}
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

const WelcomeStage = ({ setStage }) => {
  return (
    <div className="h-full flex gap-4">
      <div className="flex flex-col flex-1">
        <h2 className="text-3xl font-bold mb-4 text-primary">Welcome to RentHunter</h2>
        <h3 className="text-2xl font-semibold mb-4">The shortest route to your next home</h3>
        <div>One platform, all the rental listings in the Netherlands</div>
        <div className="flex flex-1 justify-end flex-col mt-8 md:mt-0">
          <div className="py-2 mt-2">
            <div className="rounded-lg p-2 flex gap-2 border border-gray-200">
              <div className="pt-1">
                <BulbIcon className="w-6" />
              </div>
              <div>Tell us more about your situation to better help with your search</div>
            </div>
          </div>
          <div className="w-full py-2">
            <button
              onClick={() => {
                setStage(ONBOARD_STAGES.MOVE_IN);
              }}
              className="w-full btn-primary">
              Setup my search
            </button>
          </div>
        </div>
      </div>
      <div className="hidden md:flex flex-1">
        <img className="h-full max-h-[400px] w-full object-contain" src={OnboardImage} />
      </div>
    </div>
  );
};

const MoveInStage = ({ setStage, userObject, setUserObject }) => {
  return (
    <div className="flex flex-col flex-1">
      <div className="flex flex-wrap gap-3">
        <img src={HomeIcon} className="h-8" />
        <div className="text-lg md:text-2xl font-semibold mb-2">When are you looking to move?</div>
      </div>
      <div className="flex flex-1 gap-8">
        <div className="flex flex-col flex-1 pt-12">
          <div className="w-full flex-col">
            <div className="text-sm font-medium text-gray-600 mb-2">When are you looking to move? *</div>
            <CheckBoxes
              options={["As soon as possible", "Next month", "In 2 months", "In 3 months+"]}
              value={userObject.move_in_period}
              onAdd={(e) => setUserObject({ ...userObject, move_in_period: e })}
              onRemove={() => setUserObject({ ...userObject, move_in_period: null })}
              className="!grid-cols-1 md:!grid-cols-2"
            />
          </div>
          <div className="flex flex-1 justify-end flex-col mt-8 md:mt-0">
            <div className="py-2 mt-2">
              <div className="rounded-lg p-2 flex gap-2 border border-gray-200">
                <div className="pt-1">
                  <BulbIcon className="w-6" />
                </div>
                <div>Our customizable message templates help you send proposals to landlords easily</div>
              </div>
            </div>
            <div className="w-full py-2">
              <button
                disabled={!userObject.move_in_period}
                onClick={() => {
                  setStage(ONBOARD_STAGES.LOCATION_PRICE);
                }}
                className="min-w-32 w-full btn-primary">
                Next
              </button>
            </div>
          </div>
        </div>
        <div className="hidden md:flex flex-1">
          <img className="h-full max-h-[400px] w-full object-contain rounded-3xl" src={OnboardMapImage} />
        </div>
      </div>
    </div>
  );
};

const LocationPriceStage = ({ priceRangeSegments, municipalities, setStage, onboardFilters, setOnboardFilters }) => {
  const [searchMunicipalities, setSearchMunicipalities] = useState([]); // results of the municipality input search

  const retrieveSelectedMunicipality = () => {
    const selectedMunicipality = searchMunicipalities.find((option) => option.name == onboardFilters.municipality);
    if (selectedMunicipality) return selectedMunicipality;
    if (onboardFilters.municipality) return { name: onboardFilters.municipality }; // case when the municipality is not in the list of municipalities
    return null;
  };

  return (
    <div className="flex flex-col flex-1">
      <div className="flex flex-wrap gap-3">
        <img src={HomeIcon} className="h-8" />
        <div className="text-lg md:text-2xl font-semibold mb-2">Setup your search</div>
      </div>
      <div className="flex flex-1 gap-8">
        <div className="flex flex-col flex-1 gap-4 pt-4">
          <div>
            <div className="font-medium text-lg mb-3 text-center">I want to live in?</div>
            <Combobox
              onInputChange={async (value) => {
                try {
                  if (!value) {
                    // to display the default municipalities when the input is empty (without the related cities)
                    setSearchMunicipalities([]);
                    return;
                  }
                  const res = await api.post("/municipality/search", { search: value, include_related_cities: true });
                  setSearchMunicipalities(res.data);
                } catch (e) {
                  console.log("e", e);
                  toast.error(e?.code || "Error");
                }
              }}
              value={retrieveSelectedMunicipality()}
              options={searchMunicipalities?.length > 0 ? searchMunicipalities : municipalities}
              onChange={(option) => {
                if (!option) setOnboardFilters({ ...onboardFilters, municipality: null });
                else setOnboardFilters({ ...onboardFilters, municipality: option.municipality });
              }}
              getLabel={(e) => (e ? e.name.charAt(0).toUpperCase() + e.name.slice(1) : null)}
              placeholder={"Select municipality"}
              width="w-full"
            />
          </div>

          <div className="text-center flex flex-col gap-3">
            <div className="font-medium text-lg">Price range</div>
            <PriceRange
              priceRangeSegments={priceRangeSegments}
              priceMax={onboardFilters.rental_max || 5000}
              priceMin={onboardFilters.rental_min || 0}
              setPrices={({ rental_min, rental_max }) => {
                setOnboardFilters((onboardFilters) => ({ ...onboardFilters, rental_max, rental_min }));
              }}
            />
          </div>
          <div className="flex flex-1 justify-end flex-col">
            <div className="mt-8 md:mt-0 flex justify-between w-full py-2">
              <button
                onClick={() => {
                  setStage(ONBOARD_STAGES.MOVE_IN);
                }}
                className="min-w-32 w-fit btn-secondary">
                Back
              </button>
              <button
                disabled={!onboardFilters.municipality || isNaN(onboardFilters.rental_min) || isNaN(onboardFilters.rental_max)}
                onClick={() => {
                  if (!onboardFilters.municipality || isNaN(onboardFilters.rental_min) || isNaN(onboardFilters.rental_max)) return;
                  setStage(ONBOARD_STAGES.MORE_FILTERS);
                }}
                className="min-w-32 w-fit btn-primary">
                Next
              </button>
            </div>
          </div>
        </div>
        <div className="hidden md:flex flex-1">
          <img className="h-full max-h-[400px] w-full object-contain rounded-3xl" src={OnboardMapImage} />
        </div>
      </div>
    </div>
  );
};

const MoreFiltersStage = ({ setStage, onboardFilters, setOnboardFilters }) => {
  return (
    <div className="flex flex-col flex-1">
      <div className="flex flex-wrap gap-3">
        <img src={HomeIcon} className="h-8" />
        <div className="text-lg md:text-2xl font-semibold mb-2">Setup your search</div>
      </div>
      <div className="flex flex-1 gap-8">
        <div className="flex flex-col flex-1 pt-12 max-w-[480px] mx-auto">
          <div className="text-center flex flex-col gap-3 py-4 md:pt-0">
            <div className="font-medium text-lg mb-2">Housing type</div>
            <CheckBoxes
              options={["ROOM", "STUDIO", "APARTMENT", "HOUSE"]}
              value={onboardFilters.types || []}
              onAdd={(value) => {
                setOnboardFilters((onboardFilters) => ({
                  ...onboardFilters,
                  types: onboardFilters.types && onboardFilters.types.length > 0 ? [...onboardFilters.types, value] : [value],
                }));
              }}
              onRemove={(value) => {
                setOnboardFilters((onboardFilters) => ({ ...onboardFilters, types: onboardFilters.types.filter((t) => t !== value) }));
              }}
              getLabel={(o) => capitalizeFirstLetter(o)}
              getIcon={(o) => {
                if (o == "ROOM") return <RiHotelBedLine size={25} />;
                if (o == "APARTMENT") return <BiBuilding size={25} />;
                if (o == "HOUSE") return <BiHomeAlt size={25} />;
                if (o == "STUDIO") return <CgStudio size={25} />;
              }}
              multiple={true}
              className={"md:grid-cols-4"}
            />
          </div>

          <div className="text-center flex flex-col gap-3 py-4">
            <div className="font-medium text-lg mb-2">Furnishing</div>
            <CheckBoxes
              options={["FURNISHED", "UNFURNISHED"]}
              value={onboardFilters.furnished_types || []}
              multiple={true}
              onAdd={(value) => {
                setOnboardFilters((onboardFilters) => ({
                  ...onboardFilters,
                  furnished_types: onboardFilters.furnished_types && onboardFilters.furnished_types.length > 0 ? [...onboardFilters.furnished_types, value] : [value],
                }));
              }}
              onRemove={(value) => {
                setOnboardFilters((onboardFilters) => ({ ...onboardFilters, furnished_types: onboardFilters.furnished_types.filter((t) => t !== value) }));
              }}
              getLabel={(o) => capitalizeFirstLetter(o)}
              className={"md:grid-cols-2 lg:grid-cols-2"}
            />
          </div>
          <div className="flex flex-1 justify-end flex-col mt-8">
            <div className="mt-8 md:mt-0 flex justify-between w-full py-2">
              <button
                onClick={() => {
                  setStage(ONBOARD_STAGES.LOCATION_PRICE);
                }}
                className="min-w-32 w-fit btn-secondary">
                Back
              </button>
              <button
                onClick={() => {
                  setStage(ONBOARD_STAGES.ALERT);
                }}
                className="min-w-32 w-fit btn-primary">
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AlertStage = ({ userObject, setUserObject, setStage, onboardFilters, setOnboardFilters }) => {
  const { user } = useSelector((state) => state.Auth);
  const whatsappRequired = !user?.whatsapp_phone || user?.is_whatsapp_undeliverable;

  const handleOnboard = async () => {
    try {
      if (!onboardFilters.notification_types || !onboardFilters.notification_types.length) return toast.error("Please select a notification type");
      if (onboardFilters.notification_types.includes("WHATSAPP") && whatsappRequired) {
        if (!userObject.whatsapp_phone) return toast.error("Please enter your whatsapp phone number");
        if (!isPhoneValid(userObject.whatsapp_phone)) return toast.error("Please enter a valid whatsapp phone number");
      }

      if (!onboardFilters.notification_types.includes("WHATSAPP")) {
        // delete whatsapp_phone from userObject as it's not needed for the alert
        delete userObject.whatsapp_phone;
      }

      const userRes = await api.put("/user", { ...userObject });
      if (!userRes.ok) throw new Error("Error updating user");

      const res = await api.post("/user/saved-search", {
        ...onboardFilters,
        is_activated: true,
        name: onboardFilters.municipality
          ? `${capitalizeFirstLetter(onboardFilters.municipality)} (${new Date().toLocaleString()})`
          : `Custom search (${new Date().toLocaleString()})`,
      });

      if (!res.ok) throw new Error("Error");

      setStage(ONBOARD_STAGES.SEE_LISTINGS);
    } catch (e) {
      toast.error(e?.code || "Error");
    }
  };

  return (
    <div className="flex flex-col flex-1">
      <div className="flex flex-wrap gap-3">
        <img src={AlertIcon} className="h-8" />
        <div className="text-lg md:text-2xl font-semibold mb-2">Activate alerts</div>
      </div>
      <div className="flex flex-1 gap-8">
        <div className="flex flex-col flex-1 pt-4">
          <form method="POST" onSubmit={(e) => e.preventDefault()} className="flex flex-col">
            <div className="mt-5">Choose how to receive your alerts</div>
            <div className="flex flex-col py-2 gap-2">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="whatsapp"
                  name="notification_type"
                  value="WHATSAPP"
                  checked={onboardFilters.notification_types.includes("WHATSAPP")}
                  onChange={(e) => {
                    setOnboardFilters({
                      ...onboardFilters,
                      notification_types: onboardFilters.notification_types.includes("WHATSAPP")
                        ? onboardFilters.notification_types.filter((t) => t !== "WHATSAPP")
                        : [...onboardFilters.notification_types, "WHATSAPP"],
                    });
                  }}
                  className="h-5 w-5 max-w-5 cursor-pointer accent-primary"
                />
                <div>
                  <label htmlFor="whatsapp" className="px-2 cursor-pointer flex-1 inline-flex">
                    WhatsApp (recommended)
                  </label>
                </div>
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="email"
                  name="notification_type"
                  value="EMAIL"
                  checked={onboardFilters.notification_types.includes("EMAIL")}
                  onChange={(e) => {
                    setOnboardFilters({
                      ...onboardFilters,
                      notification_types: onboardFilters.notification_types.includes("EMAIL")
                        ? onboardFilters.notification_types.filter((t) => t !== "EMAIL")
                        : [...onboardFilters.notification_types, "EMAIL"],
                    });
                  }}
                  className="h-5 w-5 max-w-5 cursor-pointer accent-primary"
                />
                <div>
                  <label htmlFor="email" className="px-2 cursor-pointer flex-1 inline-flex">
                    Email
                  </label>
                </div>
              </div>
            </div>
            {onboardFilters.notification_types.includes("WHATSAPP") && whatsappRequired && (
              <div className="flex flex-wrap md:flex-nowrap gap-4">
                <div className="w-full">
                  <label className="text-xs text-gray-500">Whatsapp phone number</label>
                  <PhoneNumberInput phone={userObject.whatsapp_phone} onChange={(e) => setUserObject({ ...userObject, whatsapp_phone: e })} />
                </div>
              </div>
            )}
            <div className="mt-5">Choose when to receive your alerts</div>
            <div className="flex items-center gap-4">
              <div className="flex items-center">
                <input
                  type="radio"
                  id="instant"
                  name="notification_frequency"
                  value="INSTANT"
                  checked={onboardFilters.notification_frequency == "INSTANT"}
                  onChange={(e) => {
                    setOnboardFilters({ ...onboardFilters, notification_frequency: e.target.value });
                  }}
                  className="h-5 w-5 cursor-pointer accent-primary"
                />
                <label htmlFor="instant" className="p-2 cursor-pointer">
                  Instantly (recommended)
                </label>
              </div>
              <div className="flex items-center">
                <input
                  type="radio"
                  id="daily_summary"
                  name="notification_frequency"
                  value="DAILY"
                  checked={onboardFilters.notification_frequency == "DAILY"}
                  onChange={(e) => {
                    setOnboardFilters({ ...onboardFilters, notification_frequency: e.target.value });
                  }}
                  className="h-5 w-5 cursor-pointer accent-primary"
                />
                <label htmlFor="daily_summary" className="p-2 cursor-pointer">
                  Daily
                </label>
              </div>
            </div>
          </form>
          <div className="flex flex-1 justify-end flex-col">
            <div className="mt-8 md:mt-0 flex justify-between w-full py-2">
              <button
                onClick={() => {
                  setStage(ONBOARD_STAGES.MORE_FILTERS);
                }}
                className="min-w-32 w-fit btn-secondary">
                Back
              </button>
              <button
                disabled={!onboardFilters.notification_types.length || !onboardFilters.notification_frequency}
                onClick={() => {
                  if (!onboardFilters.notification_types.length || !onboardFilters.notification_frequency) return;
                  handleOnboard();
                }}
                className="min-w-32 w-fit btn-primary">
                Finish
              </button>
            </div>
          </div>
        </div>
        <div className="hidden md:flex flex-1">
          <img className="h-full max-h-[400px] w-full object-contain" src={OnboardImage} />
        </div>
      </div>
    </div>
  );
};

const ListingsStage = ({ setIsOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const redirectToSearch = async () => {
    const userRes = await api.put("/user", { onboarding_done_at: new Date() });
    if (!userRes.ok) throw new Error("Error updating user");
    toast.success("Onboarding completed");

    // Cause navigation
    const lastSearch = userRes.data.saved_searches[userRes.data.saved_searches.length - 1];
    const obj = {};
    if (lastSearch.municipality) obj.municipality = lastSearch.municipality;
    if (lastSearch.search_area_geometry) obj.search_area_geometry = JSON.stringify(lastSearch.search_area_geometry);
    if (lastSearch.rental_min) obj.rental_min = lastSearch.rental_min;
    if (lastSearch.rental_max) obj.rental_max = lastSearch.rental_max;
    if (lastSearch.types) obj.types = lastSearch.types;
    if (lastSearch.rental_length_in_weeks_min) obj.rental_length_in_weeks_min = lastSearch.rental_length_in_weeks_min;
    if (lastSearch.furnished_types) obj.furnished_types = lastSearch.furnished_types;
    if (lastSearch._id) obj._id = lastSearch._id;
    const createSearch = createSearchParams(obj);

    window.location.href = `/?${createSearch.toString()}`;
    dispatch(setUser(userRes.data));
  };

  return (
    <div className="flex flex-col flex-1">
      <div className="pt-12">
        <h2 className="text-center text-3xl font-bold text-primary">Ready to start applying?</h2>
      </div>
      <div className="flex flex-1 gap-8">
        <div className="flex flex-col flex-1 pt-12 max-w-[480px] mx-auto">
          <div className="flex flex-col gap-8 mb-12">
            <div className="flex gap-4">
              <div>
                <BotIcon className="h-10" />
              </div>
              <div>
                <div className="text-2xl font-semibold mb-2">Automate rental search</div>
                <div>RentHunter will search 100+ rentals sites to find the perfect home for you.</div>
              </div>
            </div>
            <div className="flex gap-4">
              <div>
                <NotificationIcon className="h-10" />
              </div>
              <div>
                <div className="text-2xl font-semibold mb-2">Instant notifications</div>
                <div>You will get a notification as soon as a new listing matches your criteria.</div>
              </div>
            </div>
          </div>
          <div className="flex flex-1 justify-end flex-col">
            <div className="mt-8 md:mt-0 w-full py-2">
              <button
                onClick={() => {
                  redirectToSearch();
                  setIsOpen(false);
                }}
                className="w-full btn-primary">
                See listings
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Onboard;

import { StatsigClient, StatsigOptions, StatsigUser } from "@statsig/js-client";
import { StatsigSessionReplayPlugin } from "@statsig/session-replay";
import { StatsigAutoCapturePlugin } from "@statsig/web-analytics";

import { ENVIRONMENT } from "../config";

const options = {
  plugins: [new StatsigSessionReplayPlugin(), new StatsigAutoCapturePlugin()],
  environment: { tier: ENVIRONMENT },
};

/** Code to persist stableID across subdomain, renthunter & app.renthunter */
// https://docs.statsig.com/client/javascript-sdk/stable-id/#persisting-stableid-across-subdomain
const a = () => {
  let t = "STATSIG_LOCAL_STORAGE_STABLE_ID";

  function e() {
    if (crypto && crypto.randomUUID) return crypto.randomUUID();
    let t = () =>
      Math.floor(65536 * Math.random())
        .toString(16)
        .padStart(4, "0");
    return `${t()}${t()}-${t()}-4${t().substring(1)}-${t()}-${t()}${t()}${t()}`;
  }

  let i = null,
    n = localStorage.getItem(t) || null;

  if ((document.cookie.match(/statsiguuid=([\w-]+);?/) && ([, i] = document.cookie.match(/statsiguuid=([\w-]+);?/)), i && n && i === n));
  else if (i && n && i !== n) localStorage.setItem(t, i);
  else if (i && !n) localStorage.setItem(t, i);
  else {
    let o = e();
    localStorage.setItem(t, o),
      (function t(i) {
        let n = new Date();
        n.setMonth(n.getMonth() + 12);
        let o = window.location.host.split(".");
        o.length > 2 && o.shift();
        let s = `.${o.join(".")}`;
        document.cookie = `statsiguuid=${i || e()};Expires=${n};Domain=${s}`;
      })(o);
  }
};
a();

const userObj = {};
if (localStorage.getItem("STATSIG_LOCAL_STORAGE_STABLE_ID")) {
  userObj.customIDs = { stableID: localStorage.getItem("STATSIG_LOCAL_STORAGE_STABLE_ID") };
}
const myStatsigClient = new StatsigClient("client-i8vRe8FToKIt7q7HrxQLahtnOwAdqIxNck3zW3XhIVf", userObj, options);

if (ENVIRONMENT === "production") {
  // TEST init sync
  myStatsigClient.initializeSync();
}

let actions = {
  updateUserSync: (user) => {
    myStatsigClient.updateUserSync(user);
  },
  getExperiment: (experimentName) => {
    return myStatsigClient.getExperiment(experimentName);
  },
  checkGate: (gateName) => {
    return myStatsigClient.checkGate(gateName);
  },
  logEvent: (eventName, value, metadata) => {
    myStatsigClient.logEvent({ eventName, value, metadata });
  },
};

export let Statsig = actions;

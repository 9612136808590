import React from "react";

export const CheckBoxes = ({ options, value, onAdd, onRemove, getLabel = (o) => o, getValue = (o) => o, getIcon = (o) => null, multiple = false, className }) => {
  return (
    <div className={`grid gap-3 ${className} grid-cols-2`}>
      {options.map((option, n) => (
        <div
          key={n}
          style={{ overflowWrap: "anywhere" }}
          className={`p-2 flex justify-center items-center text-center flex-col border border-gray-300 rounded cursor-pointer break-words leading-none ${
            (multiple ? value.includes(getValue(option)) : value == getValue(option)) ? "bg-primary text-white" : ""
          }`}
          onClick={() => {
            if ((multiple && value.includes(getValue(option))) || (!multiple && value == getValue(option))) onRemove(option);
            else onAdd(option);
          }}>
          {getIcon(option)}
          {getLabel(option)}
        </div>
      ))}
    </div>
  );
};

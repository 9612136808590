import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import HamburgerIcon from "@/assets/svg/hamburger.svg";
import useDevice from "@/hooks/useDevice";
import NavItems from "./NavItems";

export const HamburgerMenu = () => {
  let [open, setOpen] = useState(false);
  const { user } = useSelector((state) => state.Auth);
  const device = useDevice();

  const getInitials = (str = "") => {
    const words = str.split(" ");
    const initials = words
      .map((word) => word.charAt(0))
      .join(" ")
      .toUpperCase();
    return initials;
  };
  useEffect(() => {
    document.body.style.overflow = open && device === "mobile" ? "hidden" : "auto";
  }, [open]);

  return (
    <>
      <div className="flex items-center cursor-pointer rounded-full" onClick={() => setOpen((state) => !state)}>
        {user ? (
          <div className="h-8 w-8 flex items-center justify-center rounded-full border border-primary text-primary">{getInitials(user?.firstname)}</div>
        ) : (
          <img src={HamburgerIcon} alt="hamburger" />
        )}
      </div>
      <div>
        <NavItems setOpen={setOpen} open={open} />
      </div>
      {open && <div className="w-full h-full top-0 left-0 fixed z-[5000] overflow-hidden" onClick={() => setOpen(false)} style={{ backdropFilter: "brightness(60%)" }}></div>}
    </>
  );
};

export default HamburgerMenu;

import { useEffect, useState } from "react";

import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import LoadingButton from "@/components/LoadingButton";
import { RETURN_URL } from "@/config";
import api from "@/services/api";
import { Mixpanel } from "@/services/mixpanel";
import { Statsig } from "@/services/statsig";
import { STATSIG_EVENTS } from "@/utils/statsigEvents";
import { ExpressCheckoutElement, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import AddPromotionCode from "./addPromotionCode";

function get_ga_clientid() {
  // work only if clientId already exists
  var cookie = {};
  document.cookie.split(";").forEach(function (el) {
    var splitCookie = el.split("=");
    var key = splitCookie[0].trim();
    var value = splitCookie[1];
    cookie[key] = value;
  });
  return cookie["_ga"]?.substring(6);
}

const paymentElementOptions = {
  layout: "accordion",
  fields: {
    billingDetails: {
      name: "never",
      email: "never",
    },
  },
  // link order seems to matter to show it or not
  // paymentMethodOrder: ["card", "paypal", "ideal"],
  paymentMethodOrder: ["card", "paypal", "link", "ideal"],
  defaultValues: {
    billingDetails: {},
    paymentMethods: {
      ideal: {
        bank: "revolut",
      },
    },
  },
  terms: {
    card: "never",
    ideal: "never",
    paypal: "never",
  },
  // wallets: {
  //   applePay: "auto",
  //   googlePay: "auto",
  // },
  // todo add apple pay
  // applePay: {}
};

const PaymentForm = ({ selectedPlan, isActiveStep }) => {
  const { user } = useSelector((state) => state.Auth);
  const elements = useElements();
  const stripe = useStripe();
  const navigate = useNavigate();

  const [isTermsChecked, setIsTermsChecked] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState(paymentElementOptions.paymentMethodOrder[0]);
  const [clientSecretIdeal, setClientSecretIdeal] = useState(null);
  const [clientSecretCard, setClientSecretCard] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isPaymentElementReady, setIsPaymentElementReady] = useState(false);
  const [promotion, setPromotion] = useState({
    promotionCodeId: null,
    discount: 0,
    promotionCode: null,
  });

  // use effect to update the payment method listening user change
  // we update on user, especially when the account is just created on the previous step
  useEffect(() => {
    if (!elements) return;
    if (!user) return;

    console.log("updating payment method useEffect");
    elements.getElement("payment")?.update({ defaultValues: { billingDetails: { name: `${user.firstname} ${user.lastname}`, email: user.email } } });

    // create payment intent if not created yet, works as long as we have only one plan
    if (!clientSecretCard) createPaymentIntentCard();
    if (!clientSecretIdeal) createPaymentIntentIdeal();
  }, [user]);

  // update payment intent if promotion changes as price changes
  useEffect(() => {
    if (!elements) return;

    setClientSecretCard(null);
    setClientSecretIdeal(null);

    elements.update({
      amount: selectedPlan.amount * (1 - promotion.discount / 100),
    });

    createPaymentIntentCard();
    createPaymentIntentIdeal();
  }, [promotion]);

  const handleSubmit = async (event) => {
    Mixpanel.track("checkout_page_payment_form_submit", {
      paymentMethod: currentPaymentMethod,
      isTermsChecked: isTermsChecked,
      selectedPlan: selectedPlan,
      promotionCode: promotion.promotionCode,
      promotionCodeId: promotion.promotionCodeId,
      discount: promotion.discount,
    });
    event.preventDefault();

    if (!isTermsChecked) {
      setErrorMessage("You must agree to the terms and conditions before proceeding.");
      return;
    }

    try {
      const result = await elements.submit();
      if (result.error) {
        // Handle validation errors
        console.error("Validation error:", result.error.message);
        toast.error(result.error.message);
        return;
      }

      setLoading(true);

      if (currentPaymentMethod === "ideal") {
        elements.update({
          payment_method_types: ["ideal"],
        });

        // if no client secret, try to create it
        let clientSecret = clientSecretIdeal;
        if (!clientSecretIdeal) {
          clientSecret = await createPaymentIntentIdeal();
          if (!clientSecret) {
            throw new Error("Error creating payment method Ideal");
          }
        }

        const { error, paymentIntent } = await stripe.confirmPayment({
          elements,
          clientSecret: clientSecret,
          confirmParams: {
            return_url: `${RETURN_URL}/checkout-new?customerId=${user.stripe_customer_id_ltd}&paymentMethod=ideal&coupon=${promotion.promotionCode}`,
            payment_method_data: {
              billing_details: {
                name: user.firstname + " " + user.lastname,
                email: user.email,
              },
            },
          },
        });

        if (error) {
          console.error("Error creating payment method:", error.message);
          toast.error(error.message);
          return;
        }

        if (paymentIntent) {
          toast.success("Payment Successfull!");
          navigate("/");
        }
        return;
      } else {
        elements.update({
          payment_method_types: ["card", "paypal", "link"],
        });

        let clientSecret = clientSecretCard;
        if (!clientSecretCard) {
          clientSecret = await createPaymentIntentCard();
          if (!clientSecret) {
            throw new Error("Error creating payment method");
          }
        }

        // could we do this call from the backend?
        const { error, paymentIntent } = await stripe.confirmPayment({
          elements,
          clientSecret: clientSecret,
          confirmParams: {
            return_url: `${RETURN_URL}/checkout-new?customerId=${user.stripe_customer_id_ltd}&coupon=${promotion.promotionCode}`,
            payment_method_data: {
              billing_details: {
                name: user.firstname + " " + user.lastname,
                email: user.email,
              },
            },
          },
        });

        if (error) {
          console.error("Error creating payment method:", error.message);
          toast.error(error.message);
          return;
        }

        if (paymentIntent) {
          toast.success("Payment Successfull!");
          navigate("/");
        }
      }
    } catch (error) {
      console.error("Error creating payment method:", error);
      setLoading(false);
      toast.error("Payment failed");
    } finally {
      resetPaymentMethods();
      setLoading(false);
    }
  };

  const createPaymentIntentIdeal = async () => {
    try {
      const ga4ClientId = get_ga_clientid();
      const subs = await api.post(
        `/stripe-new/create-payment-intent-ideal`,
        JSON.stringify({
          // TODO: handle it backend, remove amount here
          amount: selectedPlan.amount,
          priceId: selectedPlan.priceId,
          promotionCodeId: promotion.promotionCodeId,
          ga4ClientId: ga4ClientId,
        }),
      );
      setClientSecretIdeal(subs.clientSecret);
      return subs.clientSecret;
    } catch (error) {
      console.error("Error creating payment method:", error);
      toast.error("Error creating payment method");
      return null;
    }
  };

  const createPaymentIntentCard = async () => {
    try {
      const ga4ClientId = get_ga_clientid();
      const subs = await api.post(
        `/stripe-new/create-subscription-without-ideal`,
        JSON.stringify({
          priceId: selectedPlan.priceId,
          promotionCodeId: promotion.promotionCodeId,
          ga4ClientId: ga4ClientId,
        }),
      );
      setClientSecretCard(subs.clientSecret);
      return subs.clientSecret;
    } catch (error) {
      console.error("Error creating payment method:", error);
      toast.error("Error creating payment method");
      return null;
    }
  };

  const resetPaymentMethods = () => {
    elements.update({
      payment_method_types: ["card", "paypal", "link", "ideal"],
    });
  };

  if (!elements || !stripe) return null;

  return (
    <div className={`mx-auto ${isActiveStep ? "block" : "hidden"}`}>
      <form onSubmit={handleSubmit} id="payment-form">
        {/* Express Checkout Component */}
        <ExpressCheckoutElement
          className="my-2"
          options={{
            // buttonType: {
            //   applePay: "subscribe",
            //   googlePay: "subscribe",
            //   paypal: "paypal",
            // },
            layout: {
              maxRows: 3,
              maxColumns: 4,
              overflow: "auto",
            },
            // paymentMethods: {
            //   paypal: "auto",
            //   applePay: "auto",
            //   googlePay: "auto",
            //   link: "auto",
            // },
            paymentMethodOrder: ["applePay", "googlePay", "paypal", "link"],
          }}
          onConfirm={async () => {
            elements.update({
              payment_method_types: ["card", "paypal", "link"],
            });

            const { error } = await stripe.confirmPayment({
              elements,
              clientSecret: clientSecretCard,
              confirmParams: {
                return_url: `${RETURN_URL}/checkout-new?customerId=${user.stripe_customer_id_ltd}&coupon=${promotion.promotionCode}`,
              },
              payment_method_data: {
                billing_details: {
                  name: user.firstname + " " + user.lastname,
                  email: user.email,
                },
              },
            });
            if (error) {
              console.error("Error confirming payment:", error.message);
              toast.error(error.message);
              resetPaymentMethods();
            }
          }}
        />

        <PaymentElement
          id="payment-element"
          options={paymentElementOptions}
          onChange={(event) => {
            console.log("🚀 ~ change payment element ~ event:", event);
            setCurrentPaymentMethod(event.value.type);
          }}
          onReady={() => {
            setIsPaymentElementReady(true);
            Statsig.logEvent(STATSIG_EVENTS.checkout_payment_element_ready);
          }}
        />
        {isPaymentElementReady && <AddPromotionCode setPromotion={setPromotion} />}

        {/* No show because already shown in the upgrade popup */}
        {/* <div className="flex items-center justify-start md:w-[350px] space-x-2 mt-6">
          <div className="w-fit">
            <input
              type="checkbox"
              id="terms"
              className="h-4 w-4 border-gray-300 rounded bg-white"
              checked={isTermsChecked}
              onChange={() => {
                setIsTermsChecked(event.target.checked);
                setErrorMessage("");
              }}
            />
          </div>
          <label htmlFor="terms" className="text-sm text-gray-900">
            I've read and understand the Terms and conditions*
          </label>
        </div> */}

        {errorMessage && <p className="text-red-500 text-xs mt-2">{errorMessage}</p>}
        <div className="flex justify-start items-center gap-x-3 mt-6">
          <LoadingButton
            loading={loading}
            disabled={!stripe || !isTermsChecked}
            className="w-52 bg-secondary text-white px-4 py-2 rounded"
            onClick={() => Statsig.logEvent(STATSIG_EVENTS.checkout_payment_form_submit)}>
            Continue
          </LoadingButton>

          <span className="font-bold text-lg">€{((selectedPlan.amount / 100) * (1 - promotion.discount / 100)).toFixed(2)}</span>
        </div>
      </form>
    </div>
  );
};

export default PaymentForm;

import { useContext } from "react";

import { toast } from "react-hot-toast";
import api from "../../../../services/api";
import FilterContext from "./filterContext";

const useFilters = () => {
  const { setIsTotalMatchesLoading, setTotalMatches, setPriceRangeSegments, ...contextObject } = useContext(FilterContext);

  const fetchTotalMatches = async (filters) => {
    try {
      setIsTotalMatchesLoading(true);
      const res = await api.post("/advert/search", { ...filters, totalOnly: true });
      setTotalMatches(res.total);
    } catch (e) {
      console.log("e", e);
      setTotalMatches(null);
      toast.error(e?.code || "Error");
    } finally {
      setIsTotalMatchesLoading(false);
    }
  };

  const fetchPriceRangeSegments = async (filters) => {
    try {
      const res = await api.post("/advert/search", { ...filters, rental_min: null, rental_max: null, forRange: true });
      const range = res.range || [];
      setPriceRangeSegments([...range]);
    } catch (e) {
      console.log("e", e);
      toast.error(e?.code || "Error");
    }
  };

  return {
    ...contextObject,
    fetchTotalMatches,
    fetchPriceRangeSegments,
    setIsTotalMatchesLoading,
    setTotalMatches,
    setPriceRangeSegments,
  };
};

export default useFilters;

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import Logo from "@/assets/svg/logo_renthunter.svg";
import DesktopNavMenu from "@/components/navbar/DesktopNavMenu";
import useDevice from "@/hooks/useDevice";
import Onboard from "@/scenes/onboarding";
import OnboardChecklist from "@/scenes/onboarding/OnboardChecklist";
import { Mixpanel } from "@/services/mixpanel";
import HamburgerMenu from "./HamburgerMenu";
import MobileNavigationControls from "./MobileNavigationControls";

const NavBar = () => {
  const { user } = useSelector((state) => state.Auth);
  const device = useDevice();
  const navigate = useNavigate();

  const [showOnboarding, setShowOnboarding] = useState(false);

  // I want to hide MobileNavigationControls when url is on checkout/*, rent/* and upgrade/*
  const location = useLocation();
  const isCheckout = location.pathname.includes("checkout");
  const isHomePage = location.pathname === "/";
  const isAdvertPage = location.pathname.includes("rent");
  const isUpgrade = location.pathname.includes("upgrade");
  const isUnsubscribePage = location.pathname.includes("/account/unsubscribe");
  const isAuthPage = location.pathname.includes("/auth");

  useEffect(() => {
    // show onboarding only if user is logged in and onboarding is not done yet and there is no instant search
    if (user && !user?.onboarding_done_at && !user.saved_searches.find((s) => s.notification_frequency === "INSTANT")) {
      setShowOnboarding(true);
    }
  }, []); // Empty dependency array means this only runs on mount/page load, we want to show onboarding only when the user loads the page. Reason: strange behavior creating saved search only with the account on the fly: SaveSearchNoAuth.jsx

  if (isUnsubscribePage) return null;
  return (
    <>
      <nav
        id="navbar"
        className={`${device == "desktop" ? "sticky inset-y-0" : "static"} ${
          user && device == "mobile" ? "hidden" : ""
        } flex flex-row justify-between items-center px-5 md:px-8 w-full z-50 ease-in-out duration-300 bg-white h-[--header-height] border-b border-gray-200`}>
        <div
          className="flex justify-center items-center cursor-pointer"
          onClick={() => {
            Mixpanel.track("sidebar_click_logo");
            navigate("/");
          }}>
          <img src={Logo} alt="logo" className="h-5" />
        </div>
        <div className="flex flew-row justify-start items-center">
          {/* hide on checkout, upgrade and auth page */}
          {!isCheckout && !isUpgrade && <OnboardChecklist />}
          {!isCheckout && !isUpgrade && !isAuthPage && <>{device !== "mobile" ? <DesktopNavMenu /> : <HamburgerMenu />}</>}
        </div>
      </nav>
      {/* Controls */}
      {device === "mobile" && user && !isCheckout && !isUpgrade && !isAdvertPage && <MobileNavigationControls />}
      {/* Show onboarding on home page */}
      {isHomePage && showOnboarding && <Onboard />}
    </>
  );
};
export default NavBar;

import React, { useEffect, useState } from "react";

import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";

import { Statsig } from "@/services/statsig";
import { STATSIG_EVENTS } from "@/utils/statsigEvents";
import { CheckBoxes } from "../../../components/CheckBoxes";
import Modal from "../../../components/Modal";
import PhoneNumberInput from "../../../components/phoneNumberInput";
import api from "../../../services/api";
import { capitalizeFirstLetter, isPhoneValid } from "../../../utils";

export const UpdateSavedSearchDetailsModal = ({ savedSearch, onClose }) => {
  const { user } = useSelector((state) => state.Auth);

  const [whatsappPhone, setWhatsappPhone] = useState(undefined);
  const [savedSearchDetails, setSavedSearchDetails] = useState(null);

  const whatsappRequired = !user || !user.whatsapp_phone || user.is_whatsapp_undeliverable;

  useEffect(() => {
    if (!savedSearch) return;

    setSavedSearchDetails({
      _id: savedSearch._id,
      name: savedSearch.name,
      notification_types: savedSearch.notification_types,
      notification_frequency: savedSearch.notification_frequency,
    });
  }, [savedSearch]);

  const handleSearchUpdate = async () => {
    try {
      // validate search details
      if (!savedSearchDetails.notification_types || !savedSearchDetails.notification_types.length) return toast.error("Please select a notification type");
      if (savedSearchDetails.notification_types.includes("WHATSAPP") && whatsappRequired) {
        if (!whatsappPhone) return toast.error("Please enter your whatsapp phone number");
        if (!isPhoneValid(whatsappPhone)) return toast.error("Please enter a valid whatsapp phone number");
        const res = await api.put("/user", { whatsapp_phone: whatsappPhone });
        if (!res.ok) throw new Error("Error saving phone number");
      }

      const res = await api.put("/user/saved-search/" + savedSearch._id, {
        name: savedSearchDetails.name,
        notification_types: savedSearchDetails.notification_types,
        notification_frequency: savedSearchDetails.notification_frequency,
        is_activated: true,
      });
      if (!res.ok) throw new Error("Error updating search");

      toast.success("Search updated");
      handleClose();
    } catch (e) {
      console.log("e", e);
      toast.error(e?.code || "Error");
    }
  };

  const handleClose = () => {
    setSavedSearchDetails(null);
    onClose();
  };

  if (!savedSearchDetails) return null;
  return (
    <>
      <Modal isOpen={true} onClose={handleClose} innerClassName="md:flex md:max-w-[50rem] md:w-[60vw] md:h-[70vh] md:max-h-[calc(100vh-5rem)]">
        <form
          method="POST"
          className="w-full"
          onSubmit={(e) => {
            e.preventDefault();
            handleSearchUpdate();
            Statsig.logEvent(STATSIG_EVENTS.update_saved_search_details);
          }}>
          <div className="w-full h-full flex flex-col overflow-y-hidden transform bg-white text-left align-middle shadow-xl transition-all">
            <div className="flex justify-between items-center px-8 py-5 border-b">
              <div className="text-lg font-semibold">Update search</div>
              <button
                type="button"
                className="text-2xl"
                onClick={() => {
                  handleClose();
                  Statsig.logEvent(STATSIG_EVENTS.close_update_saved_search_details);
                }}>
                X
              </button>
            </div>

            <div className="pt-4 md:h-full pb-20 px-8 overflow-auto">
              Save the search to instantly know when new properties are available that match these requirements.
              <div className="flex flex-col mt-5">
                <div className="flex flex-wrap sm:flex-nowrap gap-4">
                  <div className="w-full md:w-1/2 pr-2">
                    <label className="text-xs text-gray-500">Name your search</label>
                    <input
                      type="text"
                      className="input-primary w-full"
                      value={savedSearchDetails.name}
                      onChange={(e) => setSavedSearchDetails({ ...savedSearchDetails, name: e.target.value })}
                    />
                  </div>
                </div>

                <div className="mt-5 mb-1">Choose how to receive your alerts</div>
                <div className="flex items-center gap-4">
                  <div className="w-full md:w-1/2 pr-2">
                    <CheckBoxes
                      multiple
                      options={["WHATSAPP", "EMAIL"]}
                      value={savedSearchDetails.notification_types}
                      onAdd={(value) => {
                        setSavedSearchDetails((values) => ({ ...values, notification_types: [...values.notification_types.filter((type) => type !== value), value] }));
                      }}
                      onRemove={(value) => {
                        setSavedSearchDetails((values) => ({ ...values, notification_types: values.notification_types.filter((type) => type !== value) }));
                      }}
                      getLabel={(o) => capitalizeFirstLetter(o.toLowerCase())}
                      className={"md:grid-cols-2"}
                    />
                  </div>
                </div>
                {savedSearchDetails.notification_types.includes("WHATSAPP") && whatsappRequired && (
                  <div className="flex flex-wrap md:flex-nowrap gap-4">
                    <div className="w-full md:w-1/2 pr-2">
                      <label className="text-xs text-gray-500">Whatsapp phone number</label>
                      <PhoneNumberInput phone={whatsappPhone} onChange={(e) => setWhatsappPhone(e)} />
                    </div>
                  </div>
                )}
                <div className="mt-5">Choose when to receive your alerts</div>
                <div className="flex items-center gap-4">
                  <div className="flex items-center">
                    <input
                      type="radio"
                      id="instant"
                      name="notification_frequency"
                      value="INSTANT"
                      checked={savedSearchDetails.notification_frequency == "INSTANT"}
                      onChange={(e) => {
                        setSavedSearchDetails({ ...savedSearchDetails, notification_frequency: e.target.value });
                      }}
                      className="h-5 w-5 cursor-pointer accent-primary"
                    />
                    <label htmlFor="instant" className="p-2 cursor-pointer">
                      Instantly (recommended)
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="radio"
                      id="daily_summary"
                      name="notification_frequency"
                      value="DAILY"
                      checked={savedSearchDetails.notification_frequency == "DAILY"}
                      onChange={(e) => {
                        setSavedSearchDetails({ ...savedSearchDetails, notification_frequency: e.target.value });
                      }}
                      className="h-5 w-5 cursor-pointer accent-primary"
                    />
                    <label htmlFor="daily_summary" className="p-2 cursor-pointer">
                      Daily
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:fixed pb-3 inset-x-0 bottom-0 bg-white flex justify-between w-full border-t pt-3 px-8 md:pb-3">
              <button type="submit" className="btn-primary w-full">
                {user ? "Update search" : "Continue"}
              </button>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

import React, { useEffect, useRef, useState } from "react";

const LoadingButton = ({ loading, children, disabled, ...rest }) => {
  // Create a ref to measure the content dimensions
  const [buttonDimensions, setButtonDimensions] = useState({ width: 0, height: 0 });
  const buttonContentRef = useRef(null);

  // Update content dimensions when children change and not loading
  useEffect(() => {
    if (buttonContentRef.current && !loading) {
      setButtonDimensions({
        width: buttonContentRef.current.offsetWidth,
        height: buttonContentRef.current.offsetHeight
      });
    }
  }, [children, loading]);

  return (
    <button
      {...rest}
      disabled={loading || disabled}
      ref={buttonContentRef}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        opacity: disabled || loading ? 0.7 : 1,
        cursor: disabled || loading ? "not-allowed" : "pointer",
        minWidth: buttonDimensions.width ? `${buttonDimensions.width}px` : "auto",
        minHeight: buttonDimensions.height ? `${buttonDimensions.height}px` : "auto"
      }}>
      {loading ? (
        <div className="flex justify-center items-center">
          <div className="animate-spin border-l-primary inline-block w-4 h-4 border-[0.1em] rounded-full">
            <span className="hidden">Loading...</span>
          </div>
        </div>
      ) : (
        children
      )}
    </button>
  );
};

export default LoadingButton;

import React, { useState } from "react";

import { toast } from "react-hot-toast";
import { FaRegCheckCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

import { setUser } from "@/redux/auth/actions";
import api from "@/services/api";
import { Statsig } from "@/services/statsig";
import { STATSIG_EVENTS } from "@/utils/statsigEvents";
import Loader from "../../components/Loader";
import PhoneNumberInput from "../../components/phoneNumberInput";
import { Mixpanel } from "../../services/mixpanel";
import { isPhoneValid } from "../../utils";

const Contact = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.Auth);
  const [values, setValues] = useState(user);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      Statsig.logEvent(STATSIG_EVENTS.account_contact_save_btn);
      if (!isPhoneValid(values.whatsapp_phone)) {
        return toast.error("Please enter a valid phone number");
      }

      const res = await api.put("/user", values);
      if (!res.ok) return toast.error("Profile update failed, please try again or contact support");
      toast.success("Profile updated successfully");
      dispatch(setUser(res.data));
      Mixpanel.track("profile_save_btn");
    } catch (error) {
      toast.error("Profile update failed");
      console.log(error);
    }
  };

  return (
    <div>
      <div className="text-3xl mb-10">Contact details</div>
      {!values ? (
        <Loader />
      ) : (
        <>
          <form>
            <div className="flex flex-col gap-4 w-full lg:w-2/3">
              <div className="flex flex-wrap md:flex-nowrap gap-4">
                <div className="w-full md:w-1/2 md:pr-2">
                  <label className="text-xs text-gray-500">Email</label>
                  <input type="text" disabled className="w-full" value={values?.email} />
                </div>
              </div>
              <div className="flex flex-wrap md:flex-nowrap gap-4">
                <div className="w-full md:w-1/2 md:pr-2">
                  <label className="text-xs text-gray-500 flex justify-start items-center">
                    Whatsapp phone number
                    {user.is_whatsapp_verified && <FaRegCheckCircle className="text-green-500 ml-1" />}
                  </label>
                  <PhoneNumberInput phone={values.whatsapp_phone} onChange={(e) => setValues({ ...values, whatsapp_phone: e })} />
                  <div className="text-sm text-gray-700 leading-none mt-1">
                    {user.is_whatsapp_undeliverable && (
                      <div className="text-red-500">
                        <div>Phone number is undeliverable</div>
                        <div className="mt-1">Please update your phone number</div>
                      </div>
                    )}
                    {!user.is_whatsapp_verified && user.whatsapp_phone && !user.is_whatsapp_undeliverable && (
                      <div>
                        <div>Phone in verification...</div>
                        <div className="mt-1">You should receive a welcome message on Whatsapp in the next few minutes</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex">
                <button onClick={handleSubmit} className="btn-primary w-full md:w-fit md:px-10">
                  Save
                </button>
              </div>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default Contact;

import React, { useState } from "react";

import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";

import { setUser } from "@/redux/auth/actions";
import api from "@/services/api";
import { Statsig } from "@/services/statsig";
import CalendarIcon from "../../assets/svg/calendar.svg?react";
import { CheckBoxes } from "../../components/CheckBoxes";
import Loader from "../../components/Loader";
import { Mixpanel } from "../../services/mixpanel";
import { STATSIG_EVENTS } from "@/utils/statsigEvents";

const Profile = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.Auth);

  const [values, setValues] = useState(user);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      Statsig.logEvent(STATSIG_EVENTS.account_profile_save_btn);
      const res = await api.put("/user", values);
      if (!res.ok) return toast.error("Profile update failed, please try again or contact support");
      toast.success("Profile updated successfully");
      dispatch(setUser(res.data));
      Mixpanel.track("profile_save_btn");
    } catch (error) {
      toast.error("Profile update failed");
      console.log(error);
    }
  };

  const formatDateString = (date) => new Date(date).toISOString().split("T")[0];

  return (
    <div>
      <div className="text-3xl mb-10">My Profile</div>
      {!values ? (
        <Loader />
      ) : (
        <>
          <form>
            <div className="flex flex-col gap-8 w-full lg:w-2/3">
              {/* firstname and lastname */}
              <div className="flex flex-wrap md:flex-nowrap gap-4">
                <div className="w-full flex flex-col gap-1">
                  <label className="text-xs text-gray-500">Firstname *</label>
                  <input type="text" className="w-full" value={values?.firstname} onChange={(e) => setValues({ ...values, firstname: e.target.value })} />
                </div>
                <div className="w-full flex flex-col gap-1">
                  <label className="text-xs text-gray-500">Lastname *</label>
                  <input type="text" className="w-full" value={values?.lastname} onChange={(e) => setValues({ ...values, lastname: e.target.value })} />
                </div>
              </div>

              {/* gender */}
              <div className="w-full flex flex-col">
                <div className="text-sm font-medium text-gray-600 mb-2">Gender</div>
                <div className="flex flex-wrap gap-7">
                  {[
                    {
                      value: "MALE",
                      label: "Male",
                    },
                    {
                      value: "FEMALE",
                      label: "Female",
                    },
                    {
                      value: "OTHER",
                      label: "Other",
                    },
                  ].map((option, n) => (
                    <div key={n}>
                      <label className="inline-flex flex-wrap items-center gap-2 cursor-pointer">
                        <input
                          id={`gender_${n}`}
                          className="flex flex-initial min-w-0 w-4 h-4 accent-primary cursor-pointer"
                          name="gender"
                          type="radio"
                          value={option.value}
                          checked={values.gender === option.value}
                          onChange={() => {
                            setValues({ ...values, gender: option.value });
                          }}
                        />
                        <span className="flex-1 leading-none">{option.label}</span>
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              {/* date of birth */}
              <div className="w-full lg:w-1/2 flex flex-col">
                <div className="text-sm font-medium text-gray-600 mb-2">Date of birth</div>
                <div className="relative">
                  <input
                    onClick={(e) => {
                      e.currentTarget.showPicker();
                    }}
                    max={formatDateString(new Date())}
                    type="date"
                    className="w-full cursor-pointer"
                    value={values?.date_of_birth ? formatDateString(values.date_of_birth) : ""}
                    onChange={(e) => {
                      setValues({ ...values, date_of_birth: new Date(e.target.value) });
                    }}
                  />
                  <div className="rounded-[4px] border border-transparent absolute inset-0 pointer-events-none overflow-hidden">
                    <div className="bg-white flex items-center justify-between px-3 py-2 w-full h-full">
                      <span className={`text-sm ${values.date_of_birth ? "" : "text-gray-500"}`}>
                        {values.date_of_birth ? formatDateString(values.date_of_birth) : "Pick a date"}
                      </span>
                      <CalendarIcon />
                    </div>
                  </div>
                </div>
              </div>

              {/* when are you looking to move? */}
              <div className="w-full flex-col">
                <div className="text-sm font-medium text-gray-600 mb-2">When are you looking to move? *</div>
                <CheckBoxes
                  options={["As soon as possible", "Next month", "In 2 months", "In 3 months+"]}
                  value={values.move_in_period}
                  onAdd={(e) => setValues({ ...values, move_in_period: e })}
                  onRemove={() => setValues({ ...values, move_in_period: null })}
                  className="md:grid-cols-4"
                />
              </div>

              <div className="w-full flex flex-col">
                <div className="text-sm font-medium text-gray-600 mb-2">What best describes your current situation? *</div>
                <CheckBoxes
                  options={[
                    { label: "Student", value: "STUDENT" },
                    { label: "Employee", value: "EMPLOYEE" },
                    { label: "Self-employed", value: "SELF_EMPLOYED" },
                    { label: "Other", value: "OTHER" },
                  ]}
                  value={values.employment_status}
                  onAdd={(e) => setValues({ ...values, employment_status: e.value })}
                  onRemove={() => setValues({ ...values, employment_status: null })}
                  getValue={(o) => o.value}
                  getLabel={(o) => o.label}
                  className="md:grid-cols-4"
                />
              </div>

              {/* how long do you plan to stay in your next home? */}
              <div className="w-full flex flex-col">
                <div className="text-sm font-medium text-gray-600 mb-2">Are you looking for a short-term, medium-term, or long-term rental?</div>

                <CheckBoxes
                  options={[
                    {
                      value: "1-3 months",
                      label: "Short term (1-3 months)",
                    },
                    {
                      value: "3-12 months",
                      label: "Medium term (3-12 months)",
                    },
                    {
                      value: "12+ months",
                      label: "Long term (12+ months)",
                    },
                  ]}
                  value={values.stay_duration}
                  onAdd={(duration) => {
                    setValues((values) => ({ ...values, stay_duration: duration.value }));
                  }}
                  onRemove={() => {
                    setValues((values) => ({ ...values, stay_duration: null }));
                  }}
                  getValue={(o) => o.value}
                  getLabel={(o) => o.label}
                  className={"md:grid-cols-3"}
                />
              </div>

              {/* what’s your monthly income range? */}
              <div className="w-full flex flex-col">
                <div className="text-sm font-medium text-gray-600 mb-2">What’s your monthly income range?</div>

                <div className="w-full flex flex-col gap-2">
                  {[
                    { label: "€0-€2k", min: 0, max: 2000 },
                    { label: "€2k-€3k", min: 2000, max: 3000 },
                    { label: "€3k-€4k", min: 3000, max: 4000 },
                    { label: "€4k-€5k", min: 4000, max: 5000 },
                    { label: "€5k-€6k", min: 5000, max: 6000 },
                    { label: "€6k-€7k", min: 6000, max: 7000 },
                    { label: "More than €7k", min: 7000, max: null },
                  ].map(({ label, max: income_max, min: income_min }, n) => (
                    <div key={n}>
                      <label className="inline-flex flex-wrap items-center gap-2 cursor-pointer">
                        <input
                          className="flex flex-initial min-w-0 w-4 h-4 accent-primary cursor-pointer"
                          name="income_range"
                          id={`income_range_${n}`}
                          type="radio"
                          value={true}
                          checked={values.income_min === income_min && values.income_max === income_max}
                          onChange={() => {
                            setValues({ ...values, income_max, income_min });
                          }}
                        />
                        <span className="flex-1 leading-none">{label}</span>
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              {/* do you have pets? */}
              <div className="w-full flex flex-col">
                <div className="text-sm font-medium text-gray-600 mb-2">Do you have pets?</div>

                <div>
                  <label className="inline-flex flex-wrap items-center gap-2 cursor-pointer">
                    <input
                      className="flex flex-initial min-w-0 w-4 h-4 accent-primary cursor-pointer"
                      name="has_pets"
                      type="radio"
                      id="has_pets_yes"
                      value={true}
                      checked={values.has_pets}
                      onChange={() => {
                        setValues({ ...values, has_pets: true });
                      }}
                    />
                    <span className="flex-1 leading-none">Yes</span>
                  </label>
                </div>

                <div>
                  <label className="inline-flex flex-wrap items-center gap-2 cursor-pointer">
                    <input
                      className="flex flex-initial min-w-0 w-4 h-4 accent-primary cursor-pointer"
                      name="has_pets"
                      type="radio"
                      id="has_pets_no"
                      value={false}
                      checked={values.has_pets === false}
                      onChange={() => {
                        setValues({ ...values, has_pets: false });
                      }}
                    />
                    <span className="flex-1 leading-none">No</span>
                  </label>
                </div>
              </div>

              <div className="flex">
                <button onClick={handleSubmit} className="btn-primary w-full md:w-fit md:px-10">
                  Save
                </button>
              </div>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default Profile;

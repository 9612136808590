import React from "react";
import { Helmet } from "react-helmet";
import { ENVIRONMENT } from "../config";

const Crisp = () => {
  if (ENVIRONMENT !== "production") return <div />;

  return (
    <Helmet>
      <script>
        {`
window.$crisp=[];window.CRISP_WEBSITE_ID="43d6df19-b162-4aa1-84cb-3e51c02cba51";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);
// push the events to the crisp bot to trigger the bot
$crisp.push([
  "on",
  "chat:opened",
  function () {
    if ($crisp.is("session:ongoing")) {
      console.log("$crisp session ongoing, do nothing");
    } else {
      console.log("$crisp do opened: ", $crisp);
      $crisp.push(["do", "message:send", ["text", "Hi, I have a question"]]);
    }
  },
]);
})();
        `}
      </script>
    </Helmet>
  );
};

export default Crisp;

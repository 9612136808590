import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";

import { navigation } from "@/constants/index";
import useOnClickOutside from "@/hooks/useOnClickOutside";
import { Mixpanel } from "@/services/mixpanel";
import { VscAccount } from "react-icons/vsc";
import styles from "./navbar.module.css";
import NavItems from "./NavItems";

const DesktopNavMenu = () => {
  const { user } = useSelector((state) => state.Auth);
  const navigate = useNavigate();
  let [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);
  const toggleButtonRef = useRef(null);

  useOnClickOutside([dropdownRef, toggleButtonRef], () => setOpen(false));

  const getInitials = (str = "") => {
    const words = str.split(" ");
    const initials = words
      .map((word) => word.charAt(0))
      .join(" ")
      .toUpperCase();
    return initials;
  };

  const AuthButtons = () => (
    <div className="flex flex-row w-full  gap-1 justify-end items-center">
      <button onClick={() => navigate("/auth/signin")} className="md:w-28 w-24 md:h-10 h-8 btn-secondary">
        Login
      </button>
      <button onClick={() => navigate("/auth/signup")} className="md:w-28 w-24 md:h-10 h-8 btn-primary">
        Sign up
      </button>
    </div>
  );

  return (
    <>
      <div className="flex flex-row w-full space-x-1 justify-end">
        {user ? (
          <>
            {navigation
              .filter((nav) => nav.desktopNavBarDisplayIcon)
              .map((item, index) => (
                <div key={index} className="md:flex hidden items-center">
                  <NavLink
                    key={item.name}
                    to={item.href}
                    exact={item.exact}
                    onClick={() => {
                      Mixpanel.track("sidebar_click_" + item.name.toLowerCase().replace(" ", "_"));
                    }}
                    className={({ isActive }) => {
                      const classes = "group flex justify-start items-center gap-x-1 lg:gap-x-3 lg:p-2.5 p-1.5 text-primary-black-90 hover:bg-primary-100";
                      const activeClasses = "text-white bg-primary hover:bg-primary";
                      return isActive ? classes + " " + activeClasses : classes;
                    }}>
                    <div className="flex relative">
                      <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                      {item.name === "Favorites" && user?.favorite_adverts?.length > 0 && (
                        <span className="bg-secondary w-5 h-5 text-white absolute -top-2.5 -right-2.5 leading-none text-[10px] rounded-full text-center py-[5.5px]">
                          {user.favorite_adverts.length}
                        </span>
                      )}
                    </div>
                  </NavLink>
                </div>
              ))}
            <div ref={toggleButtonRef} className={`${open && styles.tooltip} pl-3 flex items-center cursor-pointer rounded-full`} onClick={() => setOpen((state) => !state)}>
              {user.firstname ? (
                <div className="h-8 w-8 flex items-center justify-center rounded-full border border-primary text-primary">{getInitials(user?.firstname)}</div>
              ) : (
                <VscAccount className="h-7 w-7 shrink-0" aria-hidden="true" />
              )}
            </div>
          </>
        ) : (
          <AuthButtons />
        )}
      </div>
      {open && <div ref={dropdownRef}>{open && <NavItems setOpen={setOpen} />}</div>}
    </>
  );
};

export default DesktopNavMenu;

import React, { useEffect, useState } from "react";

import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import { FaRegFlag } from "react-icons/fa6";
import { GoHome } from "react-icons/go";
import { LuChevronRight } from "react-icons/lu";
import { MdFavorite, MdFavoriteBorder, MdLocalPhone } from "react-icons/md";
import { RxOpenInNewWindow } from "react-icons/rx";

import LocationIcon from "@/assets/svg/location.svg?react";

import ImageSlider from "@/components/ImageSlider";
import Loader from "@/components/Loader";
import Modal from "@/components/Modal";
import api from "@/services/api";
import { Statsig } from "@/services/statsig";
import { capitalizeFirstLetter, getDateAvailability, timeAgo } from "@/utils";
import { PAID_SOURCES, SOURCES_AFFILIATION_ARGUMENTS } from "@/utils/constants";
import { STATSIG_EVENTS } from "@/utils/statsigEvents";
import { setUser } from "../../../redux/auth/actions";
import { Mixpanel } from "../../../services/mixpanel";
import CustomMessageModal from "./CustomMessage";
import UpgradePopups from "./UpgradePopups";
import SaveFavoriteNoAuth from "../SaveFavoriteNoAuth";

const isFieldKnown = (field) => {
  return field !== null && field !== undefined;
};

const View = ({ navigation, advertId }) => {
  const dispatch = useDispatch();
  const id = useParams().id || advertId;
  const { user } = useSelector((state) => state.Auth);

  const [advert, setAdvert] = useState(null);
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openReport, setOpenReport] = useState(false);
  const [openCustomMessage, setOpenCustomMessage] = useState(false);
  const [isUpgradePopupOpen, setIsUpgradePopupOpen] = useState(false);
  const [isSaveFavNoAuthOpen, setIsSaveFavNoAuthOpen] = useState(false);

  useEffect(() => {
    const fetchAdvert = async () => {
      try {
        setIsLoading(true);
        const res = await api.get("/advert/" + id);
        setAdvert(res.data);
      } catch (e) {
        console.log("e", e);
        toast.error(e?.code || "Error");
      } finally {
        setIsLoading(false);
      }
    };

    if (!advert) {
      fetchAdvert();
    }
  }, []);

  const addFavorite = async (advert) => {
    if (!user) return setIsSaveFavNoAuthOpen(true);
    try {
      const res = await api.post(`/user/favorite-advert/${advert._id}`);
      if (!res.ok) throw new Error("Error");
      dispatch(setUser(res.data));
    } catch (e) {
      console.log("e", e);
      toast.error(e?.code || "Error");
    }
  };

  const removeFavorite = async (advert) => {
    try {
      if (!window.confirm("Are you sure you want to remove this listing from your favorites?")) return;
      const res = await api.delete(`/user/favorite-advert/${advert._id}`);
      if (!res.ok) throw new Error("Error");
      dispatch(setUser(res.data));
    } catch (e) {
      console.log("e", e);
      toast.error(e?.code || "Error");
    }
  };

  const renderAdvertInfos = () => {
    if (!advert) return "";

    const parts = [];

    // Add furnishing information, capitalized
    if (advert.furnishing) {
      parts.push(capitalizeFirstLetter(advert.converted_furnishing));
    }

    // Add room information based on the number of rooms
    if (advert.rooms) {
      parts.push(advert.rooms + (advert.rooms === 1 ? " room" : " rooms"));
    }

    // Add bedroom information based on the number of bedrooms
    if (advert.bedrooms) {
      parts.push(advert.bedrooms + (advert.bedrooms === 1 ? " bedroom" : " bedrooms"));
    }

    // Join all parts with " - " as separator
    return parts.join(" - ");
  };

  const getBalconyDetails = () => {
    if (!isFieldKnown(advert.balcony?.has)) return "";

    const detailsList = advert.balcony.has ? ["Yes"] : ["No"];
    if (advert.balcony.surface) detailsList.push(`${advert.balcony.surface}m²`);
    if (advert.balcony.info) detailsList.push(advert.balcony.info);
    return detailsList.join(", ");
  };

  const getParkingDetails = () => {
    if (!isFieldKnown(advert.parking?.has)) return "";

    const detailsList = advert.parking.has ? ["Yes"] : ["No"];
    if (advert.parking.is_paid) detailsList.push("Paid");
    if (advert.parking.has_garage) detailsList.push("Garage");
    if (advert.parking.info) detailsList.push(advert.parking.info);
    return detailsList.join(", ");
  };

  const getGardenDetails = () => {
    if (!isFieldKnown(advert.garden?.has)) return "";

    const detailsList = advert.garden.has ? ["Yes"] : ["No"];
    if (advert.garden.surface) detailsList.push(`${advert.garden.surface}m²`);
    if (advert.garden.type) detailsList.push(advert.garden.type);
    if (advert.garden.info) detailsList.push(advert.garden.info);
    return detailsList.join(", ");
  };

  const getPetDetails = () => {
    const isPetKnown = isFieldKnown(advert.pets?.allowed);
    if (!isPetKnown) return "";

    const detailsList = advert.pets.allowed ? ["Yes"] : ["No"];
    if (advert.pets.types && advert.pets.types.length) detailsList.push(`(${advert.pets.types.join(", ")})`);
    if (advert.pets.info) detailsList.push(advert.pets.info);
    return detailsList.join(", ");
  };

  const getIncomeRestrictionDetails = () => {
    if (!isFieldKnown(advert.income_restriction?.has)) return "";

    const detailsList = advert.income_restriction.has ? ["Yes"] : ["No"];
    if (advert.income_restriction.amount) detailsList.push(`${advert.income_restriction.amount}€`);
    if (advert.income_restriction.info) detailsList.push(advert.income_restriction.info);
    return detailsList.join(", ");
  };

  if (!advert || isLoading) return <Loader />;

  const containerSidePadding = "px-5 md:px-[10%] lg:px-32";
  const isFavourite = user?.favorite_adverts.find((fav) => fav.advert_id == advert?._id);
  const advertImages = advert.images.length > 0 ? advert.images : ["https://rentsaver.s3.nl-ams.scw.cloud/app/propertyPlaceHolder.png"];

  return (
    <div className={`${containerSidePadding} py-8`}>
      <UpgradePopups isOpen={isUpgradePopupOpen} setIsOpen={setIsUpgradePopupOpen} />
      <SaveFavoriteNoAuth isOpen={isSaveFavNoAuthOpen} setIsOpen={setIsSaveFavNoAuthOpen} />
      {/* Navigation */}
      {navigation || (
        <div className="flex flex-wrap gap-1 item-center text-base text-gray-600 mb-8">
          <Link to={"/"} className="h-6 w-6 text-lg cursor-pointer flex items-center justify-center hover:text-primary">
            <GoHome />
          </Link>
          <div className="h-6 w-6 pointer-events-none flex items-center justify-center">
            <LuChevronRight />
          </div>
          <div className="flex items-center justify-center">
            <span className="capitalize">{capitalizeFirstLetter(advert.city)}</span>
          </div>
          <div className="h-6 w-6 pointer-events-none flex items-center justify-center">
            <LuChevronRight />
          </div>
          <div className="flex items-center justify-center">
            {capitalizeFirstLetter(advert.city)} - {capitalizeFirstLetter(advert.converted_type)} {advert.surface && <> - {advert.surface}m²</>}
          </div>
        </div>
      )}

      {/* Header */}
      <div className="flex-col lg:flex-row flex justify-start items-start gap-6">
        <div className="flex-1">
          {!advert.is_active && (
            <div className="mb-2">
              <span className="rounded-md bg-red-100 text-red-900 px-3 py-1">{advert.inactive_on ? `Rented ${timeAgo(advert.inactive_on)} ago` : "Rented!"}</span>
            </div>
          )}
          <h1 className={`font-bold text-3xl lg:text-4xl mb-2 md:mb-4 ${advert.is_active ? "" : "line-through"}`}>
            {capitalizeFirstLetter(advert.city)} - {capitalizeFirstLetter(advert.converted_type)} {advert.surface && <> - {advert.surface}m²</>}
          </h1>
          <div className="flex flex-wrap gap-4 items-center">
            <h3 className="font-normal text-xl">{renderAdvertInfos()}</h3>
          </div>
        </div>
        <div>
          <span className="font-medium text-2xl lg:text-4xl mb-4">{advert.price} </span>
          <span className="text-xl lg:text-2xl">€/month</span>
        </div>
      </div>

      <div className="py-4 flex gap-12">
        {/* Info */}
        <div className="flex flex-col lg:flex-[2] w-full gap-4">
          {/* Slider */}
          <div className="relative w-full rounded-xl overflow-hidden shadow-sm border border-gray-200">
            <ImageSlider>
              {advertImages.map((imageUrl, n) => (
                <div key={n} className="h-[40vh] md:h-[60vh] bg-center bg-cover" style={{ backgroundImage: `url(${imageUrl})` }}></div>
              ))}
            </ImageSlider>
            <div className="absolute top-3 left-3">
              <div
                onClick={() => {
                  if (isFavourite) {
                    Statsig.logEvent(STATSIG_EVENTS.remove_favorite, "advert_page");
                    Mixpanel.track("match_remove_favorite", advert);
                    removeFavorite(advert);
                  } else {
                    Statsig.logEvent(STATSIG_EVENTS.add_favorite, "advert_page");
                    Mixpanel.track("match_add_favorite", advert);
                    addFavorite(advert);
                  }
                }}
                className="py-1 px-2 rounded-full border border-gray-100 bg-white bg-opacity-70 cursor-pointer relative">
                <span className="text-sm flex items-start justify-start">
                  {isFavourite ? (
                    <div className="flex flex-row justify-start items-center gap-1">
                      <MdFavorite size={25} color="#FF6A00" className="transition-transform duration-300 group-hover:scale-125" />
                      Saved
                    </div>
                  ) : (
                    <div className="flex flex-row justify-start items-center gap-1">
                      <MdFavoriteBorder size={25} color="black" className="transition-transform duration-300 group-hover:scale-125" onClick={() => {}} />
                      Save
                    </div>
                  )}
                </span>
              </div>
            </div>
          </div>

          {/* Summary */}
          <div className="flex flex-col gap-y-10">
            <section className="text-base bg-white md:text-lg">
              <p className="flex items-center flex-wrap col-span-2 mb-4 gap-2">
                <LocationIcon className="h-[18px]" />
                {advert.city && advert.location_details?.street && advert.location_details?.house_number ? (
                  // case where we are collecting everything on the listing, no need to use the api answer (which sometimes removes the house number)
                  <>
                    {advert.location_details?.street} {advert.location_details?.house_number}, {capitalizeFirstLetter(advert.city)}
                  </>
                ) : (
                  advert.location_details_from_api.address
                )}
              </p>
              <div className="grid grid-cols-2 gap-1 md:gap-2">
                {Boolean(advert.converted_type) && (
                  <p>
                    <span className="font-semibold">Type:</span> {capitalizeFirstLetter(advert.converted_type)}
                  </p>
                )}
                {Boolean(advert.surface) && (
                  <p>
                    <span className="font-semibold">Surface:</span> {advert.surface} m²
                  </p>
                )}
                {Boolean(advert.rooms) && (
                  <p>
                    <span className="font-semibold">Rooms:</span> {advert.rooms}
                  </p>
                )}
                {Boolean(advert.bedrooms) && (
                  <p>
                    <span className="font-semibold">Bedrooms:</span> {advert.bedrooms}
                  </p>
                )}
              </div>
            </section>
            {/* Description */}
            {Boolean(advert.description) && (
              <div className="flex flex-col">
                <div className="text-3xl font-semibold mb-4">Description</div>
                <div className={`text-lg whitespace-pre-wrap ${!isDescriptionExpanded && advert.description.length > 600 ? "line-clamp-6" : ""}`}>{advert.description}</div>
                {advert.description.length > 600 && (
                  <span
                    onClick={() => {
                      setIsDescriptionExpanded(!isDescriptionExpanded);
                    }}
                    className="mt-2 font-medium rounded-md border border-gray-300 px-2 py-1 self-start min-w-20 flex items-center justify-center cursor-pointer hover:border-gray-500">
                    {isDescriptionExpanded ? "See Less" : "See More"}
                  </span>
                )}
              </div>
            )}
            {/* Housing details */}
            <div>
              <div className="text-3xl font-semibold mb-4">Housing Details</div>
              <div className="flex flex-col gap-y-8">
                {/* Construction info */}
                <Section
                  title={"Housing Details"}
                  list={[
                    { key: "Construction Year", value: advert.construction_year },
                    { key: "Energy Label", value: advert.energy_label },
                  ]}
                />

                {/* Bathrooms */}
                <Section
                  title={"Bathrooms"}
                  list={[
                    { key: "No of Bathrooms", value: advert.bathrooms.count },
                    { key: "With Shower", value: advert.bathrooms.has_shower ? "Yes" : "" },
                    { key: "With Bathtub", value: advert.bathrooms.has_bathtub ? "Yes" : "" },
                    { key: "With Toilet", value: advert.bathrooms.has_toilet ? "Yes" : "" },
                    { key: "Shared", value: advert.bathrooms.is_shared ? "Yes" : "" },
                  ]}
                />

                {/* Exterior Features */}
                <Section
                  title={"Exterior Features"}
                  list={[
                    { key: "Balcony", value: getBalconyDetails() },
                    { key: "Parking", value: getParkingDetails() },
                    { key: "Garden", value: getGardenDetails() },
                    { key: "Pet Friendly", value: getPetDetails() },
                  ]}
                />

                {/* Additional Information */}
                <Section
                  title={"Additional Information"}
                  list={[
                    { key: "Furnishing", value: advert.furnishing ? capitalizeFirstLetter(advert.furnishing) : "" },
                    { key: "Move-in Date", value: getDateAvailability(advert.move_in_date || new Date()) },
                    { key: "Move-out Date", value: advert.move_out_date ? new Date(advert.move_out_date).toLocaleDateString() : "" },
                    {
                      key: "Rental Period",
                      value: advert.rental_period_in_days ? (advert.rental_period_in_days == 37620 ? "Infinite" : `${advert.rental_period_in_days} days`) : "",
                    },
                    { key: "Suitable For", value: advert.suitable_for },
                    { key: "Roommates", value: advert.roommates },
                    { key: "Heating", value: advert.heating },
                    { key: "Income Restriction", value: getIncomeRestrictionDetails() },
                    { key: "Registration Possible", value: advert.registration_possible ? "Yes" : "" },
                  ]}
                />
              </div>
            </div>

            {/* Reports */}
            <ReportModal advert={advert} openReport={openReport} setOpenReport={setOpenReport} />

            {/* Agent details (mobile) */}
            <div className="flex flex-col gap-6 mb-20 lg:hidden">
              <div>
                <AgentInformation className={"flex flex-col gap-1 p-4 rounded-xl shadow-sm border border-gray-200 lg:hidden mb-1"} advert={advert} />
                {Boolean(advert.created_at) && (
                  <>
                    <div className="flex lg:hidden text-gray-500 text-sm">First seen: {timeAgo(advert.created_at)} ago</div>
                    <div className="flex lg:hidden text-gray-500 text-sm">Last checked: {advert.last_check ? timeAgo(advert.last_check) : timeAgo(advert.created_at)} ago</div>
                  </>
                )}
              </div>
              <div>
                <span
                  onClick={() => {
                    setOpenReport(true);
                  }}
                  className="inline-flex relative flex-wrap gap-2 items-center my-2 cursor-pointer">
                  <FaRegFlag className="h-3" /> Report this property
                </span>
              </div>
            </div>
          </div>
        </div>

        <CustomMessageModal advert={advert} openCustomMessage={openCustomMessage} setOpenCustomMessage={setOpenCustomMessage} />
        <div className="bottom-0 z-10 fixed w-full left-0 right-0 lg:relative lg:flex-[1]">
          <div
            className={`${containerSidePadding} h-[--bottom-bar-height] rounded-tl-[20px] rounded-tr-[20px] shadow-[0px_-2px_10px_0px_#0000001A] lg:h-auto lg:rounded-none lg:shadow-none py-4 lg:border-t bg-white border-gray-300 lg:border-none lg:!px-0 lg:py-0 sticky inset-y-[calc(var(--header-height)+2rem)] w-full flex justify-center lg:justify-stretch flex-col lg:gap-4`}>
            <div>
              <AgentInformation className={"hidden lg:flex flex-col gap-1 p-4 rounded-xl shadow-sm border border-gray-200 mb-1"} advert={advert} />
              {Boolean(advert.created_at) && (
                <>
                  <div className="hidden lg:flex text-gray-500 text-sm">First seen: {timeAgo(advert.created_at)} ago</div>
                  <div className="hidden lg:flex text-gray-500 text-sm">Last checked: {advert.last_check ? timeAgo(advert.last_check) : timeAgo(advert.created_at)} ago</div>
                </>
              )}
            </div>
            <button
              onClick={() => {
                Mixpanel.track("match_click_photo_housing_matches", advert);

                Statsig.logEvent(STATSIG_EVENTS.advert_page_click_contact_landlord, advert._id, {
                  advert_id: advert._id,
                  source: advert.source,
                  advert_price: advert.price,
                  advert_type: advert.type,
                });
                if (user && user.type === "PAID") {
                  if (Statsig.checkGate("custom_message_template_partial_release")) setOpenCustomMessage(true);
                  else {
                    let url = advert.url;
                    if (SOURCES_AFFILIATION_ARGUMENTS[advert.source]) url += SOURCES_AFFILIATION_ARGUMENTS[advert.source];
                    window.open(url, "_blank");
                  }
                } else {
                  setIsUpgradePopupOpen(true);
                }
              }}
              className="!rounded-2xl btn-primary w-full">
              <div className="flex justify-center items-center text-sm gap-2">
                Contact landlord
                <RxOpenInNewWindow />
              </div>
            </button>
            <div className="hidden lg:flex">
              <span
                onClick={() => {
                  setOpenReport(true);
                }}
                className="hidden lg:inline-flex relative flex-wrap gap-2 items-center my-2 cursor-pointer">
                <FaRegFlag className="h-3" /> Report this property
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Section = ({ list, title }) => {
  list = list.filter(({ value }) => Boolean(value));

  if (!list.length) return null;

  return (
    <div className="flex flex-col">
      <div className="text-xl font-semibold">{title}</div>
      <ul>
        {list.map(({ key, value }, n) => (
          <li key={n} className="flex justify-between py-3 gap-4 border-b border-gray-200">
            <span className="text-gray-700">{key}</span>
            <span>{value}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

const AgentInformation = ({ advert, className }) => {
  return (
    <div className={className}>
      <div className="mb-4 text-xl">
        <span className="font-light">By </span>
        <span className="text-black capitalize font-medium">{advert.source.toLowerCase()}</span>
      </div>
      {(Boolean(advert.agent?.website) || Boolean(advert.agent?.email) || Boolean(advert.agent?.phone)) && (
        <div className="flex flex-col">
          <div className="text-black font-medium">{advert.agent?.name || "N/A"}</div>
          <div className="text-black flex flex-col">
            {Boolean(advert.agent?.phone) && (
              <div className="flex items-center">
                <a className="hover:underline inline-flex items-center gap-1" href={`tel:${advert.agent.phone}`}>
                  <MdLocalPhone /> {advert.agent.phone}
                </a>
              </div>
            )}
            {Boolean(advert.agent?.email) && (
              <div className="flex items-center">
                <a className="hover:underline text-blue-700" href={`mailto:${advert.agent.email}`}>
                  {advert.agent.email}
                </a>
              </div>
            )}
            {Boolean(advert.agent?.website) && (
              <div className="flex items-center">
                <a className="hover:underline text-blue-700" href={advert.agent.website} target="_blank">
                  {advert.agent.website}
                </a>
              </div>
            )}
          </div>
        </div>
      )}
      {(Boolean(advert.agency_fees) || Boolean(advert.deposit)) && (
        <section className="flex flex-col">
          {Boolean(advert.agency_fees) && (
            <p>
              <span className="text-black font-medium">Agency Fees:</span> {advert.agency_fees}€
            </p>
          )}
          {Boolean(advert.deposit) && (
            <p>
              <span className="text-black font-medium">Deposit:</span> {advert.deposit}€
            </p>
          )}
        </section>
      )}

      {PAID_SOURCES.some((source) => source === advert.source) && (
        <div className="mt-4 py-2 border-t border-gray text-gray-700">
          This external provider requires a paid account to respond. Prefer not to see paid listings? Exclude them in your filters.
        </div>
      )}
    </div>
  );
};

const ReportModal = ({ openReport, setOpenReport, advert }) => {
  const [reason, setReason] = useState("");
  const [type, setType] = useState("");
  const [loading, setLoading] = useState(false);

  const reset = () => {
    setLoading(false);
    setReason("");
    setType("");
  };

  const createAdvertReport = async (body) => {
    try {
      setLoading(true);
      await api.post("/advert/report", body);
      reset();
      setOpenReport(false);
      toast.success("Report submitted");
    } catch (e) {
      console.log("e", e);
      setLoading(false);
      toast.error(e?.code || "Error");
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const body = {
      reason,
      type,
      city: advert.city,
      source: advert.source,
      advert_id: advert._id,
      advert_url: advert.url,
      advert_type: advert.type,
      full_location: advert.full_location,
      converted_type: advert.converted_type,
    };

    await createAdvertReport(body);
  };

  return (
    <Modal
      innerClassName="md:flex md:max-w-[50rem] md:w-[60vw] md:h-[85vh] md:max-h-[calc(100vh-5rem)]"
      isOpen={openReport}
      onClose={() => {
        reset();
        setOpenReport(false);
      }}>
      <div className="w-full max-h-screen h-screen lg:h-auto flex flex-col overflow-y-hidden transform bg-white text-left align-middle shadow-xl transition-all">
        <div className="flex justify-between items-center px-8 py-5 border-b">
          <div className="text-xl font-semibold flex gap-2 items-center">Report this listing</div>
          <button
            className="text-2xl"
            onClick={() => {
              reset();
              setOpenReport(false);
            }}>
            X
          </button>
        </div>
        <form className="flex-1 w-full flex flex-col overflow-y-auto" action="POST" onSubmit={onSubmit}>
          <div className="py-4 px-8 mb-24 flex-1">
            <div className="text-primary font-bold text-xl pb-2">
              {capitalizeFirstLetter(advert.city)} - {capitalizeFirstLetter(advert.converted_type)} {advert.surface && <> - {advert.surface}m²</>}
            </div>
            <div className="flex flex-col gap-6 py-4">
              <label className="flex flex-wrap items-center gap-2">
                <input
                  className="flex flex-initial min-w-0 w-4 h-4 accent-primary"
                  name="advert_report"
                  type="radio"
                  value={"NOT_AVAILABLE"}
                  onChange={(e) => {
                    const value = e.target.value;
                    setType(value);
                  }}
                />
                <span className="flex-1 leading-none">Listing is no longer available</span>
              </label>

              <label className="flex flex-wrap items-center gap-2">
                <input
                  className="flex flex-initial min-w-0 w-4 h-4 accent-primary"
                  name="advert_report"
                  type="radio"
                  value={"INACCURATE"}
                  onChange={(e) => {
                    const value = e.target.value;
                    setType(value);
                  }}
                />
                <span className="flex-1 leading-none">Information is inaccurate</span>
              </label>

              <label className="flex flex-wrap items-center gap-2">
                <input
                  className="flex flex-initial min-w-0 w-4 h-4 accent-primary"
                  name="advert_report"
                  type="radio"
                  value={"FRAUD"}
                  onChange={(e) => {
                    const value = e.target.value;
                    setType(value);
                  }}
                />
                <span className="flex-1 leading-none">This is a fraudulent listing/spam</span>
              </label>

              <label className="flex flex-wrap items-center gap-2">
                <input
                  className="flex flex-initial min-w-0 w-4 h-4 accent-primary"
                  name="advert_report"
                  type="radio"
                  value={"NO_RESPONSE"}
                  onChange={(e) => {
                    const value = e.target.value;
                    setType(value);
                  }}
                />
                <span className="flex-1 leading-none">Haven't heard back from the property</span>
              </label>

              <label className="flex flex-wrap items-center gap-2">
                <input
                  className="flex flex-initial min-w-0 w-4 h-4 accent-primary"
                  name="advert_report"
                  type="radio"
                  value={"OTHERS"}
                  required
                  onChange={(e) => {
                    const value = e.target.value;
                    setType(value);
                  }}
                />
                <span className="flex-1 leading-none">Others</span>
              </label>
            </div>

            <label className="flex flex-col gap-2 my-2">
              <textarea
                required
                placeholder="Give us more details"
                name="report body"
                value={reason}
                className="resize-y min-h-20 max-h-40 p-2 rounded-lg w-full border"
                onChange={(e) => {
                  const value = e.target.value;
                  setReason(value);
                }}></textarea>
            </label>
          </div>
          <div className="pb-3 bottom-0 absolute bg-white flex justify-between w-full border-t pt-3 px-8 md:pb-3">
            <button
              type="button"
              className="btn-secondary"
              onClick={(e) => {
                reset();
                setOpenReport(false);
              }}>
              Cancel
            </button>
            <div>
              <button disabled={loading} type="submit" className="btn-primary w-32">
                Report
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default View;

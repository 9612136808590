import React, { useCallback, useEffect, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { MdLock } from "react-icons/md";

// import { STRIPE_PUBLIC_KEY } from "@/config";
import { plansLimited } from "@/constants";
import { setUser } from "@/redux/auth/actions";
import api from "@/services/api";
import { Mixpanel } from "@/services/mixpanel";
import { Statsig } from "@/services/statsig";
import { STATSIG_EVENTS } from "@/utils/statsigEvents";
import Fields from "./components/fields";
import PaymentForm from "./components/paymentForm";
import PaymentIntentResponse from "./components/paymentIntentResponse";

// to move to the config.js
const STRIPE_PUBLIC_KEY = "pk_live_51QHTFBG88wJnDlFhVYj2l4vVqgoF0N7TSMgpO28WyVKqgFEO4j3p8QMcbLSUYey4Hs5WNrC5k5YtGMwRnuoYw6GM00IAwcXQlr";

const Checkout = () => {
  const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);
  const dispatch = useDispatch();
  const location = useLocation();
  const { user } = useSelector((state) => state.Auth);
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);
  // setSelectedPlan, setOptions are not used as the plan do not change for now
  const [selectedPlan, setSelectedPlan] = useState(plansLimited.ONE_MONTH);
  const [options, setOptions] = useState({
    appearance: appearance,
    mode: "subscription",
    currency: "eur",
    amount: selectedPlan.amount,
    setup_future_usage: "off_session",
    paymentMethodTypes: ["card", "paypal", "link", "ideal"],
  });

  const urlParams = new URLSearchParams(location.search);

  // after payment
  const paymentIntentId = urlParams.get("payment_intent");
  const urlCustomerId = urlParams.get("customerId");
  const redirectStatus = urlParams.get("redirect_status"); // failed or succeeded
  const paymentMethod = urlParams.get("paymentMethod");

  useEffect(() => {
    updateStripeDetails();
    Statsig.logEvent(STATSIG_EVENTS.checkout_page_view);
  }, [user]);

  const updateStripeDetails = useCallback(async () => {
    try {
      if (user && !user?.stripe_customer_id_ltd) {
        const res = await api.post(
          `/user/upsert-stripe-new`,
          JSON.stringify({
            email: user?.email || "",
            firstname: user?.firstname || "",
            lastname: user?.lastname || "",
            password: undefined,
          }),
        );
        if (!res.ok) throw new Error(res?.code);
        if (res.data.token) api.setToken(res.data.token);
        if (res.data.user) dispatch(setUser(res.data.user));
      }
    } catch (e) {
      console.log("🚀 ~ updateStripeDetails ~ e:", e);
    }
  }, [user]);

  if (!selectedPlan || options.amount == undefined) {
    return <div>Loading...</div>;
  }

  if (user && user.type == "PAID" && !paymentIntentId) {
    // on veut à tout pris eviter de rediriger si le paiement est en cours
    toast.success("Welcome to RentHunter!");
    Mixpanel.track("checkout_page_redirect_to_home");
    navigate("/");
  }

  return (
    <>
      <PaymentIntentResponse paymentIntentId={paymentIntentId} customerId={urlCustomerId} redirectStatus={redirectStatus} paymentMethod={paymentMethod} />
      <div className="h-full p-4 md:bg-white md:m-7 md:p-8 md:shadow">
        <div className="max-w-xl">
          <div className="mb-4">
            <Elements stripe={stripePromise} options={options}>
              <div className="flex flex-col">
                <div key="1">
                  <div className="flex items-center mb-2">
                    <div
                      className={`${0 === activeStep ? "bg-primary text-white" : "bg-none"} flex items-center justify-center w-7 h-7 text-sm rounded-full border border-gray-300`}>
                      1
                    </div>
                    <h3 className="font-medium ml-2">Personal information</h3>
                  </div>
                  <div className={`ml-9 mt-2`}>
                    <Fields isActive={activeStep === 0} setActiveStep={setActiveStep} />
                  </div>
                </div>
                <div key="2">
                  <div className="flex items-center mb-2">
                    <div
                      className={`${1 === activeStep ? "bg-primary text-white" : "bg-none"} flex items-center justify-center w-7 h-7 text-sm rounded-full border border-gray-300`}>
                      2
                    </div>
                    <h3 className="font-medium ml-2">Payment method</h3>
                  </div>
                  <div className={`ml-9 mt-2`}>
                    <PaymentForm selectedPlan={selectedPlan} isActiveStep={activeStep === 1} />
                  </div>
                </div>
              </div>
            </Elements>
          </div>
          <div className="flex items-center gap-2 text-gray-500 text-sm">
            <MdLock size="20px" />
            Guaranteed safe and secure checkout
          </div>
        </div>
      </div>
    </>
  );
};

// TODO: send discount & coupon code to the backend + update displayed price
const appearance = {
  theme: "flat",
  // uncomment to activate "minimal" theme
  // variables: {
  //   fontFamily: ' "Gill Sans", sans-serif',
  //   fontLineHeight: "1.5",
  //   borderRadius: "10px",
  //   colorBackground: "#F6F8FA",
  //   accessibleColorOnColorPrimary: "#262626",
  // },
  // rules: {
  //   ".Block": {
  //     backgroundColor: "var(--colorBackground)",
  //     boxShadow: "none",
  //     padding: "12px",
  //   },
  //   ".Input": {
  //     padding: "12px",
  //   },
  //   ".Input:disabled, .Input--invalid:disabled": {
  //     color: "lightgray",
  //   },
  //   ".Tab": {
  //     padding: "10px 12px 8px 12px",
  //     border: "none",
  //   },
  //   ".Tab:hover": {
  //     border: "none",
  //     boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)",
  //   },
  //   ".Tab--selected, .Tab--selected:focus, .Tab--selected:hover": {
  //     border: "none",
  //     backgroundColor: "#fff",
  //     boxShadow: "0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)",
  //   },
  //   ".Label": {
  //     fontWeight: "500",
  //   },
  // },
};

export default Checkout;

import React, { useState } from "react";

import { BarElement, CategoryScale, Chart as ChartJS, LinearScale } from "chart.js";
import { Bar } from "react-chartjs-2";
import { Range } from "react-range";

ChartJS.register(LinearScale, BarElement, CategoryScale);

const PRICE_RANGE_MIN = 0;
const PRICE_RANGE_MAX = 5000;

const PriceRange = ({ priceRangeSegments, setPrices, priceMin = PRICE_RANGE_MIN, priceMax = PRICE_RANGE_MAX }) => {
  const [inputPriceRange, setInputPriceRange] = useState([priceMin, priceMax]); // This value is to accomodate any user input. This will be reconciled with the original onBlur
  const [sliderPriceRange, setSliderPriceRange] = useState([priceMin, priceMax]);

  const sort = (range) => [...range].sort((a, b) => a - b);
  const sortedPriceRange = sort(sliderPriceRange);

  const getMaxValue = (value) => {
    value = parseInt(value);

    if (!Number.isInteger(value)) return PRICE_RANGE_MAX;
    return Math.min(Math.max(value, sortedPriceRange[0]), PRICE_RANGE_MAX);
  };

  const getMinValue = (value) => {
    value = parseInt(value);
    if (!Number.isInteger(value)) return PRICE_RANGE_MIN;
    return Math.max(Math.min(value, sortedPriceRange[1]), PRICE_RANGE_MIN);
  };

  const setMin = () => {
    const value = inputPriceRange[0];
    const minPrice = getMinValue(value);
    const maxPrice = getMaxValue(Math.max(value, sortedPriceRange[1]));
    const sortedRange = sort([minPrice, maxPrice]);
    setInputPriceRange(sortedRange);
    setSliderPriceRange(sortedRange);
    setPrices({
      rental_min: sortedRange[0],
      rental_max: sortedRange[1],
    });
  };

  const setMax = () => {
    const value = inputPriceRange[1];
    const minPrice = getMinValue(Math.min(value, sortedPriceRange[0]));
    const maxValue = getMaxValue(value);
    const sortedRange = sort([minPrice, maxValue]);
    setInputPriceRange(sortedRange);
    setSliderPriceRange(sortedRange);
    setPrices({
      rental_min: sortedRange[0],
      rental_max: sortedRange[1],
    });
  };

  const removeNumberArrowClass = "[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none ";
  return (
    <>
      <div>
        <div className="h-16 w-full">
          <BarChart priceRangeSegments={priceRangeSegments} priceMin={sliderPriceRange[0]} priceMax={sliderPriceRange[1]} />
        </div>
        <div>
          <RangeSlider
            MIN={PRICE_RANGE_MIN}
            MAX={PRICE_RANGE_MAX}
            min={sliderPriceRange[0]}
            max={sliderPriceRange[1]}
            onChange={(priceRange) => {
              setSliderPriceRange(priceRange);
              const sortedRange = sort(priceRange);
              setInputPriceRange(sortedRange);
            }}
            onFinalChange={(priceRange) => {
              const sortedRange = sort(priceRange);
              setPrices({
                rental_min: sortedRange[0],
                rental_max: sortedRange[1],
              });
            }}
          />
        </div>
      </div>
      <div className="flex gap-5 pt-8">
        <div className="flex flex-col w-24 justify-start items-start gap-0.5 overflow-hidden">
          <div className="w-full text-xs text-gray-700 font-light text-center">Minimum</div>
          <input
            className={removeNumberArrowClass + "p-0 inline-flex min-w-0 text-base relative border px-4 py-1 rounded-2xl text-center"}
            type="number"
            pattern="\[0-9]*"
            value={inputPriceRange[0]}
            onBlur={setMin}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                setMin();
                e.target.blur();
              }
            }}
            onChange={(e) => {
              const value = e.target.value;
              setInputPriceRange((range) => [value, range[1]]);
            }}
          />
        </div>
        <div className="flex-1 h-0 self-center"></div>
        <div className="flex flex-col w-24 justify-start items-start gap-0.5 overflow-hidden">
          <div className="w-full text-xs text-gray-700 font-light text-center">Maximum</div>
          <input
            className={removeNumberArrowClass + "p-0 inline-flex min-w-0 text-base relative border px-4 py-1 rounded-2xl text-center"}
            type="number"
            pattern="\[0-9]*"
            value={inputPriceRange[1]}
            onBlur={setMax}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                setMax();
                e.target.blur();
              }
            }}
            onChange={(e) => {
              const value = e.target.value;
              setInputPriceRange((range) => [range[0], value]);
            }}
          />
        </div>
      </div>
    </>
  );
};

/** PriceRangeSegment is an array of 50 numbers representing the count of price group. Price group looks like [(0-99), (100-199) ... (4900-5000)]*/
const BarChart = ({ priceMin, priceMax, priceRangeSegments }) => {
  return (
    <Bar
      data={{
        labels: priceRangeSegments.map((_, i) => i),
        datasets: [
          {
            label: "Advert Prices",
            data: priceRangeSegments.map((segment) => segment.count),
            backgroundColor(context) {
              const index = context.index;
              const startPrice = priceRangeSegments[index].startPrice;
              const endPrice = priceRangeSegments[index].endPrice;

              if (startPrice >= priceMin && endPrice <= priceMax) return "rgb(222, 89, 89, 0.6)";
              return "rgb(229, 231, 235)";
            },
            borderColor(context) {
              const index = context.index;
              const startPrice = priceRangeSegments[index].startPrice;
              const endPrice = priceRangeSegments[index].endPrice;

              if (startPrice >= priceMin && endPrice <= priceMax) return "rgb(222, 89, 89, 1)";
              return "rgb(229, 231, 235)";
            },
            borderWidth: 1,
            borderRadius: 3,
            categoryPercentage: 1,
          },
        ],
      }}
      options={{
        events: null,
        responsive: true,
        maintainAspectRatio: false,
        animation: false,
        hover: { animationDuration: 0 },
        scales: {
          x: {
            display: false,
            beginAtZero: true,
          },
          y: { display: false },
        },
      }}
    />
  );
};

const RangeSlider = ({ min, max, onChange, onFinalChange }) => {
  const getPercent = (value) => Number((((value - PRICE_RANGE_MIN) / (PRICE_RANGE_MAX - PRICE_RANGE_MIN)) * 100).toFixed(2));

  return (
    <div className="relative">
      <Range
        values={[min, max]}
        step={50}
        min={PRICE_RANGE_MIN}
        max={PRICE_RANGE_MAX}
        onChange={onChange}
        onFinalChange={onFinalChange}
        allowOverlap
        renderTrack={({ props, children }) => (
          <div onMouseDown={props.onMouseDown} onTouchStart={props.onTouchStart} className="flex w-full h-4 cursor-pointer absolute bottom-[-8px]">
            <div ref={props.ref} className="w-full relative rounded h-0.5 bg-gray-300 self-center">
              <div
                className="absolute inset-y-0 bg-secondary"
                style={{
                  left: `${Math.min(getPercent(min), getPercent(max))}%`,
                  width: `${Math.abs(getPercent(max) - getPercent(min))}%`,
                }}></div>
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged, index }) => (
          <div
            {...props}
            style={{ ...props.style, cursor: "pointer" }}
            key={index}
            className={`flex justify-center items-center rounded-full h-8 w-8 border border-gray-300 shadow-lg hover:bg-gray-50 ${isDragged ? "bg-gray-50" : "bg-white"}`}></div>
        )}
      />
    </div>
  );
};

export default PriceRange;

import React, { useEffect, useRef, useState } from "react";

import { BarElement, CategoryScale, Chart as ChartJS, LinearScale } from "chart.js";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";

import { BiBuilding, BiHomeAlt } from "react-icons/bi";
import { CgStudio } from "react-icons/cg";
import { LuBellRing, LuSettings2 } from "react-icons/lu";
import { RiArrowDownSLine, RiArrowUpSLine, RiHotelBedLine } from "react-icons/ri";

import PriceRange from "@/components/PriceRange";
import useDevice from "@/hooks/useDevice";
import { Statsig } from "@/services/statsig";
import { STATSIG_EVENTS } from "@/utils/statsigEvents";
import { CheckBoxes } from "../../../components/CheckBoxes";
import Modal from "../../../components/Modal";
import { Select } from "../../../components/Select";
import { setUser } from "../../../redux/auth/actions";
import api from "../../../services/api";
import { Mixpanel } from "../../../services/mixpanel";
import { capitalizeFirstLetter } from "../../../utils";
import { PAID_SOURCES, SOURCES_TO_READABLE } from "../../../utils/constants";
import { rentalDurationOptions } from "../../savedSearches/options";
import { Combobox } from "./ComboboxSearchCity";
import FilterContext from "./filterContext/filterContext";
import useFilters from "./filterContext/useFilters";
import SaveSearchNoAuth from "./SaveSearchNoAuth";
import { UpdateSavedSearchDetailsModal } from "./updateSavedSearchDetailsModal";

// Register required components
ChartJS.register(LinearScale, BarElement, CategoryScale);

const PRICE_RANGE_MIN = 0;
const PRICE_RANGE_MAX = 5000;

const Filters = ({ filters, setFilters }) => {
  const [isMoreFiltersOpen, setIsMoreFiltersOpen] = useState(false);
  const [savedSearch, setSavedSearch] = useState(null);
  const [searchMunicipalities, setSearchMunicipalities] = useState([]); // results of the municipality input search
  const [defaultMunicipalities, setDefaultMunicipalities] = useState([]); // municipalities to display when the input is empty
  const [priceRangeSegments, setPriceRangeSegments] = useState([]); // price range segments to display the bar chart
  const [isTotalMatchesLoading, setIsTotalMatchesLoading] = useState(false); // to display the loading spinner when fetching the price range segments
  const [totalMatches, setTotalMatches] = useState(null);
  // for account creation on the fly when saving a search
  const [isEmailPopupOpen, setIsEmailPopupOpen] = useState(false);

  const dispatch = useDispatch();
  const device = useDevice();
  const { user } = useSelector((state) => state.Auth);

  const areWeUpdating = filters._id ? true : false;

  useEffect(() => {
    fetchDefaultMunicipalities("");
  }, []);

  const saveOrUpdateSearch = async () => {
    try {
      if (!filters.municipality && !filters.search_area_geometry) return toast.error("Please select a municipality to save the search");
      if (!user) return toast.error("Please login to save the search");

      if (areWeUpdating) {
        // update
        const res = await api.put("/user/saved-search/" + filters._id, { ...filters, is_activated: true });
        if (!res.ok) throw new Error("Error");
        toast.success("Search updated");

        // to update estimated matches count home page
        dispatch(setUser(res.data));

        setSavedSearch(res.updated_saved_search);
      } else {
        // save
        const res = await api.post("/user/saved-search", {
          ...filters,
          is_activated: true,
          notification_types: user?.is_whatsapp_verified ? ["WHATSAPP"] : ["EMAIL"],
          notification_frequency: "INSTANT",
          name: filters.municipality
            ? `${capitalizeFirstLetter(filters.municipality)} (${new Date().toLocaleString("en-US", {
                month: "long",
                day: "numeric",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })})`
            : `Custom search (${new Date().toLocaleString("en-US", { month: "long", day: "numeric", year: "numeric", hour: "2-digit", minute: "2-digit" })})`,
        });
        if (!res.ok) throw new Error("Error");

        // to update alerts count
        dispatch(setUser(res.data));

        onSaveSearchSuccess(res.data.saved_searches[res.data.saved_searches.length - 1]);
      }
    } catch (e) {
      console.error("e", e);
      toast.error(e?.code || "Error");
    }
  };

  const onSaveSearchSuccess = async (search) => {
    if (!search) return toast.error("Error saving search");
    // to add to search id to the url to be able to update the search
    setSavedSearch(search);
    setFilters({ ...filters, _id: search._id });
  };

  const fetchDefaultMunicipalities = async (value) => {
    try {
      const res = await api.post("/municipality/search", { search: value });
      setDefaultMunicipalities(res.data);
    } catch (e) {
      console.log("e", e);
      toast.error(e?.code || "Error");
    }
  };

  const computeAdditionnalFilters = (search) => {
    let count = 0;
    if (search.types && search.types.length > 0) count++;
    if (search.furnished_types && search.furnished_types.length > 0) count++;
    if (search.surface_min) count++;
    if (search.rental_length_in_weeks_min) count++;
    if (search.min_rooms) count++;
    if (search.min_bedrooms) count++;
    if (search.included_paid_sources && search.included_paid_sources.length > 0) count++;
    if (search.balcony_has) count++;
    if (search.parking_has) count++;
    if (search.garden_has) count++;
    if (search.garage_has) count++;
    if (search.pets_allowed) count++;
    if (search.registration) count++;
    if (device == "mobile") {
      if (search.rental_min) count++;
      if (search.rental_max) count++;
    }
    return count;
  };

  const retrieveSelectedMunicipality = () => {
    if (filters.municipality) return { name: filters.municipality }; // case when the municipality in the url is not in the list of municipalities
    return searchMunicipalities.find((option) => option.name == filters.municipality);
  };

  return (
    <>
      <UpdateSavedSearchDetailsModal savedSearch={savedSearch} onClose={() => setSavedSearch(null)} />
      <SaveSearchNoAuth
        search={{
          ...filters,
          is_activated: true,
          // default to email as we don't have a phone number yet
          notification_types: ["EMAIL"],
          notification_frequency: "INSTANT",
          name: filters.municipality
            ? `${capitalizeFirstLetter(filters.municipality)} (${new Date().toLocaleString("en-US", {
                month: "long",
                day: "numeric",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })})`
            : `Custom search (${new Date().toLocaleString("en-US", { month: "long", day: "numeric", year: "numeric", hour: "2-digit", minute: "2-digit" })})`,
        }}
        isOpen={isEmailPopupOpen}
        setIsOpen={setIsEmailPopupOpen}
        onSaveSearchSuccess={onSaveSearchSuccess}
      />
      <FilterContext.Provider
        value={{
          filters,
          setFilters,
          totalMatches,
          setTotalMatches,
          priceRangeSegments,
          setPriceRangeSegments,
          isTotalMatchesLoading,
          setIsTotalMatchesLoading,
        }}>
        <MoreFiltersModal isOpen={isMoreFiltersOpen} onClose={() => setIsMoreFiltersOpen(false)} />

        <div className="sticky drop-shadow-lg inset-y-0 py-3 px-5 md:px-8 md:py-4 bg-white z-40 flex flex-col md:flex-row lg:flex-col xl:flex-row justify-start items-end">
          <div className="w-full flex justify-start">
            <div className="md:mr-3">
              <Combobox
                onInputChange={async (value) => {
                  try {
                    if (!value) {
                      // to display the default municipalities when the input is empty (without the related cities)
                      setSearchMunicipalities([]);
                      return;
                    }
                    const res = await api.post("/municipality/search", { search: value, include_related_cities: true });
                    setSearchMunicipalities(res.data);
                  } catch (e) {
                    console.log("e", e);
                    toast.error(e?.code || "Error");
                  }
                }}
                value={retrieveSelectedMunicipality()}
                options={searchMunicipalities?.length > 0 ? searchMunicipalities : defaultMunicipalities}
                onChange={(option) => {
                  if (!option) {
                    setFilters({
                      ...filters,
                      municipality: null,
                    });
                  } else {
                    setFilters({
                      ...filters,
                      municipality: option.municipality,
                    });
                  }
                }}
                getLabel={(e) => (e ? e.name.charAt(0).toUpperCase() + e.name.slice(1) : null)}
                placeholder={"Select municipality"}
                width="w-full"
              />
            </div>

            <div className="hidden md:block w-full max-w-40">
              <PriceSelector />
            </div>

            <button
              className="px-3 relative whitespace-nowrap border-0 self-center"
              onClick={() => {
                Mixpanel.track("match_click_more_filters");
                setIsMoreFiltersOpen(true);
                Statsig.logEvent(STATSIG_EVENTS.click_more_filters);
              }}>
              {computeAdditionnalFilters(filters) > 0 && (
                <div className="absolute flex justify-center items-center top-[-6px] right-[6px] text-white bg-primary w-5 h-5 text-[10px] border-2 border-white rounded-full leading-none">
                  {computeAdditionnalFilters(filters)}
                </div>
              )}
              <span className="flex p-2 border border-gray-300 rounded-full bg-[#F0F2F8]">
                <LuSettings2 size={20} />
              </span>
            </button>

            <button
              className="!rounded-2xl btn-primary"
              onClick={(e) => {
                e.preventDefault();
                if (!filters.municipality && !filters.search_area_geometry) return toast.error("Please select a municipality or an area to save the search");
                // if not logged in, we need to create an account
                if (!user) return setIsEmailPopupOpen(true);
                saveOrUpdateSearch();
                Statsig.logEvent(areWeUpdating ? STATSIG_EVENTS.update_saved_search : STATSIG_EVENTS.create_saved_search);
              }}>
              <div className="flex justify-center items-center text-sm">
                <LuBellRing size={18} className="mr-2" />
                {areWeUpdating ? "Update" : "Save"}
              </div>
            </button>
          </div>
        </div>
      </FilterContext.Provider>
    </>
  );
};

const MoreFiltersModal = ({ isOpen, onClose }) => {
  const { filters: search, setFilters: setSearch, totalMatches, isTotalMatchesLoading, fetchTotalMatches, fetchPriceRangeSegments, priceRangeSegments } = useFilters();

  const [values, setValues] = useState(search);

  useEffect(() => {
    if (!isOpen) return;
    fetchPriceRangeSegments(values);
    fetchTotalMatches({
      ...values,
      rental_max: values.rental_max,
      rental_min: values.rental_min,
    });
  }, [values]);

  const handleApply = async (e) => {
    try {
      e.preventDefault();
      console.log("values: ", values);
      setSearch(values);
      onClose();
    } catch (e) {
      console.log("e", e);
      toast.error(e?.code || "Error");
    }
  };

  const handleReset = async (e) => {
    try {
      e.preventDefault();
      setValues({});
      setSearch({});
      onClose();
    } catch (e) {
      console.log("e", e);
      toast.error(e?.code || "Error");
    }
  };

  const amnestiesValues = () => {
    const valuesList = [];
    if (values.balcony_has) valuesList.push("balcony_has");
    if (values.parking_has) valuesList.push("parking_has");
    if (values.garden_has) valuesList.push("garden_has");
    if (values.garage_has) valuesList.push("garage_has");
    if (values.pets_allowed) valuesList.push("pets_allowed");
    if (values.registration) valuesList.push("registration");

    return valuesList;
  };

  if (!isOpen) return null;
  return (
    <Modal isOpen={isOpen} onClose={onClose} innerClassName="md:flex md:max-w-[50rem] md:w-[60vw] md:h-[85vh] md:max-h-[calc(100vh-5rem)]">
      <div className="w-full h-full flex flex-col overflow-y-hidden transform bg-white text-left align-middle shadow-xl transition-all">
        <div className="flex justify-between items-center px-8 py-5 border-b">
          <div className="text-lg font-semibold flex gap-2 items-center">
            <LuSettings2 /> More filters
          </div>
          <button
            className="text-2xl"
            onClick={() => {
              onClose();
            }}>
            X
          </button>
        </div>
        <div className="py-4 px-8 overflow-auto">
          <div className="md:hidden text-center flex flex-col gap-3 border-b border-gray- last:border-0 pb-8">
            <div className="font-bold text-xl mb-4">Price range</div>
            <PriceRange
              priceRangeSegments={priceRangeSegments}
              setPrices={({ rental_min, rental_max }) => {
                setValues({ ...values, rental_min, rental_max });
              }}
              priceMin={values.rental_min || undefined}
              priceMax={values.rental_max || undefined}
            />
          </div>
          <div className="text-center flex flex-col gap-3 border-b border-gray- last:border-0 py-8 md:pt-0">
            <div className="font-bold text-xl mb-4">Housing type</div>
            <CheckBoxes
              options={["ROOM", "STUDIO", "APARTMENT", "HOUSE"]}
              value={values.types || []}
              onAdd={(value) => {
                setValues((values) => ({ ...values, types: values.types && values.types.length > 0 ? [...values.types, value] : [value] }));
              }}
              onRemove={(value) => {
                setValues((values) => ({ ...values, types: values.types.filter((t) => t !== value) }));
              }}
              getLabel={(o) => capitalizeFirstLetter(o)}
              getIcon={(o) => {
                if (o == "ROOM") return <RiHotelBedLine size={25} />;
                if (o == "APARTMENT") return <BiBuilding size={25} />;
                if (o == "HOUSE") return <BiHomeAlt size={25} />;
                if (o == "STUDIO") return <CgStudio size={25} />;
              }}
              multiple={true}
              className={"md:grid-cols-4"}
            />
          </div>

          <div className="text-center flex flex-col gap-3 border-b border-gray- last:border-0 py-8">
            <div className="font-bold text-xl mb-4">Furnishing</div>
            <CheckBoxes
              options={["FURNISHED", "UNFURNISHED"]}
              value={values.furnished_types || []}
              multiple={true}
              onAdd={(value) => {
                setValues((values) => ({ ...values, furnished_types: values.furnished_types && values.furnished_types.length > 0 ? [...values.furnished_types, value] : [value] }));
              }}
              onRemove={(value) => {
                setValues((values) => ({ ...values, furnished_types: values.furnished_types.filter((t) => t !== value) }));
              }}
              getLabel={(o) => capitalizeFirstLetter(o)}
              className={"md:grid-cols-2 lg:grid-cols-2"}
            />
          </div>

          <div className="text-center flex flex-col gap-3 border-b border-gray- last:border-0 py-8">
            <div className="font-bold text-xl mb-4">Min surface (m²)</div>
            <Select
              options={[6, 8, 10, 12, 14, 16, 18, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100]}
              value={values.surface_min}
              onChange={(e) => setValues({ ...values, surface_min: e })}
              width="w-full"
              nullable={true}
              getLabel={(o) => o + "m²"}
              placeholder="No minimum"
            />
          </div>

          <div className="text-center flex flex-col gap-3 border-b border-gray- last:border-0 py-8">
            <div className="font-bold text-xl mb-4">Min rental period</div>
            <Select
              options={rentalDurationOptions}
              value={rentalDurationOptions.find((o) => o.value == values.rental_length_in_weeks_min)}
              onChange={(e) => setValues({ ...values, rental_length_in_weeks_min: e.value })}
              getLabel={(o) => o.label}
              width="w-full"
              nullable={true}
              placeholder="No minimum"
            />
          </div>

          <div className="text-center flex flex-col gap-3 border-b border-gray- last:border-0 py-8">
            <div className="font-bold text-xl mb-4">Rooms</div>
            <CheckBoxes
              options={[1, 2, 3, 4]}
              value={values.min_rooms}
              onAdd={(value) => {
                setValues((values) => ({ ...values, min_rooms: value }));
              }}
              onRemove={(value) => {
                setValues((values) => ({ ...values, min_rooms: undefined }));
              }}
              getLabel={(o) => o + "+ rooms"}
              className={"md:grid-cols-2 lg:grid-cols-4"}
            />
          </div>

          <div className="text-center flex flex-col gap-3 border-b border-gray- last:border-0 py-8">
            <div className="font-bold text-xl mb-4">Bedrooms</div>
            <CheckBoxes
              options={[1, 2, 3, 4]}
              value={values.min_bedrooms}
              onAdd={(value) => {
                setValues((values) => ({ ...values, min_bedrooms: value }));
              }}
              onRemove={(value) => {
                setValues((values) => ({ ...values, min_bedrooms: undefined }));
              }}
              getLabel={(o) => o + "+ bedrooms"}
              className={"md:grid-cols-2 lg:grid-cols-4"}
            />
          </div>

          <div className="text-center flex flex-col gap-3 border-b border-gray- last:border-0 py-8">
            <div className="font-bold text-xl mb-4">Amnesties</div>
            <CheckBoxes
              options={["balcony_has", "parking_has", "garden_has", "garage_has", "pets_allowed", "registration"]}
              value={amnestiesValues()}
              multiple={true}
              onAdd={(value) => setValues((values) => ({ ...values, [value]: true }))}
              onRemove={(value) => {
                const newValues = { ...values };
                delete newValues[value];
                setValues(newValues);
              }}
              getLabel={(o) => {
                const labels = {
                  balcony_has: "Balcony",
                  parking_has: "Parking",
                  garden_has: "Garden",
                  garage_has: "Garage",
                  pets_allowed: "Pets Allowed",
                  registration: "Has Registration",
                };

                return labels[o] || o;
              }}
              className={"md:grid-cols-2 lg:grid-cols-2 capitalize"}
            />
          </div>

          <div className="text-center flex flex-col gap-3 border-b border-gray- last:border-0 py-8">
            <div className="font-bold text-xl mb-4">Include adverts from paid sites</div>
            <CheckBoxes
              options={PAID_SOURCES}
              value={values.included_paid_sources || []}
              multiple={true}
              onAdd={(value) => {
                setValues((values) => ({
                  ...values,
                  included_paid_sources: values.included_paid_sources && values.included_paid_sources.length > 0 ? [...values.included_paid_sources, value] : [value],
                }));
              }}
              onRemove={(value) => {
                setValues((values) => ({ ...values, included_paid_sources: values.included_paid_sources.filter((t) => t !== value) }));
              }}
              getLabel={(o) => o && SOURCES_TO_READABLE[o]}
              className={"md:grid-cols-2 lg:grid-cols-2"}
            />
          </div>
        </div>
        <div className="pb-3 bottom-0 bg-white flex justify-between w-full border-t pt-3 px-8 md:pb-3">
          <button
            type="submit"
            className="btn-secondary"
            onClick={(e) => {
              handleReset(e);
            }}>
            Reset all filters
          </button>
          <div>
            <button
              type="submit"
              className="btn-primary w-32"
              onClick={(e) => {
                handleApply(e);
              }}>
              {isTotalMatchesLoading ? <PriceLoader /> : totalMatches === null ? "Apply" : `${totalMatches > 5000 ? "5000+" : totalMatches} results`}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const PriceSelector = () => {
  const priceFilterRef = useRef();
  const { filters, setFilters, totalMatches, isTotalMatchesLoading, fetchTotalMatches, fetchPriceRangeSegments, priceRangeSegments } = useFilters();

  const [priceSelectorValues, setPriceSelectorValues] = useState({
    rental_min: filters.rental_min,
    rental_max: filters.rental_max,
  });
  const [isPriceFilterOpen, setIsPriceFilterOpen] = useState(false);

  useEffect(() => {
    fetchPriceRangeSegments(filters);
  }, [filters]);

  useEffect(() => {
    fetchTotalMatches({
      ...filters,
      rental_min: priceSelectorValues.rental_min,
      rental_max: priceSelectorValues.rental_max,
    });
  }, [filters, priceSelectorValues]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (priceFilterRef.current && !priceFilterRef.current.contains(event.target)) {
        setPriceSelectorValues({
          rental_min: filters.rental_min || PRICE_RANGE_MIN,
          rental_max: filters.rental_max || PRICE_RANGE_MAX,
        });
        setIsPriceFilterOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [priceFilterRef]);

  return (
    <div className="relative flex justify-center items-end gap-2">
      <div className="w-full relative flex items-center bg-white justify-between border border-gray-300 rounded overflow-hidden hover:border-primary">
        <button
          onMouseDown={(e) => {
            e.stopPropagation();
            setIsPriceFilterOpen((open) => !open);
          }}
          className="flex-1 text-left bg-[#F0F2F8] text-sm py-2 px-3"
          type="button">
          <p className={`truncate text-left text-sm ${filters.rental_min || filters.rental_max ? "text-black" : "text-black font-light"}`}>
            {filters.rental_min || filters.rental_max ? `${filters.rental_min}€ - ${filters.rental_max}€` : "Price"}
          </p>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2">{isPriceFilterOpen ? <RiArrowUpSLine /> : <RiArrowDownSLine />}</div>
        </button>
      </div>
      {isPriceFilterOpen && (
        <div className="absolute h-0 overflow-visible">
          <div ref={priceFilterRef} className="w-96 z-10 relative mt-2 bg-white border border-gray-300 rounded-lg shadow-sm overflow-hidden">
            <div className="p-6">
              <div className="text-xs text-gray-700 font-light mb-2">Price range (€/month)</div>
              <PriceRange
                priceRangeSegments={priceRangeSegments}
                setPrices={setPriceSelectorValues}
                priceMin={priceSelectorValues.rental_min || undefined}
                priceMax={priceSelectorValues.rental_max || undefined}
              />
            </div>
            <div className="pb-3 bottom-0 bg-white flex justify-between w-full border-t pt-3 px-6 md:pb-3">
              <button
                type="submit"
                className="self-center underline"
                onClick={() => {
                  setPriceSelectorValues({ rental_min: null, rental_max: null });
                  setFilters({ ...filters, rental_min: null, rental_max: null });
                  setIsPriceFilterOpen(false);
                }}>
                Clear
              </button>
              <div>
                <button
                  type="submit"
                  className="btn-primary w-44 text-center"
                  onClick={() => {
                    setFilters({ ...filters, rental_min: priceSelectorValues.rental_min, rental_max: priceSelectorValues.rental_max });
                    setIsPriceFilterOpen(false);
                  }}>
                  {isTotalMatchesLoading ? <PriceLoader /> : totalMatches === null ? "Apply" : `Show ${totalMatches > 5000 ? "5000+" : totalMatches} results`}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const PriceLoader = () => (
  <span className="flex items-center justify-center gap-1.5">
    <div className={`flex items-center justify-center`}>
      <div className="border-[2px] border-gray-400 border-l-blue-600 animate-spin text-primary inline-block h-4 w-4 rounded-full" role="status">
        <span className="hidden">Loading...</span>
      </div>
    </div>
    Loading
  </span>
);

export default Filters;

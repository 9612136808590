import React, { useState } from "react";

import { toast } from "react-hot-toast";
import { MdFavorite, MdFavoriteBorder } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import ImageSlider from "@/components/ImageSlider";
import useDevice from "@/hooks/useDevice";
import { Statsig } from "@/services/statsig";
import { STATSIG_EVENTS } from "@/utils/statsigEvents";
import { setUser } from "../../redux/auth/actions";
import api from "../../services/api";
import { Mixpanel } from "../../services/mixpanel";
import { capitalizeFirstLetter, getDateAvailability, isLessThan48HoursAgo, timeAgo } from "../../utils";
import SaveFavoriteNoAuth from "./SaveFavoriteNoAuth";

const AdvertCard = ({ advert, index, setAdvertQueryId = () => {} }) => {
  const experiment = Statsig.getExperiment("advert_card_old_time_ago");
  // console.log("experiment NEW", experiment);
  const dispatch = useDispatch();
  const device = useDevice();
  const { user } = useSelector((state) => state.Auth);

  const [isSaveFavNoAuthOpen, setIsSaveFavNoAuthOpen] = useState(false);

  const addFavorite = async (advert) => {
    if (!user) return setIsSaveFavNoAuthOpen(true);
    try {
      const res = await api.post(`/user/favorite-advert/${advert._id}`);
      if (!res.ok) throw new Error("Error");
      dispatch(setUser(res.data));
    } catch (e) {
      console.log("e", e);
      toast.error(e?.code || "Error");
    }
  };

  const removeFavorite = async (advert) => {
    try {
      if (!window.confirm("Are you sure you want to remove this listing from your favorites?")) return;
      const res = await api.delete(`/user/favorite-advert/${advert._id}`);
      if (!res.ok) throw new Error("Error");
      dispatch(setUser(res.data));
    } catch (e) {
      console.log("e", e);
      toast.error(e?.code || "Error");
    }
  };

  const formatAdvertInfo = (advert) => {
    const parts = [];

    if (advert.surface) {
      parts.push(advert.surface + "m²");
    }

    // Add furnishing information, capitalized
    // if (advert.furnishing) {
    //   parts.push(capitalizeFirstLetter(advert.converted_furnishing));
    // }

    // Add room information based on the number of rooms
    // if (advert.rooms) {
    //   parts.push(advert.rooms + (advert.rooms === 1 ? " room" : " rooms"));
    // }

    // Add bedroom information based on the number of bedrooms
    if (advert.bedrooms) {
      parts.push(advert.bedrooms + (advert.bedrooms === 1 ? " bed" : " beds"));
    }

    return parts;
  };

  const advertImages = advert.images.length > 0 ? advert.images : ["https://rentsaver.s3.nl-ams.scw.cloud/app/propertyPlaceHolder.png"];
  const Node = device === "mobile" ? "div" : Link;

  return (
    <>
      <SaveFavoriteNoAuth advertId={advert._id} isOpen={isSaveFavNoAuthOpen} setIsOpen={setIsSaveFavNoAuthOpen} />
      <div
        key={index}
        onMouseEnter={() => {
          /**
         Advert hover effect explanations: 
         * marker z-index always between 1 and 2
         * tooltip background color changes on hover
         * 
         * Css hover defined always in map.css and updateMarkerSVG function
         */
          const advertId = advert._id;

          const svgMarker = document.querySelector(`.svg-marker-${advertId}`);
          if (!svgMarker) return;
          svgMarker.style.fill = "#011C1A";
          svgMarker.style.color = "#FFFFFF";
          svgMarker.style.scale = 1.2;

          const parentSvgMarker = svgMarker.parentElement;
          parentSvgMarker.style.zIndex = 2;
        }}
        onMouseLeave={() => {
          const advertId = advert._id;

          const svgMarker = document.querySelector(`.svg-marker-${advertId}`);
          if (!svgMarker) return;
          svgMarker.style.fill = "#FFFFFF";
          svgMarker.style.color = "#000000";
          svgMarker.style.scale = 1;

          const parentSvgMarker = svgMarker.parentElement;
          parentSvgMarker.style.zIndex = 1;
        }}
        className="flex flex-col items-center justify-start relative">
        <div className="w-full rounded-xl overflow-hidden">
          <ImageSlider>
            {advertImages.map((imageUrl, n) => (
              <Node
                key={n}
                onClick={() => {
                  Statsig.logEvent(STATSIG_EVENTS.advert_card_click, "listing_image_slider", {
                    advert_id: advert._id,
                    device: device,
                  });

                  if (device === "mobile") {
                    setAdvertQueryId(advert._id);
                  }
                }}
                target={device === "mobile" ? "_self" : "_blank"}
                to={`/rent/${advert._id}`}
                className="w-full h-60 bg-center bg-cover cursor-pointer"
                style={{ backgroundImage: `url(${imageUrl})` }}></Node>
            ))}
          </ImageSlider>
        </div>
        {experiment?.groupName == "Test" ? (
          <>
            <div className="absolute top-4 left-4">
              <div className="px-[0.85rem] py-[0.12rem] bg-secondary text-white w-fit rounded-xl text-sm">{timeAgo(advert.created_at)} ago</div>
            </div>
          </>
        ) : (
          <>
            {isLessThan48HoursAgo(advert.created_at) && (
              <div className="absolute top-4 left-4">
                <div className="px-[0.85rem] py-[0.12rem] bg-secondary text-white w-fit rounded-xl text-sm">{timeAgo(advert.created_at)} ago</div>
              </div>
            )}
          </>
        )}

        {/* 0.6rem because of react-icons inner padding */}
        <div className="absolute top-4 right-4 cursor-pointer bg-[#F5FAFE] rounded-lg p-[0.3rem]">
          {user?.favorite_adverts.find((fav) => fav.advert_id == advert._id) ? (
            <MdFavorite
              size={20}
              color="#FF6A00"
              className="transition-transform duration-300 hover:scale-125"
              onClick={() => {
                Statsig.logEvent(STATSIG_EVENTS.remove_favorite, "advert_card", {
                  advert_id: advert._id,
                  device: device,
                });
                Mixpanel.track("match_remove_favorite", advert);
                removeFavorite(advert);
              }}
            />
          ) : (
            <MdFavoriteBorder
              size={20}
              color="black"
              className="transition-transform duration-300 hover:scale-125"
              onClick={() => {
                Statsig.logEvent(STATSIG_EVENTS.add_favorite, "advert_card", {
                  advert_id: advert._id,
                  device: device,
                });
                Mixpanel.track("match_add_favorite", advert);
                addFavorite(advert);
              }}
            />
          )}
        </div>
        <div className="flex flex-col w-full grow mt-2 gap-y-[0.10rem]">
          <div className="flex justify-between items-center">
            <Node
              target={device === "mobile" ? "_self" : "_blank"}
              to={`/rent/${advert._id}`}
              className="font-medium text-md w-full"
              onClick={() => {
                Mixpanel.track("match_click", advert);

                Statsig.logEvent(STATSIG_EVENTS.advert_card_click, "listing_title", {
                  advert_id: advert._id,
                  device: device,
                });
                if (device === "mobile") {
                  setAdvertQueryId(advert._id);
                }
              }}>
              <div className="flex items-center justify-between w-full">
                {!advert.is_active ? (
                  <div>
                    <span className="rounded-md bg-red-100 text-red-900 px-3 py-1 mr-2">Rented</span>
                    <span className="line-through">{capitalizeFirstLetter(advert.converted_type)}</span>
                  </div>
                ) : (
                  <span>{capitalizeFirstLetter(advert.converted_type)}</span>
                )}
                <div className="">
                  {advert.price}€ <span className="font-light text-sm">/month</span>
                </div>
              </div>
            </Node>
          </div>
          <div>
            <div className="text-sm text-[#6a6a6a]">
              {formatAdvertInfo(advert).map((info, index, array) => {
                return (
                  <span key={index}>
                    {info}
                    {index + 1 != array.length && <span className="mx-[0.3rem] text-gray-400">•</span>}
                  </span>
                );
              })}
            </div>
          </div>

          <div className="text-sm text-[#6a6a6a]">
            {advert.city && advert.location_details?.street && advert.location_details?.house_number ? (
              // case where we are collecting everything on the listing, no need to use the api answer (which sometimes removes the house number)
              <>
                {advert.location_details?.street} {advert.location_details?.house_number}, {capitalizeFirstLetter(advert.city)}
              </>
            ) : (
              advert.location_details_from_api.address
            )}
          </div>
          <div>
            <div className="text-sm text-[#6a6a6a]">
              {capitalizeFirstLetter(advert.source)}
              <span className="mx-[0.3rem] text-gray-400">•</span>
              {getDateAvailability(advert.move_in_date || new Date())}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdvertCard;

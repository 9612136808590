import React, { useEffect, useState } from "react";

import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Logo from "@/assets/svg/logo_renthunter.svg";
import { setUser } from "@/redux/auth/actions";
import api from "@/services/api";
import { Statsig } from "@/services/statsig";
import { STATSIG_EVENTS } from "@/utils/statsigEvents";

const UNSUBSCRIBE_STAGES = {
  INIT: "INITIAL",
  REASON: "REASON",
  FEEDBACK: "FEEDBACK",
  CONFIRMATION: "CONFIRMATION",
};

const UNSUBSCRIBE_TYPE = [
  { value: "FOUND_RENTAL", description: "I found a rental property" },
  { value: "NOT_SEARCHING", description: "No longer searching for a rental" },
  { value: "UNSATISFIED", description: "Service didn't meet my expectations" },
  { value: "TECHNICAL_ISSUES", description: "Technical issues with the website/app" },
  { value: "TOO_EXPENSIVE", description: "The subscription is too expensive" },
  { value: "BETTER_ALTERNATIVE", description: "Found a better alternative" },
  { value: "OTHERS", description: "Others" },
];

const Unsubscribe = () => {
  const { user } = useSelector((state) => state.Auth);
  const navigate = useNavigate();

  const [stage, setStage] = useState(UNSUBSCRIBE_STAGES.INIT);
  const [type, setType] = useState("");
  const [reason, setReason] = useState("");
  const [rating, setRating] = useState();
  const [feedback, setFeedback] = useState("");

  useEffect(() => {
    // Navigate home if user is not a paid user or subscription is stopped already
    if (!user || user.type === "FREE" || (user.type === "PAID" && user.subscription_ends && new Date(user.subscription_ends) > new Date())) {
      navigate("/", { replace: true });
    }
  }, [user?.type]);

  // Log event when stage changes
  useEffect(() => {
    Statsig.logEvent(STATSIG_EVENTS.unsubscribe_stage_ + stage);
  }, [stage]);

  return (
    <div className="py-4 md:p-4 min-h-screen md:bg-gray-100">
      <div className="flex justify-center items-center pt-3 pb-8">
        <button
          className="cursor-pointer"
          onClick={() => {
            Statsig.logEvent(STATSIG_EVENTS.unsubscribe_click_logo);
            navigate("/");
          }}>
          <img src={Logo} alt="logo" className="h-5" />
        </button>
      </div>

      <div className="w-full md:w-[500px] flex flex-col bg-white md:rounded-md transition-all my-0 mx-auto p-4 md:p-8 md:mb-5">
        {stage === UNSUBSCRIBE_STAGES.INIT ? (
          <UnsubscribeInitiation setStage={setStage} />
        ) : stage === UNSUBSCRIBE_STAGES.REASON ? (
          <UnsubscribeReason setStage={setStage} setType={setType} type={type} reason={reason} setReason={setReason} />
        ) : stage === UNSUBSCRIBE_STAGES.FEEDBACK ? (
          <UnsubscribeFeedback setRating={setRating} rating={rating} setFeedback={setFeedback} feedback={feedback} type={type} reason={reason} setStage={setStage} />
        ) : stage === UNSUBSCRIBE_STAGES.CONFIRMATION ? (
          <UnsubscribeConfirmation rating={rating} />
        ) : null}
      </div>
    </div>
  );
};

const UnsubscribeInitiation = ({ setStage }) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-6">
      <div className="text-2xl font-semibold text-center">We're sorry to see you go!</div>
      <div className="flex-1 w-full flex flex-col">
        <div className="py-1 flex-1 text-primary text-lg pb-2 text-center">Your feedback helps us improve. Please let us know why you're leaving.</div>
      </div>
      <div className="bg-white flex flex-col gap-4 w-full">
        <button
          type="button"
          className="btn-secondary"
          onClick={() => {
            Statsig.logEvent(STATSIG_EVENTS.unsubscribe_click_keep_subscription);
            navigate("/account/subscription");
          }}>
          Keep my subscription active
        </button>
        <button
          type="button"
          onClick={() => {
            setStage(UNSUBSCRIBE_STAGES.REASON);
          }}
          className="btn-primary">
          Proceed to Unsubscribe
        </button>
      </div>
    </div>
  );
};

const UnsubscribeReason = ({ setType, type, reason, setReason, setStage }) => {
  return (
    <div className="flex flex-col gap-6">
      <div className="text-2xl font-semibold">Why are you canceling your RentHunter subscription?</div>
      <div className="flex-1 flex flex-col">
        <div className="flex flex-col gap-4 mb-2">
          <form className="flex flex-col gap-6">
            {UNSUBSCRIBE_TYPE.map(({ description, value }, n) => (
              <label key={n} className="flex flex-wrap items-center gap-2">
                <input
                  className="flex flex-initial min-w-0 w-4 h-4 accent-primary"
                  name="unsubcribe_type"
                  type="radio"
                  value={value}
                  checked={type === value}
                  onChange={() => {
                    setType(value);
                  }}
                />
                <span className="flex-1 leading-none">{description}</span>
              </label>
            ))}
          </form>

          {type === "OTHERS" && (
            <label className="flex flex-col gap-2 my-2">
              <textarea
                required
                placeholder="Give us more details"
                name="report body"
                value={reason}
                className="resize-y min-h-20 max-h-40 p-2 rounded-lg w-full border"
                onChange={(e) => {
                  const value = e.target.value;
                  setReason(value);
                }}></textarea>
            </label>
          )}
        </div>
      </div>
      <div className="bg-white flex flex-col gap-4 w-full">
        <button
          onClick={() => {
            Statsig.logEvent(STATSIG_EVENTS.unsubscribe_click_go_back_reason);
            setStage(UNSUBSCRIBE_STAGES.INIT);
          }}
          className="btn-secondary">
          Go Back
        </button>
        <button
          onClick={() => {
            if (!type) return toast.error("Please select a reason");
            if (type === "OTHERS" && !reason.trim() && reason.length < 10) return toast.error("Please provide more details (min. 10 characters)");
            setStage(UNSUBSCRIBE_STAGES.FEEDBACK);
          }}
          className="btn-primary">
          Continue to Unsubscribe
        </button>
      </div>
    </div>
  );
};

const UnsubscribeFeedback = ({ setRating, rating, feedback, setFeedback, type, reason, setStage }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const SCALE_ARRAY = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const handleUnsubscribe = async () => {
    try {
      setIsLoading(true);
      const res = await api.put("/user/unsubscribe", {
        type,
        reason,
        rating,
        feedback,
      });
      if (!res.ok) return toast.error("Something went wrong. Please try again later or contact support.");

      setStage(UNSUBSCRIBE_STAGES.CONFIRMATION);
      dispatch(setUser(res.data));
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong. Please try again later or contact support.");
    } finally {
      setIsLoading(false);
    }
  };

  const getFeedbackQuestion = () => {
    if (!rating || rating < 1 || rating > 10) return ""; // Handle invalid ratings
    if (rating <= 4) return "We're sorry to hear your experience wasn’t great. What challenges did you face, and how can we make things better for you?";
    if (rating <= 6) return "Thanks for your feedback! Which specific aspects of RentHunter could be improved to better meet your needs?";
    if (rating <= 8) return "We’re glad you had a good experience! Are there any features or changes that could make RentHunter even more valuable for you?";
    return "Thank you for your positive feedback! What did you love most, and is there anything we could do to make RentHunter exceptional?";
  };

  const getPlaceholderText = () => {
    if (!rating || rating < 1 || rating > 10) return ""; // Handle invalid ratings
    if (rating <= 4) return "I struggled with [specific issue], and I think [feature/change] would have made it better.";
    if (rating <= 6) return "I found [feature] lacking or expected [improvement]. It would help if [specific suggestion].";
    if (rating <= 8) return "I liked [feature], but it would be even better with [suggestion].";
    return "I loved [feature]! Adding [suggestion] would make it perfect.";
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="flex-1 flex flex-col gap-4">
        <div className="flex flex-col gap-3">
          <div className="text-primary font-medium text-lg">On a scale of 0-10, how likely are you to recommend RentHunter to a friend or colleague?</div>
          <div className="flex flex-col gap-4">
            <div className="grid grid-cols-10 gap-y-4 gap-2">
              {SCALE_ARRAY.map((n) => (
                <div
                  onClick={() => {
                    if (rating === n) return setRating(undefined);
                    setRating(n);
                  }}
                  key={n}
                  className="flex items-center">
                  <div className={`rounded-full md:p-1 cursor-pointer border ${rating === n ? "border-primary" : ""}`}>
                    <div className={`rounded-full h-7 w-7 text-sm md:text-base md:h-6 md:w-6 flex items-center justify-center ${rating === n ? "bg-primary text-white" : ""}`}>
                      {n}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex justify-between text-sm text-gray-400">
              <div>Very unlikely</div>
              <div>Very likely</div>
            </div>
          </div>
        </div>

        {rating && (
          <>
            <hr />
            <div className="flex flex-col gap-3">
              <div className="text-primary font-medium text-lg">{getFeedbackQuestion()}</div>
              <label className="flex flex-col gap-2">
                <textarea
                  required
                  placeholder={getPlaceholderText()}
                  name="report body"
                  value={feedback}
                  className="resize-y min-h-20 max-h-40 p-2 rounded-lg w-full border"
                  onChange={(e) => {
                    const value = e.target.value;
                    setFeedback(value);
                  }}></textarea>
              </label>
            </div>
          </>
        )}
      </div>

      <div className="bg-white flex flex-col gap-4 w-full">
        <button
          disabled={isLoading}
          onClick={() => {
            Statsig.logEvent(STATSIG_EVENTS.unsubscribe_click_go_back_feedback);
            setStage(UNSUBSCRIBE_STAGES.REASON);
          }}
          className="btn-secondary">
          Go Back
        </button>
        <button disabled={isLoading || !rating || feedback?.trim().length < 20} onClick={handleUnsubscribe} className="btn-primary">
          Confirm Unsubscription
        </button>
      </div>
    </div>
  );
};

const UnsubscribeConfirmation = ({ rating }) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.Auth);

  return (
    <div className="flex flex-col gap-6">
      <div>
        <div className="text-2xl font-semibold text-center">Your subscription has been cancelled.</div>
        <div className="flex-1 text-sm text-center">
          Note that your current active subscription ends on{" "}
          <span className="text-primary-500">{new Date(user.subscription_ends).toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" })}</span>
        </div>
      </div>
      <div className="flex-1 w-full flex flex-col">
        <div className="flex-1 text-center">Thank you for using RentHunter!</div>
      </div>
      {rating >= 7 ? (
        <>
          <hr />
          <div className="text-center flex flex-col gap-2">
            <div>We're glad you had a positive experience!</div>
            <div>Would you consider sharing your experience on Trustpilot?</div>
          </div>
          <div className="bg-white flex flex-col gap-4 w-full">
            <button
              type="button"
              className="btn-secondary"
              onClick={() => {
                Statsig.logEvent(STATSIG_EVENTS.unsubscribe_click_no_thanks_trustpilot);
                navigate("/account/profile");
              }}>
              No, thanks
            </button>
            <button
              onClick={() => {
                Statsig.logEvent(STATSIG_EVENTS.unsubscribe_click_yes_trustpilot);
                window.open("https://www.trustpilot.com/evaluate/rentsaver.nl", "_blank");
              }}
              className="btn-primary">
              Yes, I'd like to leave a review
            </button>
          </div>
        </>
      ) : (
        <div className="flex justify-center">
          <span
            onClick={() => {
              navigate("/account/profile");
            }}
            className="text-primary-500 cursor-pointer underline text-sm text-center">
            Go to Profile
          </span>
        </div>
      )}
    </div>
  );
};

export default Unsubscribe;

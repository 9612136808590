const ENVIRONMENT = getEnvironment();

let API_URL = "http://localhost:8083";
if (ENVIRONMENT === "production") API_URL = "https://api.renthunter.nl";

let RETURN_URL = "http://localhost:8081";
if (ENVIRONMENT === "production") RETURN_URL = "https://app.renthunter.nl";

// for the old STRIPE
let STRIPE_PUBLIC_KEY = import.meta.env.VITE_STRIPE_PUBLIC_KEY ?? "pk_test_51OqcLBK0veQKVONw32PEzm9vXxmSgw24GzJFTL88gamzpUzkqxW7HUOjsP89fzykoc7y0mnwo9kntJe6co4SF0PT00kVLMiTZq";
if (ENVIRONMENT === "production") STRIPE_PUBLIC_KEY = "pk_live_51OqcLBK0veQKVONw7vIy5ghawmqLH2wVnkXEFrg9Mm4l8YQP6FlXTuxTTioHQxAJ3zZCSjMFA2Br0VwX3Foww88v00jverYm3P";

const SENTRY_URL = "https://30d9f2d1c8feb1306072d077de80ca29@o4505892409442304.ingest.us.sentry.io/4506869290762240";
const MAINTENANCE = import.meta.env.VITE_MAINTENANCE;

function getEnvironment() {
  if (window.location.href.indexOf("localhost") !== -1 || window.location.href.indexOf("127.0.0.1") !== -1) return "development";
  return "production";
}

export { API_URL, ENVIRONMENT, MAINTENANCE, RETURN_URL, SENTRY_URL, STRIPE_PUBLIC_KEY };

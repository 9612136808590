import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";

import LoadingButton from "@/components/LoadingButton";
import { Statsig } from "@/services/statsig";
import { STATSIG_EVENTS } from "@/utils/statsigEvents";
import Modal from "../../../components/Modal";
import { setUser } from "../../../redux/auth/actions";
import api from "../../../services/api";
import { isEmail } from "../../../utils";

const SaveSearchNoAuth = ({ search, isOpen, setIsOpen, onSaveSearchSuccess }) => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      if (!isEmail(email)) return;

      const res = await api.post("/user/signup-with-email", {
        email,
        source: "APP_SAVED_SEARCH",
      });

      if (!res.ok) throw new Error("Error encountered while creating the account, please contact the support");
      api.setToken(res.data.token); // set the token for the next request
      dispatch(setUser(res.data.user));

      // save the search
      const savedSearch = await api.post("/user/saved-search", {
        ...search,
      });
      if (!savedSearch.ok) throw new Error("Error encountered while saving the search, please contact the support");

      // pass the search to the parent
      onSaveSearchSuccess(savedSearch.data.saved_searches[savedSearch.data.saved_searches.length - 1]);
      onClose();
    } catch (e) {
      console.log("e", e);
      toast.error(e?.code || "Error");
    } finally {
      setIsLoading(false);
    }
  };

  const onClose = () => {
    setEmail("");
    setIsOpen(false);
  };

  if (!isOpen) return null;
  return (
    <Modal className="max-w-[480px] w-full" isOpen={isOpen} onClose={onClose}>
      <div className="w-full h-full flex flex-col overflow-y-hidden transform bg-white text-left align-middle shadow-xl transition-all">
        <div className="bg-white w-full flex justify-between items-center px-8 py-5 border-b">
          <div className="text-xl font-semibold">Save this search</div>
          <button className="text-2xl" onClick={onClose}>
            X
          </button>
        </div>
        <div className="md:h-full overflow-auto flex flex-col px-8 py-3 pb-10">
          <form
            className="flex-1 w-full flex flex-col"
            action="POST"
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
              Statsig.logEvent(STATSIG_EVENTS.create_saved_search_no_auth);
            }}>
            <div className="flex flex-col gap-2">
              <label htmlFor="email" className="text-sm font-light">
                Email
              </label>
              <input
                required
                autoComplete="email"
                placeholder="Please enter your email"
                name="email"
                type="email"
                value={email}
                className="p-2 rounded-lg w-full border mb-2"
                onChange={(e) => {
                  const value = e.target.value;
                  setEmail(value);
                }}
              />
              <LoadingButton disabled={!isEmail(email)} type="submit" className="btn-primary w-full mb-2" loading={isLoading}>
                Submit
              </LoadingButton>

              <div className="flex flex-col justify-center">
                <span className="text-sm font-light">
                  I accept RentHunter's{" "}
                  <a href="https://renthunter.nl/privacy-policy" target="_blank" className="underline">
                    Privacy Policy
                  </a>{" "}
                  and{" "}
                  <a href="https://renthunter.nl/terms-and-conditions" target="_blank" className="underline">
                    Terms and Conditions
                  </a>
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default SaveSearchNoAuth;

import React, { useEffect, useState } from "react";

import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { AiOutlineNotification } from "react-icons/ai";
import { BiSupport } from "react-icons/bi";
import { BsPeople } from "react-icons/bs";
import { FaCheck } from "react-icons/fa6";
import { HiOutlineHomeModern } from "react-icons/hi2";

import MailLogo from "../../../assets/logo_mail.svg";
import PayOptionsImg from "../../../assets/pay-options.png";

import LoadingButton from "@/components/LoadingButton";
import Modal from "@/components/Modal";
import api from "@/services/api";
import { Statsig } from "@/services/statsig";
import { isEmail } from "@/utils";
import { STATSIG_EVENTS } from "@/utils/statsigEvents";
import { setUser } from "../../../redux/auth/actions";

const UPGRADE_STAGES = {
  CREATE_ACCOUNT: "CREATE_ACCOUNT",
  CHECKOUT: "CHECKOUT",
};

const UpgradePopups = ({ isOpen, setIsOpen }) => {
  const { user } = useSelector((state) => state.Auth);
  const [stage, setStage] = useState(null);

  useEffect(() => {
    // init stage
    if (!user) setStage(UPGRADE_STAGES.CREATE_ACCOUNT);
    else setStage(UPGRADE_STAGES.CHECKOUT);
  }, []);

  useEffect(() => {
    Statsig.logEvent(`${STATSIG_EVENTS.advert_page_upgrade_stage_}${stage}`);
  }, [stage]);

  return (
    <>
      {stage === UPGRADE_STAGES.CREATE_ACCOUNT ? (
        <ContactEmailModal isOpen={isOpen} setIsOpen={setIsOpen} setStage={setStage} />
      ) : stage === UPGRADE_STAGES.CHECKOUT ? (
        <ContactPaymentModal isOpen={isOpen} setIsOpen={setIsOpen} />
      ) : null}
    </>
  );
};

const ContactEmailModal = ({ isOpen, setIsOpen, setStage }) => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isConfirmEmailOpen, setIsConfirmEmailOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);

      const res = await api.post("/user/signup-with-email", {
        email,
        source: "APP_CONTACT_LANDLORD",
      });
      if (!res.ok) throw new Error("Error encountered while creating the account, please contact the support");

      dispatch(setUser(res.data.user));
      setStage(UPGRADE_STAGES.CHECKOUT);
    } catch (e) {
      console.log("e", e);
      toast.error(e?.code || "Error");
    } finally {
      setIsLoading(false);
    }
  };

  const onClose = () => {
    setEmail("");
    setIsConfirmEmailOpen(false);
    setTermsAccepted(false);
    setIsOpen(false);
  };

  return (
    <Modal className="max-w-[480px] w-full" isOpen={isOpen} onClose={onClose}>
      <div className="w-full max-h-screen h-screen md:h-auto flex flex-col overflow-y-hidden transform bg-white text-left align-middle shadow-xl transition-all px-8 py-5 pb-10">
        <div className="flex justify-center items-end">
          <div className="w-full flex justify-center items-center leading-none">
            <div className="text-3xl leading-2 font-medium">Contact landlord now</div>
          </div>
          <button className="text-xl font-ligth text-gray-600" onClick={onClose}>
            X
          </button>
        </div>
        <div className="flex justify-center py-10">
          <img src={MailLogo} alt="logo" className="h-28" />
        </div>
        <form className="flex-1 w-full flex flex-col" action="POST" onSubmit={onSubmit}>
          {isConfirmEmailOpen ? (
            <div className="flex flex-col gap-5">
              <div className="text-xl font-bold text-center text-primary-700 pb-4 underline">{email}</div>
              <div className="flex justify-between">
                <button
                  type="button"
                  className="btn-secondary w-auto"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsConfirmEmailOpen(false);
                    Statsig.logEvent(STATSIG_EVENTS.advert_page_upgrade_edit_email);
                  }}>
                  Edit email
                </button>
                <LoadingButton type="submit" className="btn-primary" loading={isLoading} onClick={() => Statsig.logEvent(STATSIG_EVENTS.advert_page_upgrade_confirm_email)}>
                  The email is correct
                </LoadingButton>
              </div>
            </div>
          ) : (
            <div className="flex flex-col gap-5">
              <input
                required
                placeholder="Please enter your email"
                name="email"
                type="email"
                value={email}
                className="p-2 rounded-lg w-full border"
                onChange={(e) => {
                  const value = e.target.value;
                  setEmail(value);
                }}
              />
              <button
                disabled={!isEmail(email) || !termsAccepted}
                type="button"
                className="btn-primary w-full"
                onClick={(e) => {
                  e.preventDefault();
                  setIsConfirmEmailOpen(true);
                  Statsig.logEvent(STATSIG_EVENTS.advert_page_upgrade_continue_with_email);
                }}>
                Continue with email
              </button>

              <div className="flex flex-col gap-6 justify-center ">
                <label className="flex flex-wrap items-center gap-2 text-sm justify-center cursor-pointer">
                  <input
                    className="flex flex-initial min-w-0 w-4 h-4 accent-primary cursor-pointer"
                    name="terms & conditions"
                    type="checkbox"
                    checked={termsAccepted}
                    required
                    onChange={(e) => {
                      setTermsAccepted(!termsAccepted);
                    }}
                  />
                  <span className="leading-none">
                    I accept the{" "}
                    <a href="https://renthunter.nl/privacy-policy" target="_blank" className="hover:underline">
                      privacy policy
                    </a>{" "}
                    and{" "}
                    <a href="https://renthunter.nl/terms-and-conditions" target="_blank" className="hover:underline">
                      terms and conditions
                    </a>
                  </span>
                </label>
                <div onClick={onClose} className="underline text-center text-sm cursor-pointer">
                  No thanks, I don't want to contact the landlord
                </div>
              </div>
            </div>
          )}
        </form>
      </div>
    </Modal>
  );
};

const ContactPaymentModal = ({ isOpen, setIsOpen }) => {
  const navigate = useNavigate();

  const onSubmit = async (e) => {
    e.preventDefault();
    navigate("/checkout-new");
  };

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal className="max-w-[560px] w-full" isOpen={isOpen} onClose={onClose}>
      <div className="w-full max-h-screen h-screen md:h-auto flex flex-col overflow-y-hidden transform bg-white text-left align-middle shadow-xl transition-all px-8 py-5 pb-10">
        <div className="flex items-center py-3 gap-8 border-b mb-3">
          <div className="text-sm font-semibold flex-1 flex gap-y-4 gap-x-2 items-center text-primary-700 flex-wrap">
            <div className="flex gap-2 items-center">
              <div className="h-6 w-6 rounded-full bg-primary-700 text-white flex items-center justify-center ">
                <FaCheck />
              </div>
              <span>Sign up</span>
            </div>

            <div className="flex-1 border-b border-primary-700"></div>

            <div className="flex gap-2 items-center">
              <div className="h-6 w-6 rounded-full bg-primary-50 flex items-center justify-center border-2 border-primary-700">2</div>
              <span>Activate</span>
            </div>

            <div className="flex-1 border-b border-primary-700"></div>

            <div className="flex gap-2 items-center opacity-60">
              <div className="h-6 w-6 rounded-full bg-primary-50 flex items-center justify-center border border-primary-700">3</div>
              <span>Contact landlord</span>
            </div>
          </div>
          <button className="text-xl font-light text-gray-600" onClick={onClose}>
            X
          </button>
        </div>
        <form className="flex-1 w-full flex flex-col gap-5" action="POST" onSubmit={onSubmit}>
          <div>
            <div className="text-2xl font-semibold flex gap-2 items-center mb-1">Contact landlord now</div>
            {/* <span className="font-medium">Get 7 days free trial</span> */}
          </div>

          <div className="flex flex-col gap-2">
            <div className="flex gap-4 items-center">
              <span>
                <HiOutlineHomeModern />
              </span>
              <span className="text-sm">
                <span className="font-medium">4-8 week</span> average to find a new home
              </span>
            </div>
            <div className="flex gap-4 items-center">
              <span>
                <AiOutlineNotification />
              </span>
              <span className="text-sm">
                Get <span className="font-medium">notified instantly</span> about new rentals
              </span>
            </div>
            <div className="flex gap-4 items-center">
              <span>
                <BsPeople />
              </span>
              <span className="text-sm">
                Do like <span className="font-medium">3,251 other tenants</span> using RentHunter
              </span>
            </div>
            <div className="flex gap-4 items-center">
              <span>
                <BiSupport />
              </span>
              <span className="text-sm">
                Support <span className="font-medium">Mon-Fri 10:00-18:00</span>
              </span>
            </div>
          </div>

          <div
            className="px-4 py-2 bg-cover bg-center bg-no-repeat bg-local flex flex-col gap-2 justify-center items-center rounded-2xl"
            style={{ backgroundImage: "url('../../src/assets/banner.jpg')" }}>
            {/* <div className="text-2xl font-bold">Get 7 days free trial!</div> */}
            <div className="text-xl font-medium">Only 19€ / month</div>
            {/* <div className="text-base">Then 39.99€ / month</div> */}
            {/* <div className="text-base">Only 19€ / month</div> */}
          </div>

          <div className="flex flex-col gap-4">
            <button type="submit" className="btn-primary w-full">
              Activate RentHunter
            </button>
            <div className="flex justify-center mb-2">
              <img src={PayOptionsImg} alt="" className="h-4" />
            </div>
          </div>

          <div className="text-sm text-center">
            <div>Not 100% satisfied? Get a full refund, no questions asked.</div>
            <div>You can cancel anytime on your account page.</div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default UpgradePopups;

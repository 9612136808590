import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";

import { useDispatch } from "react-redux";

import Loader from "@/components/Loader";
import { setUser } from "@/redux/auth/actions";
import api from "@/services/api";
import { Mixpanel } from "@/services/mixpanel";

const PaymentIntentResponse = ({ paymentIntentId, customerId, redirectStatus, paymentMethod }) => {
  if (!paymentIntentId) return null;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const hasRun = useRef(false);

  // hasRun to avoid multiple function calling
  useEffect(() => {
    if (!paymentIntentId || hasRun.current) return;

    if (paymentMethod && paymentMethod === "ideal") {
      confirmIdealPaymentIntent();
    } else {
      confirmPaymentIntent();
    }
    hasRun.current = true;

    Mixpanel.track("checkout_page_payment_intent_response", {
      paymentIntentId: paymentIntentId,
      customerId: customerId,
      paymentMethod: paymentMethod,
      redirectStatus: redirectStatus,
    });
  }, [paymentIntentId]);

  const confirmPaymentIntent = async () => {
    if (!customerId || !paymentIntentId || redirectStatus !== "succeeded") {
      toast.error("Payment failed, please retry or contact the support.");
      setLoading(false);
      return;
    }

    try {
      const res = await api.post("/stripe-new/confirm-payment-intent", {
        paymentIntentId: paymentIntentId,
      });

      if (!res.ok) throw new Error(res?.message);

      toast.success(res?.message);
      window.location.href = "https://renthunter.nl/thank-you-32";

      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      setLoading(false);
    }
  };

  const confirmIdealPaymentIntent = async () => {
    if (!customerId || !paymentIntentId || redirectStatus !== "succeeded") {
      toast.error("Payment failed, please retry or contact the support.");
      setLoading(false);
      return;
    }

    try {
      const res = await api.post("/stripe-new/confirm-ideal-payment", {
        paymentIntentId: paymentIntentId,
      });

      if (!res.ok) throw new Error(res?.message);
      if (res.data.user) dispatch(setUser(res.data.user));

      toast.success(res?.message);
      window.location.href = "https://renthunter.nl/thank-you-32";

      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      setLoading(false);
    }
  };

  return <div>{loading && <Loader />}</div>;
};

export default PaymentIntentResponse;

import React from "react";
import { Helmet } from "react-helmet";
import { ENVIRONMENT } from "../config";

const FirstPromoter = () => {
  if (ENVIRONMENT !== "production") {
    console.log("do not init GTM", { ENVIRONMENT });
    return <div />;
  }

  return (
    <Helmet>
      <script>
        {`
(function(w){w.fpr=w.fpr||function(){w.fpr.q = w.fpr.q||[];w.fpr.q[arguments[0]=='set'?'unshift':'push'](arguments);};})(window);
fpr("init", {cid:"ilsje7z1"}); 
fpr("click");
        `}
      </script>
    </Helmet>
  );
};

export default FirstPromoter;

import { Elements, ExpressCheckoutElement, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { MdLock } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";

import Modal from "@/components/Modal";
import { RETURN_URL, STRIPE_PUBLIC_KEY } from "@/config";
import { plans } from "@/constants";
import { Mixpanel } from "@/services/mixpanel";
import { isEmail, validatePassword } from "@/utils";
import { setUser } from "../../redux/auth/actions";
import api from "../../services/api";

function get_ga_clientid() {
  // work only if clientId already exists
  var cookie = {};
  document.cookie.split(";").forEach(function (el) {
    var splitCookie = el.split("=");
    var key = splitCookie[0].trim();
    var value = splitCookie[1];
    cookie[key] = value;
  });
  return cookie["_ga"]?.substring(6);
}

const Checkout = () => {
  const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);
  const { planName } = useParams();

  const [selectedPlan, setSelectedPlan] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [options, setOptions] = useState({
    appearance: appearance,
    mode: "subscription",
    currency: "eur",
    amount: undefined, // defined in the useEffect
    setup_future_usage: "off_session",
    payment_method_configuration: "pmc_1PYsjwK0veQKVONwKUrWRVsc",
    // paymentMethodTypes: ["card", "paypal", "ideal"],
    // paymentMethodTypes: ["card", "paypal", "link" "ideal"],
  });

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const coupon = urlParams.get("coupon");
  const [promotion, setPromotion] = useState({
    promotionCodeId: null,
    discount: 0,
    promotionCode: coupon,
  });

  const { user } = useSelector((state) => state.Auth);
  const navigate = useNavigate();

  // after payment
  const paymentIntentId = urlParams.get("payment_intent");
  const urlCustomerId = urlParams.get("customerId");
  const redirectStatus = urlParams.get("redirect_status"); // failed or succeeded
  const paymentMethod = urlParams.get("paymentMethod");

  useEffect(() => {
    Mixpanel.track("checkout_page_viewed", {
      planName: planName,
    });
    const newPlan = plans[Object.keys(plans).find((key) => plans[key].url === planName)];
    setSelectedPlan(newPlan);

    if (!newPlan) return;
    setOptions((prevOptions) => ({
      ...prevOptions,
      amount: newPlan.amount,
    }));
  }, [planName]);

  if (!selectedPlan || options.amount == undefined) {
    return <div>Loading...</div>;
  }

  if (user && user.type == "PAID" && !paymentIntentId) {
    // on veut à tout pris eviter de rediriger si le paiement est en cours
    toast.success("Welcome to RentHunter!");
    Mixpanel.track("checkout_page_redirect_to_home");
    navigate("/");
  }

  return (
    <Elements stripe={stripePromise} options={options}>
      <PaymentIntentResponse paymentIntentId={paymentIntentId} customerId={urlCustomerId} redirectStatus={redirectStatus} paymentMethod={paymentMethod} />
      <div className="h-full p-4 md:bg-white md:m-7 md:p-8 md:shadow">
        <ChangePlan selectedPlan={selectedPlan} discount={promotion?.discount} />
        <AddPromotionCode promotionCode={coupon} setPromotion={setPromotion} />
        <div className="mt-8">
          <div className="max-w-xl">
            <div className="mb-4">
              <div className="flex flex-col">
                {steps.map((step, index) => (
                  <div key={step.label} className="">
                    <div className="flex items-center mb-2">
                      <div
                        className={`${
                          activeStep === index ? "bg-primary text-white" : "bg-none"
                        } flex items-center justify-center w-7 h-7 text-sm rounded-full border border-gray-300`}>
                        {index + 1}
                      </div>
                      <h3 className="font-medium ml-2">{step.label}</h3>
                    </div>
                    <div className={`ml-9 mt-2`}>{step.description({ isActive: activeStep === index, selectedPlan, setActiveStep, promotion })}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex items-center gap-2 text-gray-500 text-sm">
              <MdLock size="20px" />
              Guaranteed safe and secure checkout
            </div>
          </div>
        </div>
      </div>
    </Elements>
  );
};

const Fields = ({ isActive, setActiveStep }) => {
  const { user } = useSelector((state) => state.Auth);
  const dispatch = useDispatch();
  const [fields, setFields] = useState({
    firstName: user?.firstname || "",
    lastName: user?.lastname || "",
    userNotLoggedIn: user ? false : true,
    email: user?.email || "",
    password: undefined,
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const upsertCustomer = async () => {
    try {
      const res = await api.post(
        `/user/upsert-stripe`,
        JSON.stringify({
          email: fields.email,
          firstname: fields.firstName,
          lastname: fields.lastName,
          password: fields.password,
        }),
      );
      if (!res.ok) throw new Error(res?.code);
      if (res.data.token) api.setToken(res.data.token);
      if (res.data.user) dispatch(setUser(res.data.user));
      return { ok: true };
    } catch (e) {
      console.log("🚀 ~ upsertCustomer ~ e:", e);
      return { ok: false, error: e.message || "Error" };
    }
  };

  const handleChange = (field, value) => {
    setFields((prevFields) => ({
      ...prevFields,
      [field]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: "",
    }));
  };

  const validateFields = () => {
    let tempErrors = {};
    if (!fields.firstName) tempErrors.firstName = "First Name is required";
    if (!fields.lastName) tempErrors.lastName = "Last Name is required";

    if (fields.userNotLoggedIn) {
      if (!fields.email) tempErrors.email = "Email is required";
      else if (!isEmail(fields.email)) tempErrors.email = "Invalid email address";

      if (!fields.password) tempErrors.password = "Password is required";
      else if (!validatePassword(fields.password)) tempErrors.password = "Password must be at least 6 characters long";
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleNext = async (e) => {
    e.preventDefault();

    const isValid = validateFields();
    if (!isValid) return;

    setIsSubmitting(true);
    if (!user?.stripe_customer_id) {
      try {
        const res = await upsertCustomer();
        if (!res.ok) throw new Error(res.error);
      } catch (error) {
        console.log("🚀 ~ handleNext ~ error", error);
      }
    }

    setIsSubmitting(false);
    setActiveStep((prevStep) => prevStep + 1);
    setFields((prevFields) => ({
      ...prevFields,
      userNotLoggedIn: false,
    }));
  };

  return (
    <>
      {isActive ? (
        <>
          <div className="grid grid-cols-2 gap-x-4">
            <div className="mb-4">
              <label className="block text-gray-500 text-xs mb-1" htmlFor="firstName">
                First name*
              </label>
              <input
                required
                id="firstName"
                placeholder="First name"
                className={`shadow appearance-none border  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-primary ${
                  errors.firstName ? "border-red-500" : ""
                }`}
                type="text"
                value={fields.firstName}
                onChange={(e) => handleChange("firstName", e.target.value)}
              />
              {errors.firstName && <p className="text-red-500 text-xs italic">{errors.firstName}</p>}
            </div>
            <div className="mb-4">
              <label className="block text-gray-500 text-xs mb-1" htmlFor="lastName">
                Last name*
              </label>
              <input
                required
                id="lastName"
                placeholder="Last name"
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-primary ${
                  errors.lastName ? "border-red-500" : ""
                }`}
                type="text"
                value={fields.lastName}
                onChange={(e) => handleChange("lastName", e.target.value)}
              />
              {errors.lastName && <p className="text-red-500 text-xs italic">{errors.lastName}</p>}
            </div>
          </div>
          {fields.userNotLoggedIn && (
            <>
              <div className="mb-4 w-full md:w-1/2 md:pr-2">
                <label className="block text-gray-500 text-xs mb-1" htmlFor="email">
                  Email*
                </label>
                <input
                  required
                  id="email"
                  placeholder="Email"
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-primary ${
                    errors.email ? "border-red-500" : ""
                  }`}
                  type="text"
                  value={fields.email}
                  onChange={(e) => handleChange("email", e.target.value)}
                />
                {errors.email && <p className="text-red-500 text-xs italic">{errors.email}</p>}
              </div>

              <div className="mb-4 w-full md:w-1/2 md:pr-2">
                <label className="block text-gray-500 text-xs mb-1" htmlFor="password">
                  Password*
                </label>
                <input
                  required
                  name="password"
                  type="password"
                  id="password"
                  placeholder="Password"
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-primary ${
                    errors.password ? "border-red-500" : ""
                  }`}
                  value={fields.password}
                  onChange={(e) => handleChange("password", e.target.value)}
                />
                {errors.password && <p className="text-red-500 text-xs italic">{errors.password}</p>}
              </div>
            </>
          )}

          <LoadingButton className={`bg-secondary w-28 text-white px-4 py-2 rounded mb-5`} onClick={handleNext} loading={isSubmitting}>
            Continue
          </LoadingButton>
        </>
      ) : (
        <div className={`text-gray-500 flex justify-between items-center mb-6`}>
          <p>{`${fields.firstName} ${fields.lastName}`}</p>
          <button
            className="font-semibold text-black"
            onClick={() => {
              setActiveStep((prevStep) => prevStep - 1);
            }}>
            Edit
          </button>
        </div>
      )}
    </>
  );
};

const ChangePlan = ({ selectedPlan, discount }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handlePlanSelect = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <h2 className="text-lg font-medium">Set up your payment</h2>
      <div className="mx-auto flex flex-col md:flex-row justify-center">
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(!isModalOpen)} innerClassName="md:flex md:max-w-[50rem] md:w-[60vw] md:h-[65vh] md:max-h-[calc(100vh-5rem)]">
          <div className="bg-white rounded-lg shadow-lg p-6 max-w-md w-full h-full mx-2">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-semibold">Choose an Offer</h2>
              <button className="text-gray-500 hover:text-gray-700 focus:outline-none" onClick={() => setIsModalOpen(false)}>
                <svg className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M18.3 5.71a1 1 0 0 0-1.42 0L12 10.59 7.12 5.7A1 1 0 0 0 5.7 7.12L10.59 12 5.7 16.88a1 1 0 0 0 1.42 1.42L12 13.41l4.88 4.89a1 1 0 0 0 1.42-1.42L13.41 12l4.89-4.88a1 1 0 0 0 0-1.41z" />
                </svg>
              </button>
            </div>
            {Object.values(plans).map((plan) => (
              <Link
                key={`${plan.name}-mobile`}
                to={`/checkout/${plan.url}`}
                className={`p-5 cursor-pointer bg-white mt-4 rounded-lg shadow transition-colors flex items-center ${
                  selectedPlan.name === plan.name ? "border-2 border-primary" : ""
                }`}
                onClick={() => {
                  handlePlanSelect();
                  Mixpanel.track("checkout_page_change_plan_click", { planName: plan.name });
                }}
                onChange={() => {}}>
                <label htmlFor={`${plan.name}-mobile`} className="flex justify-between items-center gap-5 w-full cursor-pointer">
                  <div className="flex items-center gap-4">
                    <div className="flex justify-between w-6">
                      <input
                        type="radio"
                        className="w-6 h-6 rounded-full cursor-pointer accent-primary"
                        name="pricing-mobile"
                        id={`${plan.name}-mobile`}
                        checked={selectedPlan.name === plan.name}
                        onChange={() => {}}
                      />
                    </div>
                    <div>
                      <div className="text-base font-medium whitespace-nowrap">
                        {plan.name}, {((plan.amount / 100) * (1 - discount / 100)).toFixed(1)}€
                      </div>
                      <div className="text-sm text-gray-500 font-light leading-tight">Cancel online at any time.</div>
                      {/* {plan.save && <span className="text-xs text-gray-600 whitespace-nowrap bg-gray-200 px-2 py-1 rounded-full">{plan.save}</span>} */}
                    </div>
                  </div>
                  {/* <div>
                    {plan.name === plans.TWO_MONTHS.name && <div className="text-xs text-white bg-red-500 px-2 py-1 rounded-full w-fit">Most popular</div>}
                    <div className="text-lg">
                      <span className="text-2xl font-bold">{plan.price}</span> / per month
                    </div>
                  </div> */}
                </label>
              </Link>
            ))}
          </div>
        </Modal>

        {/* Content */}
        <div className="block md:hidden">
          <div className="p-4 bg-white mt-4 rounded-lg border border-primary">
            <div>
              <div className="text-base font-medium whitespace-nowrap">
                {selectedPlan.name}, {((selectedPlan.amount / 100) * (1 - discount / 100)).toFixed(1)}€
              </div>
              <div className="text-sm text-gray-500 font-light leading-tight">Cancel online at any time.</div>
              {/* {plan.save && <span className="text-xs text-gray-600 whitespace-nowrap bg-gray-200 px-2 py-1 rounded-full">{plan.save}</span>} */}
            </div>
            <div className="flex justify-between items-center">
              <button className="mt-3 text-sm pt-2" onClick={() => setIsModalOpen(true)}>
                Change plan
              </button>
              {/* {selectedPlan.name === plans.TWO_MONTHS.name && <div className="text-xs text-white bg-red-500 px-2 py-1 rounded-full w-fit">Most popular</div>} */}
            </div>
          </div>
        </div>

        <div className="hidden md:flex flex-col md:flex-row justify-center gap-x-3 lg:gap-x-5 w-full">
          {Object.values(plans).map((plan) => (
            <Link
              key={plan.name}
              to={`/checkout/${plan.url}`}
              onClick={() => Mixpanel.track("checkout_page_change_plan_click", { planName: plan.name })}
              className={`w-full p-5 cursor-pointer bg-white mt-4 rounded-lg transition-colors flex items-center ${
                selectedPlan.name === plan.name ? "border-2 border-primary-900" : "border border-primary-800"
              }`}>
              <div key={plan.name}>
                <label htmlFor={plan.name} className="flex justify-between items-center gap-5 w-full cursor-pointer">
                  <div className="flex items-center gap-4">
                    <div className="flex justify-between w-6">
                      <input
                        type="radio"
                        className="w-5 h-5 rounded-full cursor-pointer accent-primary"
                        name="pricing"
                        id={plan.name}
                        checked={selectedPlan.name === plan.name}
                        onChange={() => {}}
                      />
                    </div>
                    <div>
                      <div className="text-base font-medium whitespace-nowrap">
                        {plan.name}, {((plan.amount / 100) * (1 - discount / 100)).toFixed(1)}€
                      </div>
                      <div className="text-sm text-gray-500 font-light leading-tight">Cancel online at any time.</div>
                      {/* {plan.save && <span className="text-xs text-gray-600 whitespace-nowrap bg-gray-200 px-2 py-1 rounded-full">{plan.save}</span>} */}
                    </div>
                  </div>
                  {/* <div>
                    {plan.name === plans.TWO_MONTHS.name && <div className="text-xs text-white bg-red-500 px-2 py-1 rounded-full w-fit whitespace-nowrap">Most popular</div>}
                    <div className="text-base leading-tight">
                      <span className="text-xl font-bold">{plan.price}</span> / per month
                    </div>
                  </div> */}
                </label>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

const AddPromotionCode = ({ promotionCode, setPromotion }) => {
  const [isPromotionInputOpen, setIsPromotionInputOpen] = useState(promotionCode ? true : false);
  const [error, setError] = useState(null);
  const [isPromotionValid, setIsPromotionValid] = useState(false);
  const [value, setValue] = useState(promotionCode);
  let [searchParams, setSearchParams] = useSearchParams();

  const validateCoupon = async () => {
    if (!value || !value.trim()) return;
    console.log("validate coupon: " + value);
    try {
      const res = await api.get(`/stripe/validate-promotion-code/${value}`);
      if (!res.ok) throw new Error(res?.message);
      setIsPromotionValid(true);
      console.log("🚀 ~ validateCoupon ~ res", res.data);
      setPromotion((prevPromotion) => ({
        ...prevPromotion,
        promotionCodeId: res.data.id,
        discount: res.data.percent_off,
      }));
    } catch (error) {
      console.error("Error validating coupon:", error);
      setError(error.message);
      setPromotion({
        coupon: null,
        promotionCodeId: null,
        discount: 0,
      });
    }
  };

  // onload + when coupon changes
  useEffect(() => {
    validateCoupon();
  }, [promotionCode]);

  return (
    <div className="w-full md:w-72">
      {!isPromotionInputOpen && (
        <div className="mt-3 font-medium text-sm text-primary cursor-pointer" onClick={() => setIsPromotionInputOpen(true)}>
          + Add code
        </div>
      )}
      {isPromotionInputOpen && (
        <div>
          <div className="flex justify-start items-center gap-x-2 mt-3">
            <input
              type="text"
              placeholder="Add promotion code"
              value={value}
              className="w-full h-full border border-gray-300 rounded p-2 text-sm"
              onChange={(e) => {
                setValue(e.target.value);
                setError(null);
                setIsPromotionValid(false);
              }}
            />
            <button
              className="bg-primary text-white px-4 py-1 rounded"
              onClick={() => {
                if (value == promotionCode) validateCoupon();
                else setSearchParams({ ...searchParams, coupon: value });
                Mixpanel.track("checkout_page_promotion_code_apply", { promotionCode: value });
              }}>
              Apply
            </button>
          </div>
          {error && <p className="text-red-500 text-xs">{error}</p>}
          {isPromotionValid && <p className="text-green-500 text-xs">Promotion code applied!</p>}
        </div>
      )}
    </div>
  );
};

const paymentElementOptions = {
  layout: "accordion",
  fields: {
    billingDetails: {
      name: "never",
      email: "never",
    },
  },
  // link order seems to matter to show it or not
  // paymentMethodOrder: ["card", "paypal", "ideal"],
  paymentMethodOrder: ["card", "link", "paypal", "ideal"],
  defaultValues: {
    billingDetails: {},
    paymentMethods: {
      ideal: {
        bank: "revolut",
      },
    },
  },
  terms: {
    card: "never",
    ideal: "never",
    paypal: "never",
  },
  wallets: {
    applePay: "auto",
    googlePay: "auto",
  },
  // todo add apple pay
  // applePay: {}
};

// TODO: send discount & coupon code to the backend + update displayed price
const PaymentForm = ({ selectedPlan, isActiveStep, discount, promotionCodeId, promotionCode }) => {
  console.log("paymentform rendering: ", selectedPlan, isActiveStep, discount, promotionCodeId, promotionCode);
  const { user } = useSelector((state) => state.Auth);
  const elements = useElements();
  const stripe = useStripe();
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState(paymentElementOptions.paymentMethodOrder[0]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    Mixpanel.track("checkout_page_payment_form_submit", {
      paymentMethod: currentPaymentMethod,
      isTermsChecked: isTermsChecked,
      selectedPlan: selectedPlan,
      promotionCode: promotionCode,
      promotionCodeId: promotionCodeId,
      discount: discount,
    });
    event.preventDefault();

    if (!isTermsChecked) {
      setErrorMessage("You must agree to the terms and conditions before proceeding.");
      return;
    }

    try {
      const result = await elements.submit();
      if (result.error) {
        // Handle validation errors
        console.error("Validation error:", result.error.message);
        toast.error(result.error.message);
        return;
      }

      setLoading(true);

      console.log("promotionCode: ", promotionCode);
      const ga4ClientId = get_ga_clientid();

      if (currentPaymentMethod === "ideal") {
        // todo: do this call before the submit button to avoid the delay
        const subs = await api.post(
          `/stripe/create-payment-intent-ideal`,
          JSON.stringify({
            // TODO: handle it backend, remove amount here
            amount: selectedPlan.amount,
            priceId: selectedPlan.priceId,
            promotionCodeId: promotionCodeId,
            ga4ClientId: ga4ClientId,
          }),
        );
        const clientSecret = subs.clientSecret;
        const { error, paymentIntent } = await stripe.confirmPayment({
          elements,
          clientSecret,
          confirmParams: {
            return_url: `${RETURN_URL}/checkout/${selectedPlan.url}?customerId=${user.stripe_customer_id}&paymentMethod=ideal&coupon=${promotionCode}`,
            payment_method_data: {
              billing_details: {
                name: user.firstname + " " + user.lastname,
                email: user.email,
              },
            },
          },
        });
        console.log("🚀 ~ handleSubmit ~ error:", error);
        console.log("🚀 ~ handleSubmit ~ paymentIntent:", paymentIntent);

        setLoading(false);
        if (error) {
          console.error("Error creating payment method:", error.message);
          toast.error(error.message);
          return;
        }

        if (paymentIntent) {
          toast.success("Payment Successfull!");
          navigate("/");
        }
        return;
      } else {
        // todo: do this call before the submit button to avoid the delay
        const subs = await api.post(
          `/stripe/create-subscription-without-ideal`,
          JSON.stringify({
            priceId: selectedPlan.priceId,
            promotionCodeId: promotionCodeId,
            ga4ClientId: ga4ClientId,
          }),
        );

        const { error, paymentIntent } = await stripe.confirmPayment({
          elements,
          clientSecret: subs.clientSecret,
          confirmParams: {
            return_url: `${RETURN_URL}/checkout/${selectedPlan.url}?customerId=${user.stripe_customer_id}&coupon=${promotionCode}`,
            payment_method_data: {
              billing_details: {
                name: user.firstname + " " + user.lastname,
                email: user.email,
              },
            },
          },
        });
        console.log("🚀 ~ handleSubmit ~ paymentIntent:", paymentIntent);
        console.log("🚀 ~ handleSubmit ~ error:", error);

        setLoading(false);
        if (error) {
          console.error("Error creating payment method:", error.message);
          toast.error(error.message);
          return;
        }

        if (paymentIntent) {
          toast.success("Payment Successfull!");
          navigate("/");
        }
      }
    } catch (error) {
      console.error("Error creating payment method:", error);
      setLoading(false);
      toast.error("Payment failed");
    }
  };

  // use effect to update the payment method listening user change
  useEffect(() => {
    if (!elements) return;
    if (!user) return;

    console.log("updating payment method");
    elements.getElement("payment")?.update({ defaultValues: { billingDetails: { name: `${user.firstname} ${user.lastname}`, email: user.email } } });
    Mixpanel.track("checkout_page_payment_method_updated", { paymentMethod: currentPaymentMethod });
  }, [isActiveStep]);

  if (!elements || !stripe) return null;

  return (
    <div className="mx-auto">
      <div className="">
        {/* <ExpressCheckoutElement
          options={{
            paymentMethods: {
              applePay: "auto",
              googlePay: "auto",
              link: "never",
              paypal: "auto",
            },
          }}
          onConfirm={() => {}} // TODO
        /> */}
        <PaymentElement
          id="payment-element"
          options={paymentElementOptions}
          onChange={(event) => {
            console.log("🚀 ~ change payment element ~ event:", event);
            setCurrentPaymentMethod(event.value.type);
          }}
        />
        <div className="flex items-center justify-start md:w-[350px] space-x-2 mt-6">
          <div className="w-fit">
            <input
              type="checkbox"
              id="terms"
              className="h-4 w-4 border-gray-300 rounded bg-white"
              checked={isTermsChecked}
              onChange={() => {
                setIsTermsChecked(event.target.checked);
                setErrorMessage("");
              }}
            />
          </div>
          <label htmlFor="terms" className="text-sm text-gray-900">
            I've read and understand the Terms and conditions*
          </label>
        </div>
        {errorMessage && <p className="text-red-500 text-xs mt-2">{errorMessage}</p>}
        <div className="flex justify-start items-center gap-x-3 mt-3">
          <LoadingButton onClick={handleSubmit} loading={loading} disabled={!stripe || !isTermsChecked} className="w-52 bg-secondary text-white px-4 py-2 rounded">
            START SUBSCRIPTION
          </LoadingButton>

          <span className="font-bold text-lg">{((selectedPlan.amount / 100) * (1 - discount / 100)).toFixed(1)}€</span>
        </div>
      </div>
    </div>
  );
};

const PaymentIntentResponse = ({ customerId, paymentIntentId, redirectStatus, paymentMethod }) => {
  if (!paymentIntentId) return null;
  console.log("🚀 ~ PaymentIntentResponse ~ paymentIntentId", paymentIntentId);
  const [loading, setLoading] = useState(true);
  const hasRun = useRef(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const confirmPaymentIntent = async () => {
    if (!customerId || !paymentIntentId || redirectStatus !== "succeeded") {
      toast.error("Payment failed, please retry or contact the support.");
      setLoading(false);
      return;
    }

    try {
      const res = await api.post("/stripe/payment-intent-succeeded", {
        paymentIntentId: paymentIntentId,
      });

      if (!res.ok) throw new Error(res?.message);

      toast.success(res?.message);
      // navigate("/");
      // redirect to https://renthunter.nl/thank-you-32
      window.location.href = "https://renthunter.nl/thank-you-32";

      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      setLoading(false);
    }
  };

  const confirmIdealPaymentIntent = async () => {
    if (!customerId || !paymentIntentId || redirectStatus !== "succeeded") {
      toast.error("Payment failed, please retry or contact the support.");
      setLoading(false);
      return;
    }

    try {
      const res = await api.post("/stripe/create-subscription-ideal", {
        paymentIntentId: paymentIntentId,
      });

      if (!res.ok) throw new Error(res?.message);
      if (res.data.user) dispatch(setUser(res.data.user));

      toast.success(res?.message);
      // navigate("/");
      // redirect to https://renthunter.nl/thank-you-32
      window.location.href = "https://renthunter.nl/thank-you-32";

      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      setLoading(false);
    }
  };

  //hasRun to avoid multiple funcation calling
  useEffect(() => {
    if (!paymentIntentId || hasRun.current) return;

    if (paymentMethod && paymentMethod === "ideal") {
      // setupSepaPaymentMethod();
      confirmIdealPaymentIntent();
    } else {
      // setupSepaPaymentMethod();
      confirmPaymentIntent();
    }
    hasRun.current = true;
    Mixpanel.track("checkout_page_payment_intent_response", {
      paymentIntentId: paymentIntentId,
      customerId: customerId,
      paymentMethod: paymentMethod,
      redirectStatus: redirectStatus,
    });
  }, [paymentIntentId]);

  return <div>{loading && <p>Loading...</p>}</div>;
};

const appearance = {
  theme: "flat",
  // uncomment to activate "minimal" theme
  // variables: {
  //   fontFamily: ' "Gill Sans", sans-serif',
  //   fontLineHeight: "1.5",
  //   borderRadius: "10px",
  //   colorBackground: "#F6F8FA",
  //   accessibleColorOnColorPrimary: "#262626",
  // },
  // rules: {
  //   ".Block": {
  //     backgroundColor: "var(--colorBackground)",
  //     boxShadow: "none",
  //     padding: "12px",
  //   },
  //   ".Input": {
  //     padding: "12px",
  //   },
  //   ".Input:disabled, .Input--invalid:disabled": {
  //     color: "lightgray",
  //   },
  //   ".Tab": {
  //     padding: "10px 12px 8px 12px",
  //     border: "none",
  //   },
  //   ".Tab:hover": {
  //     border: "none",
  //     boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)",
  //   },
  //   ".Tab--selected, .Tab--selected:focus, .Tab--selected:hover": {
  //     border: "none",
  //     backgroundColor: "#fff",
  //     boxShadow: "0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)",
  //   },
  //   ".Label": {
  //     fontWeight: "500",
  //   },
  // },
};

const steps = [
  {
    label: "Personal information",
    description: ({ isActive, setActiveStep }) => <Fields isActive={isActive} setActiveStep={setActiveStep} />,
  },
  {
    label: "Payment method",
    description: ({ isActive, selectedPlan, promotion }) => {
      return (
        <form id="payment-form" className={`${isActive ? "block" : "hidden"}`}>
          <PaymentForm
            selectedPlan={selectedPlan}
            isActiveStep={isActive}
            promotionCodeId={promotion?.promotionCodeId}
            discount={promotion?.discount}
            promotionCode={promotion?.promotionCode}
          />
        </form>
      );
    },
  },
];

const LoadingButton = ({ loading, children, disabled, ...rest }) => {
  return (
    <button
      {...rest}
      disabled={loading || disabled}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        opacity: disabled || loading ? 0.7 : 1,
        cursor: disabled || loading ? "not-allowed" : "pointer",
      }}>
      {loading && (
        <div className="flex justify-center items-center">
          <div className="animate-spin border-l-primary inline-block w-4 h-4 border-[0.1em] rounded-full">
            <span className="hidden">Loading...</span>
          </div>
        </div>
      )}
      {!loading && children}
    </button>
  );
};

export default Checkout;

import { FaExternalLinkAlt } from "react-icons/fa";

import AlertIcon from "../assets/svg/alert.svg?react";
import FAQIcon from "../assets/svg/faq.svg?react";
import FavoriteIcon from "../assets/svg/favorite.svg?react";
import HomeIcon from "../assets/svg/home.svg?react";
import AccountIcon from "../assets/svg/profile-nav.svg?react";

export const navigation = [
  { name: "Home", href: "/", exact: "true", icon: HomeIcon, desktopNavBarDisplayIcon: false, mobileBottomBarDisplayIcon: true, afterIcon: null },
  { name: "My Alerts", href: "/search-profile", exact: "true", icon: AlertIcon, desktopNavBarDisplayIcon: true, mobileBottomBarDisplayIcon: true, afterIcon: null },
  { name: "Favorites", href: "/favorites", exact: "true", icon: FavoriteIcon, desktopNavBarDisplayIcon: true, mobileBottomBarDisplayIcon: true, afterIcon: null },
  {
    name: "FAQ",
    href: "https://renthunter.nl/faq",
    exact: "true",
    icon: FAQIcon,
    desktopNavBarDisplayIcon: false,
    mobileBottomBarDisplayIcon: false,
    afterIcon: FaExternalLinkAlt,
  },
  {
    name: "Account",
    href: "/account",
    exact: "true",
    icon: AccountIcon,
    desktopNavBarDisplayIcon: false,
    mobileBottomBarDisplayIcon: true,
    afterIcon: null,
  },
];

export const SORT_BY = [
  {
    id: "",
    value: "newest first",
  },
  {
    id: "price-lowest",
    value: "lowest price",
  },
  {
    id: "price-highest",
    value: "highest price",
  },
];

// test mode
// export const plans = {
//   ONE_MONTH: {
//     name: "1 Month",
//     save: "",
//     price: "€19",
//     amount: 1900,
//     productId: "prod_QIb13yt4l5GI5H",
//     priceId: "price_1PRzp1K0veQKVONwGUgL1b1z",
//     url: "1-month",
//   },
//   TWO_MONTHS: {
//     name: "2 Months",
//     save: "Save 15%",
//     price: "€16",
//     amount: 3200,
//     productId: "prod_QIb1qPngj8d4gD",
//     priceId: "price_1PRzpHK0veQKVONwJi4ZWKuP",
//     url: "2-months",
//   },
//   THREE_MONTHS: {
//     name: "3 Months",
//     save: "Save 30%",
//     price: "€14",
//     amount: 4200,
//     productId: "prod_QIb1iobMhRDTuJ",
//     priceId: "price_1PRzpWK0veQKVONw9LLGnDmb",
//     url: "3-months",
//   },
// };

// live mode
export const plans = {
  ONE_MONTH: {
    name: "1-month plan",
    save: "",
    price: "€19",
    amount: 1900,
    productId: import.meta.env.VITE_ONE_MONTH_PLAN_PRODUCT_ID ?? "prod_PnlO4MnHVgseig",
    priceId: import.meta.env.VITE_ONE_MONTH_PLAN_PRICE_ID ?? "price_1Oy9ruK0veQKVONwucDgjDI7",
    url: "1-month",
  },
  TWO_MONTHS: {
    name: "2-month plan",
    save: "Save 15%",
    price: "€16",
    amount: 3200,
    productId: "prod_PjGqCQTak350LB",
    priceId: "price_1OtoJ8K0veQKVONwOxoXzfqt",
    url: "2-months",
  },
  THREE_MONTHS: {
    name: "3-month plan",
    save: "Save 30%",
    price: "€14",
    amount: 4200,
    productId: "prod_PjGsKcQRmd3IB9",
    priceId: "price_1OtoKIK0veQKVONw2bpM3rX1",
    url: "3-months",
  },
};

// live mode
export const plansLimited = {
  ONE_MONTH: {
    name: "1-month plan",
    save: "",
    price: "€19",
    amount: 1900,
    productId: "prod_RB0VFklPlLKurv",
    priceId: "price_1QIeUbG88wJnDlFhWhqj4USl",
    url: "1-month",
  },
};


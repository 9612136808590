import React, { createContext } from "react";

/**
 * @type {React.Context<{
 *  filters: {},
 *  setFilters: () => {},
 *  totalMatches: number | null,
 *  setTotalMatches: (data: number | null) => {},
 *  priceRangeSegments: ({ startPrice: number, endPrice: number, count: number })[],
 *  setPriceRangeSegments: (data: ({ startPrice: number, endPrice: number, count: number })[]) => {},
 *  isTotalMatchesLoading: boolean,
 *  setIsTotalMatchesLoading: (data: boolean) => {} }>
 * }
 */
const FilterContext = createContext({
  filters: {},
  setFilters: () => {},
  totalMatches: null,
  setTotalMatches: () => {},
  priceRangeSegments: [],
  setPriceRangeSegments: () => {},
  isTotalMatchesLoading: false,
  setIsTotalMatchesLoading: () => {},
});

export default FilterContext;

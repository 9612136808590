import api from "@/services/api";
import { Mixpanel } from "@/services/mixpanel";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const AddPromotionCode = ({ setPromotion }) => {
  const urlParams = new URLSearchParams(location.search);
  const coupon = urlParams.get("coupon");

  const [isPromotionInputOpen, setIsPromotionInputOpen] = useState(coupon ? true : false);
  const [error, setError] = useState(null);
  const [isPromotionValid, setIsPromotionValid] = useState(false);
  const [value, setValue] = useState(coupon || "");
  let [searchParams, setSearchParams] = useSearchParams();

  // onload + when coupon changes
  useEffect(() => {
    validateCoupon();
  }, [coupon]);

  const validateCoupon = async () => {
    if (!value || !value.trim()) return;
    try {
      const res = await api.get(`/stripe-new/validate-promotion-code/${value}`);
      if (!res.ok) throw new Error(res?.message);
      setIsPromotionValid(true);
      setPromotion({
        promotionCode: value,
        promotionCodeId: res.data.id,
        discount: res.data.percent_off,
      });
    } catch (error) {
      console.error("Error validating coupon:", error);
      setError(error.message);
      setPromotion({
        promotionCode: null,
        promotionCodeId: null,
        discount: 0,
      });
    }
  };

  return (
    <div className="w-full md:w-72">
      {!isPromotionInputOpen && (
        <div className="mt-3 font-medium text-sm text-primary cursor-pointer" onClick={() => setIsPromotionInputOpen(true)}>
          + Add code
        </div>
      )}
      {isPromotionInputOpen && (
        <div>
          <div className="flex justify-start items-center gap-x-2 mt-3">
            <input
              type="text"
              placeholder="Add promotion code"
              value={value}
              className="w-full h-full border border-gray-300 rounded p-2 text-sm"
              onChange={(e) => {
                setValue(e.target.value);
                setError(null);
                setIsPromotionValid(false);
              }}
            />
            <button
              onClick={(event) => {
                event.preventDefault();
                if (value == coupon) validateCoupon();
                else setSearchParams({ ...searchParams, coupon: value });
                Mixpanel.track("checkout_page_promotion_code_apply", { promotionCode: value });
              }}
              className="bg-primary text-white px-4 py-1 rounded">
              Apply
            </button>
          </div>
          {error && <p className="text-red-500 text-xs">{error}</p>}
          {isPromotionValid && <p className="text-green-500 text-xs">Promotion code applied!</p>}
        </div>
      )}
    </div>
  );
};

export default AddPromotionCode;

import React from "react";

import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Mixpanel } from "../../services/mixpanel";

const Subscription = () => {
  const { user } = useSelector((state) => state.Auth);
  const navigate = useNavigate();

  // active subscription
  return user.type == "PAID" && !user.subscription_ends ? (
    <>
      <div className="flex flex-col">
        <div className="text-3xl mb-5">Subscription status: active</div>
        <Link to={"/account/unsubscribe"}>
          <button
            onClick={() => {
              Mixpanel.track("profile_click_customer_portal");
            }}
            className="btn-primary w-fit mt-5">
            Manage my subscription
          </button>
        </Link>
      </div>
    </>
  ) : user.type == "PAID" && user.subscription_ends ? (
    // unsubscribed BUT subscription is still active
    <>
      <div className="flex flex-col">
        <div className="text-3xl mb-10 md:hidden">Subscription status: unsubscribed</div>
        <div className="text-xl">
          You're unsubscribed successfully. Your current subscription will stay active until{" "}
          <span className="text-primary">{new Date(user.subscription_ends).toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" })}</span>, after which it
          will be cancelled.
        </div>

        <button
          onClick={() => {
            Mixpanel.track("profile_click_upgrade");
            navigate("/checkout-new");
          }}
          className="btn-primary w-full md:w-fit mt-5">
          Manage my subscription
        </button>
      </div>
    </>
  ) : (
    // no subscription
    <>
      <div className="flex flex-col">
        <div className="text-3xl mb-10 md:hidden">Subscription</div>
        <div className="text-xl">You have no active subscription</div>
        <button
          onClick={() => {
            Mixpanel.track("profile_click_upgrade");
            navigate("/checkout-new");
          }}
          className="btn-primary w-full md:w-fit mt-5">
          Start your subscription
        </button>
      </div>
    </>
  );
};

export default Subscription;

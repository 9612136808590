export const STATSIG_EVENTS = {
  /** Onboarding */
  // onboarding stages prefix
  onboarding_stage_: "onboarding_stage_",
  onboarding_click_close: "onboarding_click_close",

  /** Adverts general */
  advert_card_click: "advert_card_click",
  add_favorite: "add_favorite",
  remove_favorite: "remove_favorite",

  /** Filters */
  click_more_filters: "click_more_filters",

  create_saved_search_no_auth: "create_saved_search_no_auth",
  create_saved_search: "create_saved_search",
  update_saved_search: "update_saved_search",

  update_saved_search_details: "update_saved_search_details",
  close_update_saved_search_details: "close_update_saved_search_details",

  /** Advert page */
  advert_page_click_contact_landlord: "advert_page_click_contact_landlord",
  advert_page_click_edit_message_template: "advert_page_click_edit_message_template",
  advert_page_click_copy_message_and_redirect: "advert_page_click_copy_message_and_redirect",

  /** Advert page upgrade popup suite */
  advert_page_upgrade_stage_: "advert_page_upgrade_stage_",
  advert_page_upgrade_continue_with_email: "advert_page_upgrade_continue_with_email",
  advert_page_upgrade_edit_email: "advert_page_upgrade_edit_email",
  advert_page_upgrade_confirm_email: "advert_page_upgrade_confirm_email",

  /** Account */
  account_message_view_variables: "account_message_view_variables",
  account_message_cancel_edit: "account_message_cancel_edit",
  account_message_restore_default: "account_message_restore_default",
  account_message_save: "account_message_save",

  account_contact_save_btn: "account_contact_save_btn",
  account_profile_save_btn: "account_profile_save_btn",

  /** My alerts */
  my_alerts_add_search_click: "my_alerts_add_search_click",
  my_alerts_add_first_search_click: "my_alerts_add_first_search_click",
  my_alerts_add_search_click: "my_alerts_add_search_click",
  my_alerts_show_matches_click: "my_alerts_show_matches_click",
  my_alerts_toggle_search_click: "my_alerts_toggle_search_click",

  /** Checkout */
  checkout_page_view: "checkout_page_view",
  checkout_fields_next_click: "checkout_fields_next_click",
  checkout_edit_personal_info: "checkout_upgrade_edit_personal_info",
  checkout_payment_element_ready: "checkout_payment_element_ready",
  checkout_payment_form_submit: "checkout_payment_form_submit",

  /** Unsubscribe */
  // unsubscribe stages prefix
  unsubscribe_stage_: "unsubscribe_stage_",
  unsubscribe_click_logo: "unsubscribe_click_logo",
  unsubscribe_click_keep_subscription: "unsubscribe_click_keep_subscription",
  unsubscribe_click_go_back_reason: "unsubscribe_click_go_back_reason",
  unsubscribe_click_go_back_feedback: "unsubscribe_click_go_back_feedback",
  unsubscribe_click_yes_trustpilot: "unsubscribe_click_yes_trustpilot",
  unsubscribe_click_no_thanks_trustpilot: "unsubscribe_click_no_thanks_trustpilot",
};

import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import LoadingButton from "@/components/LoadingButton";
import { setUser } from "@/redux/auth/actions";
import api from "@/services/api";
import { Statsig } from "@/services/statsig";
import { isEmail, validatePassword } from "@/utils";
import { STATSIG_EVENTS } from "@/utils/statsigEvents";

const Fields = ({ isActive, setActiveStep }) => {
  const { user } = useSelector((state) => state.Auth);
  const dispatch = useDispatch();

  const [fields, setFields] = useState({
    firstname: user?.firstname || "",
    lastname: user?.lastname || "",
    userNotLoggedIn: user ? false : true,
    email: user?.email || "",
    password: undefined,
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const upsertCustomer = async () => {
    try {
      const res = await api.post(
        `/user/upsert-stripe-new`,
        JSON.stringify({
          email: fields.email,
          firstname: fields.firstname,
          lastname: fields.lastname,
          password: fields.password,
        }),
      );
      if (!res.ok) throw new Error(res?.code);
      if (res.data.token) api.setToken(res.data.token);
      if (res.data.user) dispatch(setUser(res.data.user));
      return { ok: true };
    } catch (e) {
      console.log("🚀 ~ upsertCustomer ~ e:", e);
      return { ok: false, error: e.message || "Error" };
    }
  };

  const handleChange = (field, value) => {
    setFields((prevFields) => ({
      ...prevFields,
      [field]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: "",
    }));
  };

  const validateFields = () => {
    let tempErrors = {};
    if (!fields.firstname) tempErrors.firstname = "First Name is required";
    if (!fields.lastname) tempErrors.lastname = "Last Name is required";

    if (fields.userNotLoggedIn) {
      if (!fields.email) tempErrors.email = "Email is required";
      else if (!isEmail(fields.email)) tempErrors.email = "Invalid email address";

      if (!fields.password) tempErrors.password = "Password is required";
      else if (!validatePassword(fields.password)) tempErrors.password = "Password must be at least 6 characters long";
    }

    // name and lastname must be at least 3 characters long
    if (fields.firstname.length < 3) tempErrors.firstname = "Firstname must be at least 3 characters long";
    if (fields.lastname.length < 3) tempErrors.lastname = "Lastname must be at least 3 characters long";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleNext = async () => {
    const isValid = validateFields();
    if (!isValid) return;

    setIsSubmitting(true);
    if (!user?.stripe_customer_id_ltd) {
      try {
        const res = await upsertCustomer();
        if (!res.ok) throw new Error(res.error);
      } catch (error) {
        console.log("🚀 ~ handleNext ~ error", error);
      }
    } else {
      // update user
      try {
        const res = await api.put(`/user`, JSON.stringify(fields));
        if (!res.ok) throw new Error(res.error);
        dispatch(setUser(res.data));
      } catch (error) {
        console.log("🚀 ~ handleNext ~ error", error);
      }
    }

    setIsSubmitting(false);
    setActiveStep((prevStep) => prevStep + 1);
    setFields((prevFields) => ({
      ...prevFields,
      userNotLoggedIn: false,
    }));
  };

  return (
    <>
      {isActive ? (
        <>
          <div className="grid grid-cols-2 gap-x-4">
            <div className="mb-4">
              <label className="block text-gray-500 text-xs mb-1" htmlFor="firstName">
                First name*
              </label>
              <input
                required
                id="firstName"
                placeholder="First name"
                className={`shadow appearance-none border  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-primary ${
                  errors.firstname ? "border-red-500" : ""
                }`}
                type="text"
                value={fields.firstname}
                onChange={(e) => handleChange("firstname", e.target.value)}
              />
              {errors.firstname && <p className="text-red-500 text-xs italic">{errors.firstname}</p>}
            </div>
            <div className="mb-4">
              <label className="block text-gray-500 text-xs mb-1" htmlFor="lastName">
                Last name*
              </label>
              <input
                required
                id="lastName"
                placeholder="Last name"
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-primary ${
                  errors.lastname ? "border-red-500" : ""
                }`}
                type="text"
                value={fields.lastname}
                onChange={(e) => handleChange("lastname", e.target.value)}
              />
              {errors.lastname && <p className="text-red-500 text-xs italic">{errors.lastname}</p>}
            </div>
          </div>
          {fields.userNotLoggedIn && (
            <>
              <div className="mb-4 w-full md:w-1/2 md:pr-2">
                <label className="block text-gray-500 text-xs mb-1" htmlFor="email">
                  Email*
                </label>
                <input
                  required
                  id="email"
                  placeholder="Email"
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-primary ${
                    errors.email ? "border-red-500" : ""
                  }`}
                  type="text"
                  value={fields.email}
                  onChange={(e) => handleChange("email", e.target.value)}
                />
                {errors.email && <p className="text-red-500 text-xs italic">{errors.email}</p>}
              </div>

              <div className="mb-4 w-full md:w-1/2 md:pr-2">
                <label className="block text-gray-500 text-xs mb-1" htmlFor="password">
                  Password*
                </label>
                <input
                  required
                  name="password"
                  type="password"
                  id="password"
                  placeholder="Password"
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-primary ${
                    errors.password ? "border-red-500" : ""
                  }`}
                  value={fields.password}
                  onChange={(e) => handleChange("password", e.target.value)}
                />
                {errors.password && <p className="text-red-500 text-xs italic">{errors.password}</p>}
              </div>
            </>
          )}

          <LoadingButton
            className={`bg-secondary w-28 text-white px-4 py-2 rounded mb-5`}
            onClick={(e) => {
              e.preventDefault();
              handleNext();
              Statsig.logEvent(STATSIG_EVENTS.checkout_fields_next_click);
            }}
            loading={isSubmitting}>
            Continue
          </LoadingButton>
        </>
      ) : (
        <div className={`text-gray-500 flex justify-between items-center mb-6`}>
          <p>{`${fields.firstname} ${fields.lastname}`}</p>
          <button
            className="font-semibold text-black"
            onClick={() => {
              setActiveStep((prevStep) => prevStep - 1);
              Statsig.logEvent(STATSIG_EVENTS.checkout_edit_personal_info);
            }}>
            Edit
          </button>
        </div>
      )}
    </>
  );
};

export default Fields;

import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader";

import api from "../../services/api";
import AdvertCard from "../housingMatches/AdvertCard";

const Favorites = () => {
  const [matches, setMatches] = useState(null);

  const fetchMatches = async () => {
    try {
      const res = await api.get("/advert/user-favorites");
      setMatches(res.data);
    } catch (e) {
      console.log("e", e);
      toast.error(e?.code || "Error");
    }
  };

  useEffect(() => {
    fetchMatches();
  }, []);

  return (
    <div className="p-5 md:p-8">
      <div className="text-3xl mb-10">My Favorites</div>
      {!matches ? (
        <Loader />
      ) : (
        <>
          {matches.length == 0 && (
            <div>
              <h2>You don't have any favorite listings yet!</h2>
            </div>
          )}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-y-10 gap-x-6">
            {matches.map((advert, index) => {
              return <AdvertCard key={index} advert={advert} />;
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default Favorites;
